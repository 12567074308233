/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Motion Cloud
 * API for Motion Cloud web application
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ApplicationDto } from '../model';
import { ApplicationReleaseDto } from '../model';
import { DownloadTicketDTO } from '../model';
import { EngineDto } from '../model';
import { ErrorDTO } from '../model';
/**
 * EnginesApi - axios parameter creator
 * @export
 */
export const EnginesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get application releases for a specific engine
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationReleasesByEngineId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getApplicationReleasesByEngineId.');
            }
            const localVarPath = `/api/engines/{id}/releases`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get applications for a specific engine
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationsByEngineId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getApplicationsByEngineId.');
            }
            const localVarPath = `/api/engines/{id}/applications`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get engine by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEngineById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getEngineById.');
            }
            const localVarPath = `/api/engines/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get engines file by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEngineFile: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getEngineFile.');
            }
            const localVarPath = `/api/engines/{id}/file`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of published engines
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublishedEngines: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/engines/published`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnginesApi - functional programming interface
 * @export
 */
export const EnginesApiFp = function(axiosInstance: Promise<AxiosInstance>, configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get application releases for a specific engine
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationReleasesByEngineId(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<ApplicationReleaseDto>>> {
            const localVarAxiosArgs = await EnginesApiAxiosParamCreator(configuration).getApplicationReleasesByEngineId(id, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get applications for a specific engine
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationsByEngineId(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<ApplicationDto>>> {
            const localVarAxiosArgs = await EnginesApiAxiosParamCreator(configuration).getApplicationsByEngineId(id, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get engine by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEngineById(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<EngineDto>> {
            const localVarAxiosArgs = await EnginesApiAxiosParamCreator(configuration).getEngineById(id, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get engines file by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEngineFile(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<DownloadTicketDTO>> {
            const localVarAxiosArgs = await EnginesApiAxiosParamCreator(configuration).getEngineFile(id, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get list of published engines
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublishedEngines(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<EngineDto>>> {
            const localVarAxiosArgs = await EnginesApiAxiosParamCreator(configuration).getPublishedEngines(options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
    }
};

/**
 * EnginesApi - factory interface
 * @export
 */
export const EnginesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get application releases for a specific engine
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationReleasesByEngineId(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<ApplicationReleaseDto>>> {
            return EnginesApiFp(configuration).getApplicationReleasesByEngineId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get applications for a specific engine
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationsByEngineId(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<ApplicationDto>>> {
            return EnginesApiFp(configuration).getApplicationsByEngineId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get engine by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEngineById(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<EngineDto>> {
            return EnginesApiFp(configuration).getEngineById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get engines file by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEngineFile(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<DownloadTicketDTO>> {
            return EnginesApiFp(configuration).getEngineFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of published engines
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublishedEngines(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<EngineDto>>> {
            return EnginesApiFp(configuration).getPublishedEngines(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EnginesApi - object-oriented interface
 * @export
 * @class EnginesApi
 * @extends {BaseAPI}
 */
export class EnginesApi extends BaseAPI {
    /**
     * 
     * @summary Get application releases for a specific engine
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnginesApi
     */
    public async getApplicationReleasesByEngineId(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<ApplicationReleaseDto>>> {
        return EnginesApiFp(this.configuration).getApplicationReleasesByEngineId(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get applications for a specific engine
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnginesApi
     */
    public async getApplicationsByEngineId(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<ApplicationDto>>> {
        return EnginesApiFp(this.configuration).getApplicationsByEngineId(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get engine by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnginesApi
     */
    public async getEngineById(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<EngineDto>> {
        return EnginesApiFp(this.configuration).getEngineById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get engines file by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnginesApi
     */
    public async getEngineFile(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<DownloadTicketDTO>> {
        return EnginesApiFp(this.configuration).getEngineFile(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get list of published engines
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnginesApi
     */
    public async getPublishedEngines(options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<EngineDto>>> {
        return EnginesApiFp(this.configuration).getPublishedEngines(options).then((request) => request(this.axios, this.basePath));
    }
}
