import {
	createSlice,
	createAsyncThunk,
	createAction,
	isAnyOf,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { TemplatesResponse } from 'types/response/TemplatesResponse';

import { RootState } from 'store';
import { EntityStatus } from 'api';
import adminTemplatesService from 'services/admin/AdminTemplatesService';

export interface TemplatesState {
	templates: TemplatesResponse;
	loading: boolean;
	error: string | null;
}

const initialState: TemplatesState = {
	templates: {} as TemplatesResponse,
	loading: true,
	error: null,
};

type PubTemplateRequestParamsLoose = Parameters<
	typeof adminTemplatesService.getPublishedTemplatesList
>;
type PubTemplateRequestParams = [
	PubTemplateRequestParamsLoose[0], // Page
	PubTemplateRequestParamsLoose[1], // Size
	EntityStatus | undefined,
	PubTemplateRequestParamsLoose[3] // Search Query
];

export const getPublishedTemplates = createAsyncThunk(
	'templates/getPublishedTemplates',
	async (params: PubTemplateRequestParams) =>
		await adminTemplatesService
			.getPublishedTemplatesList(...params)
			.catch((error: AxiosError) => Promise.reject(error.request?.status))
);

type TemplateRequestParamsLoose = Parameters<
	typeof adminTemplatesService.getTemplatesList
>;
type TemplateRequestParams = [
	TemplateRequestParamsLoose[0], // Page
	TemplateRequestParamsLoose[1], // Size
	TemplateRequestParamsLoose[2], // Search Query
	EntityStatus | undefined
];

export const getTemplates = createAsyncThunk(
	'templates/getTemplates',
	async (params: TemplateRequestParams) =>
		await adminTemplatesService
			.getTemplatesList(...params)
			.catch((error: AxiosError) => Promise.reject(error.request?.status))
);

export const resetState = createAction('RESET');

const templatesSlice = createSlice({
	name: 'templates',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getTemplates.pending, (state) => {
				state.loading = true;
				state.error = null;
			})

			.addCase(getTemplates.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.templates = payload.data;
			})

			.addCase(resetState, () => {
				return initialState;
			})

			.addMatcher(isAnyOf(getTemplates.rejected), (state, action) => {
				state.error = action.payload as string;
				state.loading = false;
			});
	},
});

export const templatesState = (state: RootState) => state.templates;

export default templatesSlice.reducer;
