/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Motion Cloud
 * API for Motion Cloud web application
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { EditSupportItemDto } from '../model';
import { ErrorDTO } from '../model';
import { SupportItemDto } from '../model';
/**
 * AdminSupportItemsApi - axios parameter creator
 * @export
 */
export const AdminSupportItemsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add support item on Manage Support tab
         * @param {boolean} supportItemPresent 
         * @param {SupportItemDto} [supportItemDto] 
         * @param {Blob} [image] 
         * @param {Blob} [supportItemFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSupportItemForm: async (supportItemPresent: boolean, supportItemDto?: SupportItemDto, image?: Blob, supportItemFile?: Blob, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'supportItemPresent' is not null or undefined
            if (supportItemPresent === null || supportItemPresent === undefined) {
                throw new RequiredError('supportItemPresent','Required parameter supportItemPresent was null or undefined when calling createSupportItemForm.');
            }
            const localVarPath = `/api/admin/support`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (supportItemPresent !== undefined) {
                localVarQueryParameter['supportItemPresent'] = supportItemPresent;
            }


            if (supportItemDto !== undefined) {
                const supportItemDtoValue = typeof supportItemDto === 'object' && !(supportItemDto instanceof Blob) ? JSON.stringify(supportItemDto) : supportItemDto;
                localVarFormParams.append('supportItemDto', supportItemDtoValue as any);
            }

            if (image !== undefined) {
                const imageValue = typeof image === 'object' && !(image instanceof Blob) ? JSON.stringify(image) : image;
                localVarFormParams.append('image', imageValue as any);
            }

            if (supportItemFile !== undefined) {
                const supportItemFileValue = typeof supportItemFile === 'object' && !(supportItemFile instanceof Blob) ? JSON.stringify(supportItemFile) : supportItemFile;
                localVarFormParams.append('supportItemFile', supportItemFileValue as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete picture from support item on Manage Support tab
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSupportItemBasicPicture: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteSupportItemBasicPicture.');
            }
            const localVarPath = `/api/admin/support/{id}/photo`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete support item on Manage Support tab
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSupportItemById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteSupportItemById.');
            }
            const localVarPath = `/api/admin/support/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update support item on Manage Support tab
         * @param {number} supportItemId 
         * @param {EditSupportItemDto} [supportItemDto] 
         * @param {Blob} [basicPic] 
         * @param {Blob} [supportItemFile] 
         * @param {boolean} [supportItemFilePresent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSupportItemForm: async (supportItemId: number, supportItemDto?: EditSupportItemDto, basicPic?: Blob, supportItemFile?: Blob, supportItemFilePresent?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'supportItemId' is not null or undefined
            if (supportItemId === null || supportItemId === undefined) {
                throw new RequiredError('supportItemId','Required parameter supportItemId was null or undefined when calling updateSupportItemForm.');
            }
            const localVarPath = `/api/admin/support/{supportItemId}`
                .replace(`{${"supportItemId"}}`, encodeURIComponent(String(supportItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


            if (supportItemDto !== undefined) {
                const supportItemDtoValue = typeof supportItemDto === 'object' && !(supportItemDto instanceof Blob) ? JSON.stringify(supportItemDto) : supportItemDto;
                localVarFormParams.append('supportItemDto', supportItemDtoValue as any);
            }

            if (basicPic !== undefined) {
                const basicPicValue = typeof basicPic === 'object' && !(basicPic instanceof Blob) ? JSON.stringify(basicPic) : basicPic;
                localVarFormParams.append('basicPic', basicPicValue as any);
            }

            if (supportItemFile !== undefined) {
                const supportItemFileValue = typeof supportItemFile === 'object' && !(supportItemFile instanceof Blob) ? JSON.stringify(supportItemFile) : supportItemFile;
                localVarFormParams.append('supportItemFile', supportItemFileValue as any);
            }

            if (supportItemFilePresent !== undefined) {
                const supportItemFilePresentValue = typeof supportItemFilePresent === 'object' && !(supportItemFilePresent instanceof Blob) ? JSON.stringify(supportItemFilePresent) : supportItemFilePresent;
                localVarFormParams.append('supportItemFilePresent', supportItemFilePresentValue as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload file in support item by id
         * @param {number} id 
         * @param {Blob} [supportItemFile] 
         * @param {boolean} [supportItemFilePresent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadSupportItemForm: async (id: number, supportItemFile?: Blob, supportItemFilePresent?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling uploadSupportItemForm.');
            }
            const localVarPath = `/api/admin/support/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


            if (supportItemFile !== undefined) {
                const supportItemFileValue = typeof supportItemFile === 'object' && !(supportItemFile instanceof Blob) ? JSON.stringify(supportItemFile) : supportItemFile;
                localVarFormParams.append('supportItemFile', supportItemFileValue as any);
            }

            if (supportItemFilePresent !== undefined) {
                const supportItemFilePresentValue = typeof supportItemFilePresent === 'object' && !(supportItemFilePresent instanceof Blob) ? JSON.stringify(supportItemFilePresent) : supportItemFilePresent;
                localVarFormParams.append('supportItemFilePresent', supportItemFilePresentValue as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminSupportItemsApi - functional programming interface
 * @export
 */
export const AdminSupportItemsApiFp = function(axiosInstance: Promise<AxiosInstance>, configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add support item on Manage Support tab
         * @param {boolean} supportItemPresent 
         * @param {SupportItemDto} [supportItemDto] 
         * @param {Blob} [image] 
         * @param {Blob} [supportItemFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSupportItemForm(supportItemPresent: boolean, supportItemDto?: SupportItemDto, image?: Blob, supportItemFile?: Blob, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> {
            const localVarAxiosArgs = await AdminSupportItemsApiAxiosParamCreator(configuration).createSupportItemForm(supportItemPresent, supportItemDto, image, supportItemFile, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Delete picture from support item on Manage Support tab
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSupportItemBasicPicture(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> {
            const localVarAxiosArgs = await AdminSupportItemsApiAxiosParamCreator(configuration).deleteSupportItemBasicPicture(id, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Delete support item on Manage Support tab
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSupportItemById(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await AdminSupportItemsApiAxiosParamCreator(configuration).deleteSupportItemById(id, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Update support item on Manage Support tab
         * @param {number} supportItemId 
         * @param {EditSupportItemDto} [supportItemDto] 
         * @param {Blob} [basicPic] 
         * @param {Blob} [supportItemFile] 
         * @param {boolean} [supportItemFilePresent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSupportItemForm(supportItemId: number, supportItemDto?: EditSupportItemDto, basicPic?: Blob, supportItemFile?: Blob, supportItemFilePresent?: boolean, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> {
            const localVarAxiosArgs = await AdminSupportItemsApiAxiosParamCreator(configuration).updateSupportItemForm(supportItemId, supportItemDto, basicPic, supportItemFile, supportItemFilePresent, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Upload file in support item by id
         * @param {number} id 
         * @param {Blob} [supportItemFile] 
         * @param {boolean} [supportItemFilePresent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadSupportItemForm(id: number, supportItemFile?: Blob, supportItemFilePresent?: boolean, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> {
            const localVarAxiosArgs = await AdminSupportItemsApiAxiosParamCreator(configuration).uploadSupportItemForm(id, supportItemFile, supportItemFilePresent, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
    }
};

/**
 * AdminSupportItemsApi - factory interface
 * @export
 */
export const AdminSupportItemsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Add support item on Manage Support tab
         * @param {boolean} supportItemPresent 
         * @param {SupportItemDto} [supportItemDto] 
         * @param {Blob} [image] 
         * @param {Blob} [supportItemFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSupportItemForm(supportItemPresent: boolean, supportItemDto?: SupportItemDto, image?: Blob, supportItemFile?: Blob, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> {
            return AdminSupportItemsApiFp(configuration).createSupportItemForm(supportItemPresent, supportItemDto, image, supportItemFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete picture from support item on Manage Support tab
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSupportItemBasicPicture(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> {
            return AdminSupportItemsApiFp(configuration).deleteSupportItemBasicPicture(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete support item on Manage Support tab
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSupportItemById(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AdminSupportItemsApiFp(configuration).deleteSupportItemById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update support item on Manage Support tab
         * @param {number} supportItemId 
         * @param {EditSupportItemDto} [supportItemDto] 
         * @param {Blob} [basicPic] 
         * @param {Blob} [supportItemFile] 
         * @param {boolean} [supportItemFilePresent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSupportItemForm(supportItemId: number, supportItemDto?: EditSupportItemDto, basicPic?: Blob, supportItemFile?: Blob, supportItemFilePresent?: boolean, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> {
            return AdminSupportItemsApiFp(configuration).updateSupportItemForm(supportItemId, supportItemDto, basicPic, supportItemFile, supportItemFilePresent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload file in support item by id
         * @param {number} id 
         * @param {Blob} [supportItemFile] 
         * @param {boolean} [supportItemFilePresent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadSupportItemForm(id: number, supportItemFile?: Blob, supportItemFilePresent?: boolean, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> {
            return AdminSupportItemsApiFp(configuration).uploadSupportItemForm(id, supportItemFile, supportItemFilePresent, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminSupportItemsApi - object-oriented interface
 * @export
 * @class AdminSupportItemsApi
 * @extends {BaseAPI}
 */
export class AdminSupportItemsApi extends BaseAPI {
    /**
     * 
     * @summary Add support item on Manage Support tab
     * @param {boolean} supportItemPresent 
     * @param {SupportItemDto} [supportItemDto] 
     * @param {Blob} [image] 
     * @param {Blob} [supportItemFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSupportItemsApi
     */
    public async createSupportItemForm(supportItemPresent: boolean, supportItemDto?: SupportItemDto, image?: Blob, supportItemFile?: Blob, options?: AxiosRequestConfig) : Promise<AxiosResponse<string>> {
        return AdminSupportItemsApiFp(this.configuration).createSupportItemForm(supportItemPresent, supportItemDto, image, supportItemFile, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete picture from support item on Manage Support tab
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSupportItemsApi
     */
    public async deleteSupportItemBasicPicture(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<string>> {
        return AdminSupportItemsApiFp(this.configuration).deleteSupportItemBasicPicture(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete support item on Manage Support tab
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSupportItemsApi
     */
    public async deleteSupportItemById(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AdminSupportItemsApiFp(this.configuration).deleteSupportItemById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update support item on Manage Support tab
     * @param {number} supportItemId 
     * @param {EditSupportItemDto} [supportItemDto] 
     * @param {Blob} [basicPic] 
     * @param {Blob} [supportItemFile] 
     * @param {boolean} [supportItemFilePresent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSupportItemsApi
     */
    public async updateSupportItemForm(supportItemId: number, supportItemDto?: EditSupportItemDto, basicPic?: Blob, supportItemFile?: Blob, supportItemFilePresent?: boolean, options?: AxiosRequestConfig) : Promise<AxiosResponse<string>> {
        return AdminSupportItemsApiFp(this.configuration).updateSupportItemForm(supportItemId, supportItemDto, basicPic, supportItemFile, supportItemFilePresent, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Upload file in support item by id
     * @param {number} id 
     * @param {Blob} [supportItemFile] 
     * @param {boolean} [supportItemFilePresent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSupportItemsApi
     */
    public async uploadSupportItemForm(id: number, supportItemFile?: Blob, supportItemFilePresent?: boolean, options?: AxiosRequestConfig) : Promise<AxiosResponse<string>> {
        return AdminSupportItemsApiFp(this.configuration).uploadSupportItemForm(id, supportItemFile, supportItemFilePresent, options).then((request) => request(this.axios, this.basePath));
    }
}
