import { createSlice, createAction, isAnyOf } from '@reduxjs/toolkit';
import { ErrorDTO, TemplateDto } from 'api';
import { AxiosResponse } from 'axios';
import adminTemplatesService from 'services/admin/AdminTemplatesService';

import { RootState } from 'store';
import { getErrorMessageAnd, rejectionError } from 'utils/errors';
import { createAppAsyncThunk } from 'utils/rtk';

export interface TemplateState {
	template: TemplateDto;
	templateFile: Blob;
	templateFileName: string;
	loading: boolean;
	uploading: boolean | null;
	error: ErrorDTO | null;
}

const initialState: TemplateState = {
	template: {} as TemplateDto,
	templateFile: {} as Blob,
	templateFileName: '',
	loading: true,
	uploading: null,
	error: null,
};

const setLoading = (state: TemplateState) => {
	state.loading = true;
	state.error = null;
};

const setUploading = (state: TemplateState) => {
	state.uploading = true;
	state.error = null;
};

export const deleteTemplate = createAppAsyncThunk(
	'oneTemplate/deleteTemplate',
	(id: number, { rejectWithValue }) =>
		adminTemplatesService
			.deleteTemplateItemById(id)
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const deleteTemplatePicture = createAppAsyncThunk(
	'oneTemplate/deleteTemplatePicture',
	(id: number, { rejectWithValue }) =>
		adminTemplatesService
			.deleteTemplatesItemBasicPicture(id)
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const deleteTemplateFile = createAppAsyncThunk(
	'oneTemplate/deleteTemplateFile',
	(id: number, { rejectWithValue }) =>
		adminTemplatesService
			.deleteTemplatesFile(id)
			.catch(getErrorMessageAnd(rejectWithValue))
);

type TemplateCreateParamsLoose = Parameters<
	typeof adminTemplatesService.addTemplateItemForm
>;

export const addTemplate = createAppAsyncThunk<
	AxiosResponse<void>,
	TemplateCreateParamsLoose
>('oneTemplate/addTemplate', async (params, { rejectWithValue }) =>
	adminTemplatesService
		.addTemplateItemForm(...params)
		.catch(getErrorMessageAnd(rejectWithValue))
);

type TemplateUpdateParamsLoose = Parameters<
	typeof adminTemplatesService.updateTemplateItemForm
>;

export const updateTemplate = createAppAsyncThunk<
	AxiosResponse<void>,
	TemplateUpdateParamsLoose
>('oneTemplate/updateTemplate', (params, { rejectWithValue }) =>
	adminTemplatesService
		.updateTemplateItemForm(...params)
		.catch(getErrorMessageAnd(rejectWithValue))
);

export const getOneTemplate = createAppAsyncThunk(
	'oneTemplate/getOneTemplate',
	(id: number, { rejectWithValue }) =>
		adminTemplatesService
			.getTemplatesItemById(id)
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const downloadTemplate = createAppAsyncThunk(
	'oneTemplate/downloadTemplate',
	(id: number, { rejectWithValue }) =>
		adminTemplatesService
			.getTemplatesFile(id)
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const getTemplateFileName = createAppAsyncThunk(
	'oneTemplate/getTemplateFileName',
	(id: number, { rejectWithValue }) =>
		adminTemplatesService
			.getTemplateFileName(id)
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const resetState = createAction('RESET');

const oneTemplateSlice = createSlice({
	name: 'oneTemplate',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(addTemplate.pending, setUploading)
			.addCase(addTemplate.fulfilled, (state) => {
				state.uploading = false;
				state.error = null;
			})

			.addCase(updateTemplate.pending, setUploading)
			.addCase(updateTemplate.fulfilled, (state) => {
				state.uploading = false;
				state.error = null;
			})

			.addCase(deleteTemplate.pending, setLoading)
			.addCase(deleteTemplate.fulfilled, (state) => {
				state.loading = false;
				state.template = {} as TemplateDto;
				state.error = null;
			})

			.addCase(deleteTemplatePicture.pending, setLoading)
			.addCase(deleteTemplatePicture.fulfilled, (state) => {
				state.loading = false;
				state.error = null;
			})

			.addCase(deleteTemplateFile.pending, setLoading)
			.addCase(deleteTemplateFile.fulfilled, (state) => {
				state.loading = false;
				state.error = null;
			})

			.addCase(getOneTemplate.pending, setLoading)
			.addCase(getOneTemplate.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.template = payload.data;
			})

			.addCase(downloadTemplate.pending, setLoading)
			.addCase(downloadTemplate.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.templateFile = payload.data;
			})

			.addCase(getTemplateFileName.pending, setLoading)
			.addCase(getTemplateFileName.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.templateFileName = payload.data;
			})

			.addCase(resetState, () => {
				return initialState;
			})

			.addMatcher(
				isAnyOf(
					addTemplate.rejected,
					updateTemplate.rejected,
					deleteTemplate.rejected,
					deleteTemplatePicture.rejected,
					deleteTemplateFile.rejected,
					getOneTemplate.rejected,
					downloadTemplate.rejected,
					getTemplateFileName.rejected
				),
				(state, action) => {
					state.error = action.payload ?? rejectionError;
					state.loading = false;
				}
			);
	},
});

export const oneTemplateState = (state: RootState) => state.oneTemplate;

export default oneTemplateSlice.reducer;
