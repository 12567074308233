import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftIcon, CloudLargeIcon, DownloadIcon } from 'assets/icons/svg';
import style from 'assets/styles/oneElementPage.module.scss';
import { App, Spin } from 'antd';
import { getPublicImageURL } from 'services/ApiService';
import { McMarkdown } from 'components/mc';
import { ApplicationDto, ApplicationReleaseDto } from 'api';
import { Cond } from 'utils/Cond';
import { useQuery } from '@tanstack/react-query';
import applicationsService from 'services/ApplicationsService';
import { mcErrorNotification } from 'utils/Notifications';
import enginesService from 'services/EnginesService';
import { NotFoundElement } from 'components/NotFoundElement';
import McSpinner from 'components/MotionCloudSpinner';

export const OneEngine = () => {
	let navigate = useNavigate();

	const { id: paramId } = useParams();
	const id = Number(paramId);

	const handleBackBtn = () => {
		navigate(-1);
	};

	const { notification } = App.useApp();

	const {
		data: apps,
		isLoading: isLoadingApplications,
		error: applicationsError,
	} = useQuery({
		queryKey: ['applications'],
		queryFn: () =>
			applicationsService
				.getApplicationsList(0, 50, undefined)
				.then((res) => res.data.content),
		initialData: [],
	});

	const downloadOnClick = async (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault();

		if (!engine || engine.id === undefined) {
			mcErrorNotification(
				'Error',
				'Engine is not available for downloading.',
				'download',
				'engines'
			);
			return;
		}

		try {
			const downloadTicket = await enginesService
				.getEngineFile(engine.id)
				.then((r) => r.data);

			const link = document.createElement('a');
			link.href = downloadTicket.url!;
			link.download = downloadTicket.fileName;
			link.click();
		} catch (error) {
			mcErrorNotification(
				'Error',
				'Failed to download the engine file.',
				'download',
				'engines'
			);
		}
	};

	useEffect(() => {
		if (!applicationsError) return;
		notification.error(
			mcErrorNotification('Error', applicationsError, 'fetch', 'applications')
		);
	}, [applicationsError, notification]);

	const {
		data: engine,
		isLoading: isLoadingEngine,
		error: getEngineError,
	} = useQuery({
		queryKey: ['engine', id],
		queryFn: () => enginesService.getEngineById(id).then((res) => res.data),
		initialData: undefined,
		enabled: !isNaN(id) && Number.isInteger(id),
	});

	const {
		data: rels,
		isLoading: isLoadingAppReleases,
		error: getReleasesError,
	} = useQuery({
		queryKey: ['engines', id, 'releases'],
		queryFn: () =>
			enginesService
				.getApplicationReleasesByEngineId(id)
				.then((res) => res.data),
		initialData: [],
		enabled: !isNaN(id) && Number.isInteger(id) && !!engine,
	});

	useEffect(() => {
		if (!getReleasesError) return;
		notification.error(
			mcErrorNotification(
				'Error',
				getReleasesError,
				'fetch',
				'application releases'
			)
		);
	}, [getReleasesError, notification]);

	useEffect(() => {
		if (!getEngineError) return;
		notification.error(
			mcErrorNotification('Error', getEngineError, 'fetch', 'engine')
		);
	}, [getEngineError, notification]);

	const loading =
		isLoadingApplications || isLoadingAppReleases || isLoadingEngine;

	const hasError =
		!!getEngineError || !!getReleasesError || !!applicationsError;

	const appRels = useMemo(
		() =>
			rels.map(
				(rel) =>
					[rel, (apps ?? []).find((a) => a.id === rel.applicationId)] as [
						ApplicationReleaseDto,
						ApplicationDto | undefined
					]
			),
		[apps, rels]
	);

	return !loading && (!!hasError || !engine) ? (
		<NotFoundElement element={'engine'} />
	) : loading ? (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				width: '100%',
				padding: '32px',
			}}
		>
			<Spin spinning={loading} indicator={<McSpinner />} size="large" />
		</div>
	) : !!engine ? (
		<div className={style.container}>
			<div className={style.header}>
				<CloudLargeIcon />
				<h1 className={style.title}>{engine.name}</h1>
			</div>
			<Cond if={engine.fullSizeImagePath}>
				<div className={style.imgContainer}>
					<img alt="" src={getPublicImageURL(engine.fullSizeImagePath!)} />
				</div>
			</Cond>
			<div className="flex between wrap" style={{ gap: '2rem' }}>
				<Spin spinning={loading} style={{ flex: 1 }}>
					<div className={style.appLogos} style={{ gap: '2rem' }}>
						{appRels.map(([rel, app]) => (
							<div key={rel.id} className={style.appLogo}>
								<img
									alt={`${app?.name} icon`}
									src={getPublicImageURL(app?.iconImagePath as string)}
								/>
								{app?.name}
							</div>
						))}
					</div>
				</Spin>
				<a
					style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
					onClick={downloadOnClick}
				>
					Download Engine
					<DownloadIcon />
				</a>
			</div>
			<div className={style.descriptionContainer}>
				{engine.description && (
					<h2 className={style.descriptionTitle}>Description</h2>
				)}

				<McMarkdown content={engine.description} />
			</div>
			{engine.releaseNotes && (
				<div className={style.releaseNotesContainer}>
					<div className="flex align-center" style={{ gap: '0.75rem' }}>
						<h2 className={style.releaseNotesTitle}>Release Notes</h2>
						<h2>•</h2>
						<h3>{engine.releaseDate}</h3>
					</div>
					<div className={style.markdownContent}>
						<McMarkdown content={engine.releaseNotes} />
					</div>
				</div>
			)}
			<h4 className={style.backButton} onClick={handleBackBtn}>
				<ArrowLeftIcon />
				<span>Back</span>
			</h4>
		</div>
	) : (
		<NotFoundElement element={'engine'} />
	);
};
