import { useEffect, useState } from 'react';
import style from 'assets/styles/manageElements.module.scss';
import { SearchField } from 'components/fields';
import { AddButton } from 'components/buttons';
import { App, Spin } from 'antd';
import { AlertCircle } from 'assets/icons/AlertCircle';
import ManageOneOrganization from 'modules/ManageOrganizations/ManageOneOrganization';
import { useParams } from 'react-router-dom';
import { useDebounce } from 'hooks/useDebounce';
import EditOrganizationForm from 'modules/ManageOrganizations/EditOrganizationForm';
import adminOrganizationService from 'services/admin/AdminOrganizationService';
import { OrganizationDto } from 'api';
import { McButton } from 'components/mc';
import { errorNotification, warningNotification } from 'utils/Notifications';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useAppSelector } from 'hooks/hooks';
import { authState } from 'store/slices/auth';
import { hasWritePermission } from 'utils/permissions';

export const ManageOrganizations = () => {
	const params = useParams();
	const { notification } = App.useApp();
	const [showOrganizationForm, setShowOrganizationForm] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const [searchText, setSearchText] = useState('');
	const debouncedSearch = useDebounce(searchText, 700);
	const [selectedOrganization, setSelectedOrganization] = useState<
		OrganizationDto | undefined
	>(undefined);
	const PAGE_SIZE = 10;

	// Fetch the selected organization by URL param
	const {
		data: paramSelectedOrganization,
		error: getParamSelectedOrganizationError,
		isSuccess: isFetchingParamOrganizationSuccess,
	} = useQuery({
		queryKey: ['organizations', params.id],
		queryFn: () =>
			adminOrganizationService
				.getOrganizationById(Number(params.id))
				.then((res) => res.data),
		initialData: undefined,
		enabled: !!params.id,
	});

	// Fetch total amount of organizations
	const { data: amountOfOrganizations, error: amountOfOrganizationsError } =
		useQuery({
			queryKey: ['organizations', 'count'],
			queryFn: () =>
				adminOrganizationService
					.getAmountOfOrganizations()
					.then((res) => res.data),
			initialData: 0,
		});

	// Fetch organizations paginated infinite query
	const {
		data: organizationsPages,
		fetchNextPage: fetchNextOrganizationPage,
		hasNextPage: hasNextOrganizationPage,
		error: getOrganizationsError,
		isLoading: loading,
		isFetching: isFetchingOrganizations,
	} = useInfiniteQuery({
		queryKey: ['organizations', 'infinite', debouncedSearch], // Include debounced search to reset query when something is changed
		queryFn: ({ pageParam: pageNr }) =>
			adminOrganizationService
				.getOrganizations(pageNr, PAGE_SIZE, debouncedSearch)
				.then((res) => res.data),
		initialPageParam: 0,
		getNextPageParam: (lastPage) => {
			const nextPageNr = lastPage.pageNumber + 1;
			return nextPageNr < lastPage.allPages ? nextPageNr : undefined;
		},
	});

	const { permissions } = useAppSelector(authState);

	const canEdit = hasWritePermission(permissions, 'organizations');

	useEffect(() => {
		if (!isFetchingParamOrganizationSuccess) return;
		setSelectedOrganization(paramSelectedOrganization);
	}, [isFetchingParamOrganizationSuccess, paramSelectedOrganization]);

	useEffect(() => {
		if (!getOrganizationsError) return;
		notification.error(
			errorNotification('Failed to fetch requested organizations!')
		);
	}, [getOrganizationsError, notification]);

	useEffect(() => {
		if (!amountOfOrganizationsError) return;
		notification.warning(
			warningNotification('Could not fetch total amount of organizations!')
		);
	}, [amountOfOrganizationsError, notification]);

	useEffect(() => {
		if (!getParamSelectedOrganizationError) return;
		notification.warning(
			warningNotification(
				'Failed to fetch organization provided by url parameter!'
			)
		);
	}, [getParamSelectedOrganizationError, notification]);

	const organizations = !organizationsPages
		? []
		: organizationsPages.pages.reduce(
				(acc, page) => [...acc, ...page.content],
				[] as OrganizationDto[]
		  );

	const allOrganizations = !!organizationsPages
		? organizationsPages.pages[0].allElements
		: 0;

	return (
		<div className={style.wrapper}>
			<div className={style.container}>
				<h1 className={style.header}>Manage Organizations</h1>
				{amountOfOrganizations === 0 && !selectedOrganization && !loading ? (
					<>
						<div
							className={style.noElementsInfo}
							style={{ marginTop: '2rem', marginBottom: '1.5rem' }}
						>
							There are no Organizations to show here
							<AlertCircle />
						</div>
						<AddButton
							onClick={() => setShowOrganizationForm((prev) => !prev)}
							disabled={!canEdit}
						/>
					</>
				) : (
					<>
						<div className={style.amountInfo}>
							{`Displaying ${organizations.length} out of ${allOrganizations} organizations`}
						</div>
						<div className={style.searchAddFields}>
							<div
								style={{ flex: 'auto' }}
								className={style.searchFieldWrapper}
							>
								<SearchField
									placeholder={'Search'}
									value={searchText}
									onChange={(e) => {
										setSearchText(e.target.value);
									}}
								/>
							</div>
							<AddButton
								onClick={() => setShowOrganizationForm((prev) => !prev)}
								disabled={!canEdit}
							/>
						</div>
						{organizations.length === 0 && !loading && (
							<div
								className={style.noElementsInfo}
								style={{ marginTop: '2rem', marginBottom: '1.5rem' }}
							>
								No Organizations found
								<AlertCircle />
							</div>
						)}
					</>
				)}
			</div>
			<div style={{ marginTop: '2rem' }}>
				{showOrganizationForm && (
					<EditOrganizationForm
						organization={'new'}
						setSelected={setSelectedOrganization}
						onHide={() => setShowOrganizationForm(false)}
					/>
				)}
			</div>
			{organizations.length !== 0 ? (
				<h3 className={style.listTitle}>Organization List</h3>
			) : (
				<></>
			)}
			<div>
				<Spin spinning={loading} size="large">
					<div className={style.itemsContainer}>
						{params.id && selectedOrganization !== undefined ? (
							<ManageOneOrganization
								organization={selectedOrganization}
								open={true}
								setSelected={setSelectedOrganization}
								setRefresh={setRefresh}
								refresh={refresh}
							/>
						) : (
							organizations.map((organization) => {
								return (
									<div key={organization.id}>
										<ManageOneOrganization
											organization={organization}
											open={organization.id === selectedOrganization?.id}
											setSelected={setSelectedOrganization}
											setRefresh={setRefresh}
											refresh={refresh}
										/>
									</div>
								);
							})
						)}
					</div>
				</Spin>
			</div>
			{hasNextOrganizationPage && (
				<div
					className={style.paginationContainer}
					onClick={() => fetchNextOrganizationPage()}
				>
					<McButton disabled={loading || isFetchingOrganizations}>
						View more
					</McButton>
				</div>
			)}
		</div>
	);
};

export default ManageOrganizations;
