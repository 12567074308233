import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

export interface UpdaterInterface {
	open: boolean;
}

const initialState: UpdaterInterface = {
	open: true,
};

const updaterSlice = createSlice({
	name: 'updater',
	initialState: initialState,
	reducers: {
		setUpdaterOpen: (state, action) => {
			state.open = action.payload;
		},
	},
});

export const updaterState = (state: RootState) => state.updater;

export const { setUpdaterOpen } = updaterSlice.actions;

export default updaterSlice.reducer;
