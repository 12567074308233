/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Motion Cloud
 * API for Motion Cloud web application
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ApplicationReleaseSaveDto } from '../model';
import { ApplicationReleaseSortOrder } from '../model';
import { EntityStatus } from '../model';
import { ErrorDTO } from '../model';
import { PageDtoApplicationReleaseDto } from '../model';
/**
 * AdminApplicationReleasesApi - axios parameter creator
 * @export
 */
export const AdminApplicationReleasesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create application release
         * @param {ApplicationReleaseSaveDto} [applicationDto] 
         * @param {Blob} [avatarPic] 
         * @param {Blob} [basicPic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApplicationReleaseForm: async (applicationDto?: ApplicationReleaseSaveDto, avatarPic?: Blob, basicPic?: Blob, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/applications/releases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


            if (applicationDto !== undefined) {
                const applicationDtoValue = typeof applicationDto === 'object' && !(applicationDto instanceof Blob) ? JSON.stringify(applicationDto) : applicationDto;
                localVarFormParams.append('applicationDto', applicationDtoValue as any);
            }

            if (avatarPic !== undefined) {
                const avatarPicValue = typeof avatarPic === 'object' && !(avatarPic instanceof Blob) ? JSON.stringify(avatarPic) : avatarPic;
                localVarFormParams.append('avatarPic', avatarPicValue as any);
            }

            if (basicPic !== undefined) {
                const basicPicValue = typeof basicPic === 'object' && !(basicPic instanceof Blob) ? JSON.stringify(basicPic) : basicPic;
                localVarFormParams.append('basicPic', basicPicValue as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete application release by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApplicationReleaseById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteApplicationReleaseById.');
            }
            const localVarPath = `/api/admin/applications/releases/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete application releases basic picture by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApplicationReleasesBasicPicture: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteApplicationReleasesBasicPicture.');
            }
            const localVarPath = `/api/admin/applications/releases/{id}/picture`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete application releases icon picture by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApplicationReleasesIconPicture: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteApplicationReleasesIconPicture.');
            }
            const localVarPath = `/api/admin/applications/releases/{id}/icon`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of Applications releases with pagination, filtering by entity status and application ids
         * @param {number} page 
         * @param {number} size 
         * @param {string} [searchedValue] 
         * @param {number} [enginesId] 
         * @param {ApplicationReleaseSortOrder} [sortOrder] 
         * @param {EntityStatus} [entityStatus] 
         * @param {number} [applicationsId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationReleasesList: async (page: number, size: number, searchedValue?: string, enginesId?: number, sortOrder?: ApplicationReleaseSortOrder, entityStatus?: EntityStatus, applicationsId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            if (page === null || page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling getApplicationReleasesList.');
            }
            // verify required parameter 'size' is not null or undefined
            if (size === null || size === undefined) {
                throw new RequiredError('size','Required parameter size was null or undefined when calling getApplicationReleasesList.');
            }
            const localVarPath = `/api/admin/applications/releases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (searchedValue !== undefined) {
                localVarQueryParameter['searchedValue'] = searchedValue;
            }

            if (enginesId !== undefined) {
                localVarQueryParameter['enginesId'] = enginesId;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (entityStatus !== undefined) {
                localVarQueryParameter['entityStatus'] = entityStatus;
            }

            if (applicationsId !== undefined) {
                localVarQueryParameter['applicationsId'] = applicationsId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update application release by id
         * @param {number} applicationReleaseId 
         * @param {ApplicationReleaseSaveDto} [dto] 
         * @param {Blob} [avatarPic] 
         * @param {Blob} [basicPic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplicationReleaseForm: async (applicationReleaseId: number, dto?: ApplicationReleaseSaveDto, avatarPic?: Blob, basicPic?: Blob, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationReleaseId' is not null or undefined
            if (applicationReleaseId === null || applicationReleaseId === undefined) {
                throw new RequiredError('applicationReleaseId','Required parameter applicationReleaseId was null or undefined when calling updateApplicationReleaseForm.');
            }
            const localVarPath = `/api/admin/applications/releases/{applicationReleaseId}`
                .replace(`{${"applicationReleaseId"}}`, encodeURIComponent(String(applicationReleaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


            if (dto !== undefined) {
                const dtoValue = typeof dto === 'object' && !(dto instanceof Blob) ? JSON.stringify(dto) : dto;
                localVarFormParams.append('dto', dtoValue as any);
            }

            if (avatarPic !== undefined) {
                const avatarPicValue = typeof avatarPic === 'object' && !(avatarPic instanceof Blob) ? JSON.stringify(avatarPic) : avatarPic;
                localVarFormParams.append('avatarPic', avatarPicValue as any);
            }

            if (basicPic !== undefined) {
                const basicPicValue = typeof basicPic === 'object' && !(basicPic instanceof Blob) ? JSON.stringify(basicPic) : basicPic;
                localVarFormParams.append('basicPic', basicPicValue as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApplicationReleasesApi - functional programming interface
 * @export
 */
export const AdminApplicationReleasesApiFp = function(axiosInstance: Promise<AxiosInstance>, configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create application release
         * @param {ApplicationReleaseSaveDto} [applicationDto] 
         * @param {Blob} [avatarPic] 
         * @param {Blob} [basicPic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createApplicationReleaseForm(applicationDto?: ApplicationReleaseSaveDto, avatarPic?: Blob, basicPic?: Blob, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await AdminApplicationReleasesApiAxiosParamCreator(configuration).createApplicationReleaseForm(applicationDto, avatarPic, basicPic, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Delete application release by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApplicationReleaseById(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await AdminApplicationReleasesApiAxiosParamCreator(configuration).deleteApplicationReleaseById(id, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Delete application releases basic picture by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApplicationReleasesBasicPicture(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await AdminApplicationReleasesApiAxiosParamCreator(configuration).deleteApplicationReleasesBasicPicture(id, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Delete application releases icon picture by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApplicationReleasesIconPicture(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await AdminApplicationReleasesApiAxiosParamCreator(configuration).deleteApplicationReleasesIconPicture(id, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get list of Applications releases with pagination, filtering by entity status and application ids
         * @param {number} page 
         * @param {number} size 
         * @param {string} [searchedValue] 
         * @param {number} [enginesId] 
         * @param {ApplicationReleaseSortOrder} [sortOrder] 
         * @param {EntityStatus} [entityStatus] 
         * @param {number} [applicationsId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationReleasesList(page: number, size: number, searchedValue?: string, enginesId?: number, sortOrder?: ApplicationReleaseSortOrder, entityStatus?: EntityStatus, applicationsId?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<PageDtoApplicationReleaseDto>> {
            const localVarAxiosArgs = await AdminApplicationReleasesApiAxiosParamCreator(configuration).getApplicationReleasesList(page, size, searchedValue, enginesId, sortOrder, entityStatus, applicationsId, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Update application release by id
         * @param {number} applicationReleaseId 
         * @param {ApplicationReleaseSaveDto} [dto] 
         * @param {Blob} [avatarPic] 
         * @param {Blob} [basicPic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateApplicationReleaseForm(applicationReleaseId: number, dto?: ApplicationReleaseSaveDto, avatarPic?: Blob, basicPic?: Blob, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await AdminApplicationReleasesApiAxiosParamCreator(configuration).updateApplicationReleaseForm(applicationReleaseId, dto, avatarPic, basicPic, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
    }
};

/**
 * AdminApplicationReleasesApi - factory interface
 * @export
 */
export const AdminApplicationReleasesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create application release
         * @param {ApplicationReleaseSaveDto} [applicationDto] 
         * @param {Blob} [avatarPic] 
         * @param {Blob} [basicPic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createApplicationReleaseForm(applicationDto?: ApplicationReleaseSaveDto, avatarPic?: Blob, basicPic?: Blob, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AdminApplicationReleasesApiFp(configuration).createApplicationReleaseForm(applicationDto, avatarPic, basicPic, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete application release by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApplicationReleaseById(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AdminApplicationReleasesApiFp(configuration).deleteApplicationReleaseById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete application releases basic picture by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApplicationReleasesBasicPicture(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AdminApplicationReleasesApiFp(configuration).deleteApplicationReleasesBasicPicture(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete application releases icon picture by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApplicationReleasesIconPicture(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AdminApplicationReleasesApiFp(configuration).deleteApplicationReleasesIconPicture(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of Applications releases with pagination, filtering by entity status and application ids
         * @param {number} page 
         * @param {number} size 
         * @param {string} [searchedValue] 
         * @param {number} [enginesId] 
         * @param {ApplicationReleaseSortOrder} [sortOrder] 
         * @param {EntityStatus} [entityStatus] 
         * @param {number} [applicationsId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationReleasesList(page: number, size: number, searchedValue?: string, enginesId?: number, sortOrder?: ApplicationReleaseSortOrder, entityStatus?: EntityStatus, applicationsId?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<PageDtoApplicationReleaseDto>> {
            return AdminApplicationReleasesApiFp(configuration).getApplicationReleasesList(page, size, searchedValue, enginesId, sortOrder, entityStatus, applicationsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update application release by id
         * @param {number} applicationReleaseId 
         * @param {ApplicationReleaseSaveDto} [dto] 
         * @param {Blob} [avatarPic] 
         * @param {Blob} [basicPic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateApplicationReleaseForm(applicationReleaseId: number, dto?: ApplicationReleaseSaveDto, avatarPic?: Blob, basicPic?: Blob, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AdminApplicationReleasesApiFp(configuration).updateApplicationReleaseForm(applicationReleaseId, dto, avatarPic, basicPic, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApplicationReleasesApi - object-oriented interface
 * @export
 * @class AdminApplicationReleasesApi
 * @extends {BaseAPI}
 */
export class AdminApplicationReleasesApi extends BaseAPI {
    /**
     * 
     * @summary Create application release
     * @param {ApplicationReleaseSaveDto} [applicationDto] 
     * @param {Blob} [avatarPic] 
     * @param {Blob} [basicPic] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApplicationReleasesApi
     */
    public async createApplicationReleaseForm(applicationDto?: ApplicationReleaseSaveDto, avatarPic?: Blob, basicPic?: Blob, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AdminApplicationReleasesApiFp(this.configuration).createApplicationReleaseForm(applicationDto, avatarPic, basicPic, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete application release by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApplicationReleasesApi
     */
    public async deleteApplicationReleaseById(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AdminApplicationReleasesApiFp(this.configuration).deleteApplicationReleaseById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete application releases basic picture by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApplicationReleasesApi
     */
    public async deleteApplicationReleasesBasicPicture(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AdminApplicationReleasesApiFp(this.configuration).deleteApplicationReleasesBasicPicture(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete application releases icon picture by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApplicationReleasesApi
     */
    public async deleteApplicationReleasesIconPicture(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AdminApplicationReleasesApiFp(this.configuration).deleteApplicationReleasesIconPicture(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get list of Applications releases with pagination, filtering by entity status and application ids
     * @param {number} page 
     * @param {number} size 
     * @param {string} [searchedValue] 
     * @param {number} [enginesId] 
     * @param {ApplicationReleaseSortOrder} [sortOrder] 
     * @param {EntityStatus} [entityStatus] 
     * @param {number} [applicationsId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApplicationReleasesApi
     */
    public async getApplicationReleasesList(page: number, size: number, searchedValue?: string, enginesId?: number, sortOrder?: ApplicationReleaseSortOrder, entityStatus?: EntityStatus, applicationsId?: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<PageDtoApplicationReleaseDto>> {
        return AdminApplicationReleasesApiFp(this.configuration).getApplicationReleasesList(page, size, searchedValue, enginesId, sortOrder, entityStatus, applicationsId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update application release by id
     * @param {number} applicationReleaseId 
     * @param {ApplicationReleaseSaveDto} [dto] 
     * @param {Blob} [avatarPic] 
     * @param {Blob} [basicPic] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApplicationReleasesApi
     */
    public async updateApplicationReleaseForm(applicationReleaseId: number, dto?: ApplicationReleaseSaveDto, avatarPic?: Blob, basicPic?: Blob, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AdminApplicationReleasesApiFp(this.configuration).updateApplicationReleaseForm(applicationReleaseId, dto, avatarPic, basicPic, options).then((request) => request(this.axios, this.basePath));
    }
}
