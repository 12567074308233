/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Motion Cloud
 * API for Motion Cloud web application
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { AddOrganizationDto } from '../model';
import { ErrorDTO } from '../model';
import { OrganizationDto } from '../model';
import { PageDtoOrganizationDto } from '../model';
import { PageDtoUserDto } from '../model';
/**
 * AdminOrganizationsApi - axios parameter creator
 * @export
 */
export const AdminOrganizationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a new organization and returns the corresponding dto
         * @param {AddOrganizationDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrganization: async (body: AddOrganizationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addOrganization.');
            }
            const localVarPath = `/api/admin/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a user to an existing organization
         * @param {number} organizationId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserToOrganization: async (organizationId: number, userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling addUserToOrganization.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling addUserToOrganization.');
            }
            const localVarPath = `/api/admin/organizations/{organizationId}/users`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the total amount of organizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAmountOfOrganizations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/organizations/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get amount of users in organization
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAmountOfUsersInOrganization: async (organizationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling getAmountOfUsersInOrganization.');
            }
            const localVarPath = `/api/admin/organizations/{organizationId}/users/count`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an organization by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOrganizationById.');
            }
            const localVarPath = `/api/admin/organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get pageable organizations
         * @param {number} page 
         * @param {number} size 
         * @param {string} [searchedValue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizations: async (page: number, size: number, searchedValue?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            if (page === null || page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling getOrganizations.');
            }
            // verify required parameter 'size' is not null or undefined
            if (size === null || size === undefined) {
                throw new RequiredError('size','Required parameter size was null or undefined when calling getOrganizations.');
            }
            const localVarPath = `/api/admin/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (searchedValue !== undefined) {
                localVarQueryParameter['searchedValue'] = searchedValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get pageable users that are part of the specified organization
         * @param {number} page 
         * @param {number} size 
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersInOrganization: async (page: number, size: number, organizationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            if (page === null || page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling getUsersInOrganization.');
            }
            // verify required parameter 'size' is not null or undefined
            if (size === null || size === undefined) {
                throw new RequiredError('size','Required parameter size was null or undefined when calling getUsersInOrganization.');
            }
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling getUsersInOrganization.');
            }
            const localVarPath = `/api/admin/organizations/{organizationId}/users`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove an existing organization
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOrganization: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling removeOrganization.');
            }
            const localVarPath = `/api/admin/organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a user from an existing organization
         * @param {number} organizationId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromOrganization: async (organizationId: number, userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling removeUserFromOrganization.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling removeUserFromOrganization.');
            }
            const localVarPath = `/api/admin/organizations/{organizationId}/users/{userId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing organization
         * @param {OrganizationDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganization: async (body: OrganizationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateOrganization.');
            }
            const localVarPath = `/api/admin/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminOrganizationsApi - functional programming interface
 * @export
 */
export const AdminOrganizationsApiFp = function(axiosInstance: Promise<AxiosInstance>, configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a new organization and returns the corresponding dto
         * @param {AddOrganizationDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrganization(body: AddOrganizationDto, options?: AxiosRequestConfig): Promise<AxiosResponse<OrganizationDto>> {
            const localVarAxiosArgs = await AdminOrganizationsApiAxiosParamCreator(configuration).addOrganization(body, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Add a user to an existing organization
         * @param {number} organizationId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUserToOrganization(organizationId: number, userId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await AdminOrganizationsApiAxiosParamCreator(configuration).addUserToOrganization(organizationId, userId, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Returns the total amount of organizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAmountOfOrganizations(options?: AxiosRequestConfig): Promise<AxiosResponse<number>> {
            const localVarAxiosArgs = await AdminOrganizationsApiAxiosParamCreator(configuration).getAmountOfOrganizations(options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get amount of users in organization
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAmountOfUsersInOrganization(organizationId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<number>> {
            const localVarAxiosArgs = await AdminOrganizationsApiAxiosParamCreator(configuration).getAmountOfUsersInOrganization(organizationId, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get an organization by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationById(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<OrganizationDto>> {
            const localVarAxiosArgs = await AdminOrganizationsApiAxiosParamCreator(configuration).getOrganizationById(id, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get pageable organizations
         * @param {number} page 
         * @param {number} size 
         * @param {string} [searchedValue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizations(page: number, size: number, searchedValue?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<PageDtoOrganizationDto>> {
            const localVarAxiosArgs = await AdminOrganizationsApiAxiosParamCreator(configuration).getOrganizations(page, size, searchedValue, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get pageable users that are part of the specified organization
         * @param {number} page 
         * @param {number} size 
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersInOrganization(page: number, size: number, organizationId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<PageDtoUserDto>> {
            const localVarAxiosArgs = await AdminOrganizationsApiAxiosParamCreator(configuration).getUsersInOrganization(page, size, organizationId, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Remove an existing organization
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeOrganization(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await AdminOrganizationsApiAxiosParamCreator(configuration).removeOrganization(id, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Remove a user from an existing organization
         * @param {number} organizationId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeUserFromOrganization(organizationId: number, userId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await AdminOrganizationsApiAxiosParamCreator(configuration).removeUserFromOrganization(organizationId, userId, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Update an existing organization
         * @param {OrganizationDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrganization(body: OrganizationDto, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await AdminOrganizationsApiAxiosParamCreator(configuration).updateOrganization(body, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
    }
};

/**
 * AdminOrganizationsApi - factory interface
 * @export
 */
export const AdminOrganizationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Add a new organization and returns the corresponding dto
         * @param {AddOrganizationDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrganization(body: AddOrganizationDto, options?: AxiosRequestConfig): Promise<AxiosResponse<OrganizationDto>> {
            return AdminOrganizationsApiFp(configuration).addOrganization(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a user to an existing organization
         * @param {number} organizationId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUserToOrganization(organizationId: number, userId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AdminOrganizationsApiFp(configuration).addUserToOrganization(organizationId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the total amount of organizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAmountOfOrganizations(options?: AxiosRequestConfig): Promise<AxiosResponse<number>> {
            return AdminOrganizationsApiFp(configuration).getAmountOfOrganizations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get amount of users in organization
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAmountOfUsersInOrganization(organizationId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<number>> {
            return AdminOrganizationsApiFp(configuration).getAmountOfUsersInOrganization(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get an organization by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationById(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<OrganizationDto>> {
            return AdminOrganizationsApiFp(configuration).getOrganizationById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get pageable organizations
         * @param {number} page 
         * @param {number} size 
         * @param {string} [searchedValue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizations(page: number, size: number, searchedValue?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<PageDtoOrganizationDto>> {
            return AdminOrganizationsApiFp(configuration).getOrganizations(page, size, searchedValue, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get pageable users that are part of the specified organization
         * @param {number} page 
         * @param {number} size 
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersInOrganization(page: number, size: number, organizationId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<PageDtoUserDto>> {
            return AdminOrganizationsApiFp(configuration).getUsersInOrganization(page, size, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove an existing organization
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeOrganization(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AdminOrganizationsApiFp(configuration).removeOrganization(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a user from an existing organization
         * @param {number} organizationId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeUserFromOrganization(organizationId: number, userId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AdminOrganizationsApiFp(configuration).removeUserFromOrganization(organizationId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing organization
         * @param {OrganizationDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrganization(body: OrganizationDto, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AdminOrganizationsApiFp(configuration).updateOrganization(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminOrganizationsApi - object-oriented interface
 * @export
 * @class AdminOrganizationsApi
 * @extends {BaseAPI}
 */
export class AdminOrganizationsApi extends BaseAPI {
    /**
     * 
     * @summary Add a new organization and returns the corresponding dto
     * @param {AddOrganizationDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationsApi
     */
    public async addOrganization(body: AddOrganizationDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<OrganizationDto>> {
        return AdminOrganizationsApiFp(this.configuration).addOrganization(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Add a user to an existing organization
     * @param {number} organizationId 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationsApi
     */
    public async addUserToOrganization(organizationId: number, userId: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AdminOrganizationsApiFp(this.configuration).addUserToOrganization(organizationId, userId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Returns the total amount of organizations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationsApi
     */
    public async getAmountOfOrganizations(options?: AxiosRequestConfig) : Promise<AxiosResponse<number>> {
        return AdminOrganizationsApiFp(this.configuration).getAmountOfOrganizations(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get amount of users in organization
     * @param {number} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationsApi
     */
    public async getAmountOfUsersInOrganization(organizationId: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<number>> {
        return AdminOrganizationsApiFp(this.configuration).getAmountOfUsersInOrganization(organizationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get an organization by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationsApi
     */
    public async getOrganizationById(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<OrganizationDto>> {
        return AdminOrganizationsApiFp(this.configuration).getOrganizationById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get pageable organizations
     * @param {number} page 
     * @param {number} size 
     * @param {string} [searchedValue] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationsApi
     */
    public async getOrganizations(page: number, size: number, searchedValue?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<PageDtoOrganizationDto>> {
        return AdminOrganizationsApiFp(this.configuration).getOrganizations(page, size, searchedValue, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get pageable users that are part of the specified organization
     * @param {number} page 
     * @param {number} size 
     * @param {number} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationsApi
     */
    public async getUsersInOrganization(page: number, size: number, organizationId: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<PageDtoUserDto>> {
        return AdminOrganizationsApiFp(this.configuration).getUsersInOrganization(page, size, organizationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Remove an existing organization
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationsApi
     */
    public async removeOrganization(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AdminOrganizationsApiFp(this.configuration).removeOrganization(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Remove a user from an existing organization
     * @param {number} organizationId 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationsApi
     */
    public async removeUserFromOrganization(organizationId: number, userId: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AdminOrganizationsApiFp(this.configuration).removeUserFromOrganization(organizationId, userId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update an existing organization
     * @param {OrganizationDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationsApi
     */
    public async updateOrganization(body: OrganizationDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AdminOrganizationsApiFp(this.configuration).updateOrganization(body, options).then((request) => request(this.axios, this.basePath));
    }
}
