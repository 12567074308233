import { useInfiniteQuery } from '@tanstack/react-query';
import { App, Empty, Spin, Tooltip } from 'antd';
import { ContainerDto, ContainerType } from 'api';
import { useDebounce } from 'hooks/useDebounce';
import { useEffect, useMemo, useState } from 'react';
import { mcErrorNotification } from 'utils/Notifications';
import style from 'assets/styles/manageElements.module.scss';
import { AlertCircle } from 'assets/icons/AlertCircle';
import { McButton } from 'components/mc';
import ManageOneContainer from 'modules/ManageContainers/ManageOneContainer';
import { InfoIcon } from 'assets/icons/svg';
import FilterTagContainer from 'components/FilterTagContainer';
import FilterTagField, {
	FilterCategory,
} from 'components/fields/FilterTagField';
import { SearchField } from 'components/fields';
import { SelectCustomer } from 'components/selects';
import adminContainerService from 'services/admin/AdminContainerService';

const ManageContainers = () => {
	const [searchedValue, setSearchedValue] = useState('');
	const debouncedSearchTerm = useDebounce(searchedValue, 700);
	const PAGE_SIZE = 10;
	const { notification } = App.useApp();
	const [activatedByUserId, setActivatedByUserId] = useState<
		number | undefined
	>(undefined);
	const [activeFilter, setActiveFilter] = useState(false);
	const [inactiveFilter, setInactiveFilter] = useState(false);
	const [dongleFilter, setDongleFilter] = useState(false);
	const [softwareFilter, setSoftwareFilter] = useState(false);

	const activeLicensesStatus: boolean | undefined = useMemo(
		() => (activeFilter ? true : inactiveFilter ? false : undefined),
		[activeFilter, inactiveFilter]
	);
	const containerType: ContainerType | undefined = useMemo(
		() => (dongleFilter ? 'DONGLE' : softwareFilter ? 'SOFTWARE' : undefined),
		[dongleFilter, softwareFilter]
	);

	// Fetch organizations paginated infinite query
	const {
		data: containerPages,
		fetchNextPage: fetchNextContainerPage,
		hasNextPage: hasNextContainerPage,
		error: getContainerPageError,
		isLoading,
		isFetching: isFetchingContainers,
	} = useInfiniteQuery({
		queryKey: [
			'containers',
			'infinite',
			debouncedSearchTerm,
			containerType,
			activeLicensesStatus,
			activatedByUserId,
		],
		queryFn: ({ pageParam: pageNr }) =>
			adminContainerService
				.getPaginatedContainers(
					pageNr,
					PAGE_SIZE,
					debouncedSearchTerm,
					containerType,
					activeLicensesStatus,
					activatedByUserId
				)
				.then((res) => res.data),
		initialPageParam: 0,
		getNextPageParam: (lastPage) => {
			const nextPageNr = lastPage.pageNumber + 1;
			return nextPageNr < lastPage.allPages ? nextPageNr : undefined;
		},
	});

	const containers = useMemo(
		() =>
			!containerPages
				? []
				: containerPages.pages.reduce(
						(acc, page) => [...acc, ...page.content],
						[] as ContainerDto[]
				  ),
		[containerPages]
	);

	const totalContainersCount =
		!containerPages || !containerPages.pages || containerPages.pages.length < 1
			? 0
			: containerPages.pages[0].allElements;

	useEffect(() => {
		if (!getContainerPageError) return;
		notification.warning(
			mcErrorNotification(
				'Warning',
				getContainerPageError,
				'fetch',
				'container list'
			)
		);
	}, [getContainerPageError, notification]);

	const filterCategories: FilterCategory[] = useMemo(() => {
		const statusCategory: FilterCategory = {
			categoryName: 'Status',
			filterItems: [
				{
					label: 'Has active licenses',
					enabled: activeFilter,
					toggleEnabled: () => setActiveFilter((prev) => !prev),
					isOptionDisabled: inactiveFilter,
				},
				{
					label: 'No active licenses',
					enabled: inactiveFilter,
					toggleEnabled: () => setInactiveFilter((prev) => !prev),
					isOptionDisabled: activeFilter,
				},
			],
		};
		const subscriptionTypeCategory: FilterCategory = {
			categoryName: 'Container Type',
			filterItems: [
				{
					label: 'Dongle',
					enabled: dongleFilter,
					toggleEnabled: () => setDongleFilter((prev) => !prev),
					isOptionDisabled: softwareFilter,
				},
				{
					label: 'Software',
					enabled: softwareFilter,
					toggleEnabled: () => setSoftwareFilter((prev) => !prev),
					isOptionDisabled: dongleFilter,
				},
			],
		};

		return [statusCategory, subscriptionTypeCategory];
	}, [activeFilter, dongleFilter, inactiveFilter, softwareFilter]);

	return (
		<>
			<div className={style.container}>
				<h1 className={style.header}>Manage Containers</h1>
				{!isLoading &&
				searchedValue === '' &&
				activatedByUserId === undefined &&
				containerType === undefined &&
				activeLicensesStatus === undefined &&
				totalContainersCount === 0 ? (
					<div
						className={style.noElementsInfo}
						style={{ marginTop: '2rem', marginBottom: '1.5rem' }}
					>
						No Containers found
						<AlertCircle />
					</div>
				) : (
					<>
						<div className={style.amountInfo}>
							{`Displaying ${containers.length} out of ${totalContainersCount} containers`}
						</div>
						<div className={style.searchAddFields}>
							<div
								style={{ flex: 'auto' }}
								className={style.searchFieldWrapper}
							>
								<SearchField
									placeholder={'Search'}
									value={searchedValue}
									onChange={(e) => {
										setSearchedValue(e.target.value);
									}}
								/>
							</div>
							<div>
								<SelectCustomer
									userId={activatedByUserId}
									setUserId={setActivatedByUserId}
									allowClear={true}
								/>
							</div>
							<div>
								<FilterTagField filterCategories={filterCategories} />
							</div>
						</div>
					</>
				)}
				<FilterTagContainer filterCategories={filterCategories} />
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						gap: '1rem',
						alignItems: 'baseline',
					}}
				>
					<h3 className={style.listTitle}>Unique Container List</h3>
					<Tooltip title="Unique containers that have been activated in motion cloud and who performed the first activation. This page DOES NOT show the current status of the container.">
						<div
							style={{
								width: '24px',
								height: '24px',
								cursor: 'help',
							}}
						>
							<InfoIcon />
						</div>
					</Tooltip>
				</div>
				<Spin spinning={isLoading || isFetchingContainers} size="large">
					{containers.length === 0 ? (
						<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
					) : (
						<div className={style.itemsContainer}>
							{containers.map((container) => {
								return (
									<div key={container.id}>
										<ManageOneContainer container={container} />
									</div>
								);
							})}
						</div>
					)}
				</Spin>
				{totalContainersCount !== 0 && hasNextContainerPage && (
					<div
						className={style.paginationContainer}
						onClick={() => fetchNextContainerPage()}
					>
						<McButton>View more</McButton>
					</div>
				)}
			</div>
		</>
	);
};

export default ManageContainers;
