import {
	OnlineManualSidebarIcon,
	QaSidebarIcon,
	TutorialsSidebarIcon,
} from 'assets/icons/svg';
import { SupportItemType, SupportItemTypes } from 'types/api';

export type SupportCategory = 'qa' | 'manual' | 'tutorial' | 'admin';

interface CategoryFieldInfo {
	label: string;
	placeholder: string;
}
export interface CategoryInfo {
	key: SupportCategory;
	itemType: SupportItemType;
	label: string;
	items: string;
	icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
	titleField: CategoryFieldInfo;
	descriptionField: CategoryFieldInfo;
}

const qa: CategoryInfo = {
	key: 'qa',
	itemType: SupportItemTypes.QA,
	label: 'Q&A',
	items: 'Q&A item(s)',
	icon: QaSidebarIcon,
	titleField: {
		label: 'Question',
		placeholder: 'How do I...?',
	},
	descriptionField: {
		label: 'Answer',
		placeholder: 'Go to X and click...',
	},
};

const manual: CategoryInfo = {
	key: 'manual',
	itemType: SupportItemTypes.MANUAL,
	label: 'Online Manual',
	icon: OnlineManualSidebarIcon,
	items: 'manual(s)',
	titleField: {
		label: 'Title',
		placeholder: 'Manual for Software X',
	},
	descriptionField: {
		label: 'Description',
		placeholder: 'This manual was released...',
	},
};
const tutorial: CategoryInfo = {
	key: 'tutorial',
	itemType: SupportItemTypes.TUTORIAL,
	label: 'Tutorials',
	items: 'tutorial(s)',
	icon: TutorialsSidebarIcon,
	titleField: {
		label: 'Title',
		placeholder: 'Using X to do Y',
	},
	descriptionField: {
		label: 'Steps',
		placeholder: '1. Read this tutorial',
	},
};
const admin: CategoryInfo = {
	key: 'admin',
	itemType: SupportItemTypes.ADMIN,
	label: 'Admin Notes',
	items: 'admin note(s)',
	icon: OnlineManualSidebarIcon,
	titleField: {
		label: 'Title',
		placeholder: 'Admin Note for X',
	},
	descriptionField: {
		label: 'Description',
		placeholder: 'Details or instructions for admins',
	},
};

export const supportCategoryByKey: Record<SupportCategory, CategoryInfo> = {
	qa,
	manual,
	tutorial,
	admin,
};

export const supportCategoryByType: Record<SupportItemType, CategoryInfo> = {
	QA: qa,
	MANUAL: manual,
	TUTORIAL: tutorial,
	ADMIN: admin,
};

export const supportCategories: CategoryInfo[] = [qa, manual, tutorial, admin];
