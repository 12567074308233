/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Motion Cloud
 * API for Motion Cloud web application
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ErrorDTO } from '../model';
import { PageWebCrmOrganizationDto } from '../model';
import { WebCrmOrganizationDto } from '../model';
/**
 * AdminWebCRMApi - axios parameter creator
 * @export
 */
export const AdminWebCRMApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get webcrm organization by id
         * @param {number} webCrmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebCrmOrganizationById: async (webCrmId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'webCrmId' is not null or undefined
            if (webCrmId === null || webCrmId === undefined) {
                throw new RequiredError('webCrmId','Required parameter webCrmId was null or undefined when calling getWebCrmOrganizationById.');
            }
            const localVarPath = `/api/admin/webcrm/organizations/{webCrmId}`
                .replace(`{${"webCrmId"}}`, encodeURIComponent(String(webCrmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get pageable webcrm organizations
         * @param {number} page 
         * @param {number} size 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebCrmOrganizations: async (page: number, size: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            if (page === null || page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling getWebCrmOrganizations.');
            }
            // verify required parameter 'size' is not null or undefined
            if (size === null || size === undefined) {
                throw new RequiredError('size','Required parameter size was null or undefined when calling getWebCrmOrganizations.');
            }
            const localVarPath = `/api/admin/webcrm/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminWebCRMApi - functional programming interface
 * @export
 */
export const AdminWebCRMApiFp = function(axiosInstance: Promise<AxiosInstance>, configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get webcrm organization by id
         * @param {number} webCrmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWebCrmOrganizationById(webCrmId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<WebCrmOrganizationDto>> {
            const localVarAxiosArgs = await AdminWebCRMApiAxiosParamCreator(configuration).getWebCrmOrganizationById(webCrmId, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get pageable webcrm organizations
         * @param {number} page 
         * @param {number} size 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWebCrmOrganizations(page: number, size: number, search?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<PageWebCrmOrganizationDto>> {
            const localVarAxiosArgs = await AdminWebCRMApiAxiosParamCreator(configuration).getWebCrmOrganizations(page, size, search, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
    }
};

/**
 * AdminWebCRMApi - factory interface
 * @export
 */
export const AdminWebCRMApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get webcrm organization by id
         * @param {number} webCrmId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWebCrmOrganizationById(webCrmId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<WebCrmOrganizationDto>> {
            return AdminWebCRMApiFp(configuration).getWebCrmOrganizationById(webCrmId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get pageable webcrm organizations
         * @param {number} page 
         * @param {number} size 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWebCrmOrganizations(page: number, size: number, search?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<PageWebCrmOrganizationDto>> {
            return AdminWebCRMApiFp(configuration).getWebCrmOrganizations(page, size, search, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminWebCRMApi - object-oriented interface
 * @export
 * @class AdminWebCRMApi
 * @extends {BaseAPI}
 */
export class AdminWebCRMApi extends BaseAPI {
    /**
     * 
     * @summary Get webcrm organization by id
     * @param {number} webCrmId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminWebCRMApi
     */
    public async getWebCrmOrganizationById(webCrmId: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<WebCrmOrganizationDto>> {
        return AdminWebCRMApiFp(this.configuration).getWebCrmOrganizationById(webCrmId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get pageable webcrm organizations
     * @param {number} page 
     * @param {number} size 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminWebCRMApi
     */
    public async getWebCrmOrganizations(page: number, size: number, search?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<PageWebCrmOrganizationDto>> {
        return AdminWebCRMApiFp(this.configuration).getWebCrmOrganizations(page, size, search, options).then((request) => request(this.axios, this.basePath));
    }
}
