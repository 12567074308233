import { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { transformLinkTarget } from 'utils/urls';
import { extractYoutubeId } from 'utils/youtube';
import './styles/markdown.scss';

interface Props {
	content: string | undefined;
}

const YoutubeEmbed: FC<{ url: string }> = ({ url }) => {
	const videoId = extractYoutubeId(url);

	if (!videoId)
		return (
			<a href={url} target={transformLinkTarget(url)}>
				{url}
			</a>
		);

	return (
		<div className="youtube-embed">
			<iframe
				width="100%"
				height="315"
				src={`https://www.youtube.com/embed/${videoId}`}
				title="YouTube video player"
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
			/>
		</div>
	);
};

export const McMarkdown: FC<Props> = (props) => {
	return (
		<div className="mc-markdown">
			<ReactMarkdown
				linkTarget={transformLinkTarget}
				components={{
					a: ({ node, href, children }) => {
						if (
							href &&
							(href.includes('youtube.com') || href.includes('youtu.be'))
						) {
							return <YoutubeEmbed url={href} />;
						}
						return (
							<a href={href} target={transformLinkTarget(href)}>
								{children}
							</a>
						);
					},
				}}
			>
				{props.content ?? ''}
			</ReactMarkdown>
		</div>
	);
};
