import React, { Dispatch, SetStateAction } from 'react';

import { EditUserForm } from './EditUserForm';
import { ItemContainer } from 'components/ItemContainer';
import style from 'assets/styles/manageElements.module.scss';
import {
	IconAvatar,
	IconEdit,
	IconTrash,
	MoreVertical,
} from 'assets/icons/svg';
import { UserDto } from 'api/model';
import { getPublicImageURL } from 'services/ApiService';
import { getRequestError } from 'utils/errors';
import { App, Tooltip } from 'antd';
import {
	deleteItemModal,
	deleteSuccessNotification,
	mcErrorNotification,
} from 'utils/Notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAppSelector } from 'hooks/hooks';
import { authState } from 'store/slices/auth';
import { hasWritePermission, hasDeletePermission } from 'utils/permissions';
import { McIconButton } from 'components/mc';
import { StatusBadge } from 'components/labels/StatusBadge';
import adminUsersService from 'services/admin/AdminUsersService';

interface ManageOneUserProps {
	user: UserDto;
	isCurAdmin: boolean;
	setSelectedToUpdate: Dispatch<SetStateAction<string | null>>;
	selectedToUpdate: string | null;
}

export const ManageOneUser: React.FC<ManageOneUserProps> = ({
	user,
	setSelectedToUpdate,
	selectedToUpdate,
}) => {
	const queryClient = useQueryClient();
	const { modal, notification } = App.useApp();

	const styles =
		selectedToUpdate === user.email
			? {
					backgroundColor: 'var(--bg-active)',
					color: 'var(--button-text)',
					fontSize: '0.875rem',
			  }
			: {
					color: 'var(--text-normal)',
					fontSize: '0.875rem',
			  };

	const clickEditBtnHandler = (
		e: React.MouseEvent<HTMLElement, MouseEvent>
	) => {
		e.preventDefault();
		if (!user.email) return;

		if (selectedToUpdate === null) {
			setSelectedToUpdate(user.email);
		} else {
			if (selectedToUpdate === user.email) {
				setSelectedToUpdate(null);
			} else {
				setSelectedToUpdate(user.email);
			}
		}
	};

	const { permissions } = useAppSelector(authState);

	const canEdit = hasWritePermission(permissions, 'users');
	const canDelete = hasDeletePermission(permissions, 'users');

	const deleteUserHandler = useMutation({
		mutationFn: (email: string) => adminUsersService.deleteUserByEmail(email),
		onSuccess: () => {
			notification.success(
				deleteSuccessNotification(user.firstName + ' ' + user.lastName)
			);
			queryClient.invalidateQueries({
				queryKey: ['users'],
			});
		},
		onError: (error: unknown) => {
			const errorDto = getRequestError(error);
			notification.error(
				mcErrorNotification(
					'Error',
					error,
					'delete',
					`user${
						errorDto.code === 'ENTITY_OWNS_RESOURCES' &&
						errorDto.target === 'subscription'
							? '! The user is still connected to a subscription'
							: ''
					}`
				)
			);
		},
	});

	return (
		<div>
			<ItemContainer styles={styles}>
				<div className={style.profileItem} style={{ width: '25%' }}>
					<Tooltip title={'user id'}>
						<div style={{ color: 'var(--text-disabled)' }}>{user.id}</div>
					</Tooltip>
					<div style={{ transform: 'scale(0.85)' }}>
						{!user.avatarPath ? (
							<IconAvatar />
						) : (
							<img
								className={style.userAvatar}
								alt=""
								src={getPublicImageURL(user.avatarPath)}
							/>
						)}
					</div>
					<div className={style.userNameItem}>
						<span>{user.firstName}</span>
						<span>{user.lastName}</span>
					</div>
				</div>
				<div className={style.textItem} style={{ width: '25%' }}>
					{user.email}
				</div>
				<div className={style.textItem} style={{ width: '20%' }}>
					<div>{user.company}</div>
				</div>
				<div className={style.statusItem} style={{ width: '10%' }}>
					<StatusBadge status={user.status!} />
				</div>

				<div className={style.buttonsItem} style={{ gap: '0.50rem' }}>
					<McIconButton
						icon={<IconEdit />}
						disabled={!canEdit}
						onClick={clickEditBtnHandler}
					/>
					<McIconButton
						icon={<IconTrash />}
						disabled={!canDelete}
						onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
							e.preventDefault();
							modal.confirm(
								deleteItemModal(
									() => deleteUserHandler.mutate(user.email),
									user.firstName + ' ' + user.lastName
								)
							);
						}}
					/>
					<div style={{ cursor: 'auto' }}>
						<MoreVertical />
					</div>
				</div>
			</ItemContainer>
			{selectedToUpdate === user.email && (
				<EditUserForm
					user={user}
					activatedContainers={user.activatedContainers}
					onHide={() => setSelectedToUpdate(null)}
				/>
			)}
		</div>
	);
};
