import { useCallback, useEffect, useState } from 'react';
import { SSE_URI } from 'utils/sse';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAppSelector } from 'hooks/hooks';
import { authState } from 'store/slices/auth';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { useAuth0 } from '@auth0/auth0-react';

const SseHandler = () => {
	const queryClient = useQueryClient();
	const { isOnline, isGuest } = useAppSelector(authState);
	const [eventSource, setEventSource] = useState<boolean>(false);
	const [errorsEncountered, setErrorsEncountered] = useState<number>(0);
	const { isAuthenticated: hasAPIAuth, getAccessTokenSilently } = useAuth0();

	const problemEncounteredFunction = useCallback(
		(ctrl: AbortController, causeMessage: string, retryMessage: string) => {
			console.warn(causeMessage);
			ctrl.abort();
			setTimeout(() => {
				if (errorsEncountered < 5) {
					console.warn(retryMessage);
					setErrorsEncountered((prev) => prev + 1);
					setEventSource(false);
				}
				queryClient.invalidateQueries({ queryKey: ['updates'] });
			}, errorsEncountered * 5000);
		},
		[errorsEncountered, queryClient]
	);

	const { data: ctrl } = useQuery({
		queryKey: ['updates'],
		enabled:
			!isGuest &&
			isOnline &&
			hasAPIAuth &&
			!eventSource &&
			errorsEncountered < 5,
		gcTime: Infinity,
		staleTime: Infinity,
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		refetchOnMount: true,
		queryFn: () => {
			const ctrl = new AbortController();
			setEventSource(true);
			getAccessTokenSilently()
				.then((res) =>
					fetchEventSource(SSE_URI, {
						headers: {
							Authorization: `Bearer ${res}`,
						},
						signal: ctrl.signal,
						openWhenHidden: true,
						async onopen(response) {
							if (response.ok) {
								setErrorsEncountered(0);
							} else {
								problemEncounteredFunction(
									ctrl,
									'Failed to open SSE connection',
									'Retrying to establish SSE connection...'
								);
							}
						},
						onmessage(msg) {
							try {
								let message: string = msg?.data;
								if (message.startsWith('"') && message.length > 1)
									message = message.substring(1);
								if (message.endsWith('"'))
									message = message.substring(0, message.length - 1);

								switch (message) {
									case 'FEATURE_FLAG':
										queryClient.invalidateQueries({
											queryKey: ['feature-flags'],
										});
										break;
									case 'SUBSCRIPTION':
										queryClient.invalidateQueries({
											queryKey: ['subscriptions'],
										});
										break;
									case 'ENGINE':
										queryClient.invalidateQueries({
											queryKey: ['engines', 'home', 'online'],
										});
										break;
									case 'NOTIFICATION':
										queryClient.invalidateQueries({
											queryKey: ['notifications'],
										});
										break;
									case 'DESKTOP_UPDATE':
										queryClient.invalidateQueries({
											queryKey: ['desktop', 'update'],
										});
										break;
									default:
										console.error(
											'Unrecognizable sse format, skipping query invalidation...'
										);
								}
							} catch (e: any) {
								console.error('Failed message event transform');
							}
						},
						onclose() {
							setEventSource(false);
							ctrl.abort();
							queryClient.invalidateQueries({ queryKey: ['updates'] });
						},
						onerror() {
							problemEncounteredFunction(
								ctrl,
								'Error encountered from SSE connection',
								'Retrying to establish SSE connection...'
							);
						},
					})
				)
				.catch((err) => {
					problemEncounteredFunction(
						ctrl,
						'Failed to fetch access token!',
						'Attempting to refetch access token...'
					);
				});
			return ctrl;
		},
	});

	useEffect(() => {
		return () => {
			if (!!ctrl)
				try {
					ctrl.abort();
				} catch (ignore: any) {}
		};
	}, [ctrl]);
	return <></>;
};

export default SseHandler;
