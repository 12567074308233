/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Motion Cloud
 * API for Motion Cloud web application
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { AddContainerDto } from '../model';
import { AddSubscriptionDto } from '../model';
import { AdminSubscriptionDto } from '../model';
import { ContainerDto } from '../model';
import { ContainerType } from '../model';
import { EditSubscriptionDto } from '../model';
import { ErrorDTO } from '../model';
import { LicensingRequestStatus } from '../model';
import { PageDtoAdminLicenseRequestDto } from '../model';
import { PageDtoAdminSubscriptionDto } from '../model';
import { SubscriptionExpiryRequestType } from '../model';
import { SubscriptionStatus } from '../model';
import { SubscriptionType } from '../model';
import { UpdateContainerDto } from '../model';
import { UserDto } from '../model';
/**
 * AdminSubscriptionsApi - axios parameter creator
 * @export
 */
export const AdminSubscriptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Manually sets the status of a pending request to abandoned
         * @param {number} subscriptionId 
         * @param {number} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        abandonRequest: async (subscriptionId: number, requestId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionId' is not null or undefined
            if (subscriptionId === null || subscriptionId === undefined) {
                throw new RequiredError('subscriptionId','Required parameter subscriptionId was null or undefined when calling abandonRequest.');
            }
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling abandonRequest.');
            }
            const localVarPath = `/api/admin/subscriptions/{subscriptionId}/requests/{requestId}/abandon`
                .replace(`{${"subscriptionId"}}`, encodeURIComponent(String(subscriptionId)))
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add Container to Subscription on Manage Subscription tab
         * @param {AddContainerDto} body 
         * @param {number} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addContainer: async (body: AddContainerDto, subscriptionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addContainer.');
            }
            // verify required parameter 'subscriptionId' is not null or undefined
            if (subscriptionId === null || subscriptionId === undefined) {
                throw new RequiredError('subscriptionId','Required parameter subscriptionId was null or undefined when calling addContainer.');
            }
            const localVarPath = `/api/admin/subscriptions/{subscriptionId}/containers`
                .replace(`{${"subscriptionId"}}`, encodeURIComponent(String(subscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add Licenses on Licenses tab
         * @param {Array<number>} modulesIds 
         * @param {number} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLicenses: async (modulesIds: Array<number>, subscriptionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modulesIds' is not null or undefined
            if (modulesIds === null || modulesIds === undefined) {
                throw new RequiredError('modulesIds','Required parameter modulesIds was null or undefined when calling createLicenses.');
            }
            // verify required parameter 'subscriptionId' is not null or undefined
            if (subscriptionId === null || subscriptionId === undefined) {
                throw new RequiredError('subscriptionId','Required parameter subscriptionId was null or undefined when calling createLicenses.');
            }
            const localVarPath = `/api/admin/subscriptions/{subscriptionId}/licenses`
                .replace(`{${"subscriptionId"}}`, encodeURIComponent(String(subscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (modulesIds) {
                localVarQueryParameter['modulesIds'] = modulesIds;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add Subscription on the Manage Subscription tab
         * @param {AddSubscriptionDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscription: async (body: AddSubscriptionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createSubscription.');
            }
            const localVarPath = `/api/admin/subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Container on Manage Subscription tab
         * @param {number} containerId 
         * @param {number} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContainerFromSubscription: async (containerId: number, subscriptionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'containerId' is not null or undefined
            if (containerId === null || containerId === undefined) {
                throw new RequiredError('containerId','Required parameter containerId was null or undefined when calling deleteContainerFromSubscription.');
            }
            // verify required parameter 'subscriptionId' is not null or undefined
            if (subscriptionId === null || subscriptionId === undefined) {
                throw new RequiredError('subscriptionId','Required parameter subscriptionId was null or undefined when calling deleteContainerFromSubscription.');
            }
            const localVarPath = `/api/admin/subscriptions/{subscriptionId}/containers/{containerId}`
                .replace(`{${"containerId"}}`, encodeURIComponent(String(containerId)))
                .replace(`{${"subscriptionId"}}`, encodeURIComponent(String(subscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Subscription on Manage Subscription tab
         * @param {number} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubscriptionById: async (subscriptionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionId' is not null or undefined
            if (subscriptionId === null || subscriptionId === undefined) {
                throw new RequiredError('subscriptionId','Required parameter subscriptionId was null or undefined when calling deleteSubscriptionById.');
            }
            const localVarPath = `/api/admin/subscriptions/{subscriptionId}`
                .replace(`{${"subscriptionId"}}`, encodeURIComponent(String(subscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the full Subscription by id
         * @param {number} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSubscriptionById: async (subscriptionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionId' is not null or undefined
            if (subscriptionId === null || subscriptionId === undefined) {
                throw new RequiredError('subscriptionId','Required parameter subscriptionId was null or undefined when calling getAdminSubscriptionById.');
            }
            const localVarPath = `/api/admin/subscriptions/{subscriptionId}/admin`
                .replace(`{${"subscriptionId"}}`, encodeURIComponent(String(subscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get licensing requests by subscription id for the admin view
         * @param {number} subscriptionId 
         * @param {number} page 
         * @param {number} size 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAdminLicensingRequest: async (subscriptionId: number, page: number, size: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionId' is not null or undefined
            if (subscriptionId === null || subscriptionId === undefined) {
                throw new RequiredError('subscriptionId','Required parameter subscriptionId was null or undefined when calling getAllAdminLicensingRequest.');
            }
            // verify required parameter 'page' is not null or undefined
            if (page === null || page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling getAllAdminLicensingRequest.');
            }
            // verify required parameter 'size' is not null or undefined
            if (size === null || size === undefined) {
                throw new RequiredError('size','Required parameter size was null or undefined when calling getAllAdminLicensingRequest.');
            }
            const localVarPath = `/api/admin/subscriptions/{subscriptionId}/requests`
                .replace(`{${"subscriptionId"}}`, encodeURIComponent(String(subscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get List of Containers with the filtering possibility by type
         * @param {number} subscriptionId 
         * @param {ContainerType} [containerType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllContainers: async (subscriptionId: number, containerType?: ContainerType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionId' is not null or undefined
            if (subscriptionId === null || subscriptionId === undefined) {
                throw new RequiredError('subscriptionId','Required parameter subscriptionId was null or undefined when calling getAllContainers.');
            }
            const localVarPath = `/api/admin/subscriptions/{subscriptionId}/containers`
                .replace(`{${"subscriptionId"}}`, encodeURIComponent(String(subscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (containerType !== undefined) {
                localVarQueryParameter['containerType'] = containerType;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a full list of Subscriptions with pagination, filtering by status, searched value and user access
         * @param {number} page 
         * @param {number} size 
         * @param {string} [searchedValue] 
         * @param {SubscriptionStatus} [entityStatus] 
         * @param {LicensingRequestStatus} [requestStatus] 
         * @param {SubscriptionExpiryRequestType} [subscriptionExpiry] 
         * @param {SubscriptionType} [subscriptionType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedAdminSubscriptions: async (page: number, size: number, searchedValue?: string, entityStatus?: SubscriptionStatus, requestStatus?: LicensingRequestStatus, subscriptionExpiry?: SubscriptionExpiryRequestType, subscriptionType?: SubscriptionType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            if (page === null || page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling getPaginatedAdminSubscriptions.');
            }
            // verify required parameter 'size' is not null or undefined
            if (size === null || size === undefined) {
                throw new RequiredError('size','Required parameter size was null or undefined when calling getPaginatedAdminSubscriptions.');
            }
            const localVarPath = `/api/admin/subscriptions/admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (searchedValue !== undefined) {
                localVarQueryParameter['searchedValue'] = searchedValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (entityStatus !== undefined) {
                localVarQueryParameter['entityStatus'] = entityStatus;
            }

            if (requestStatus !== undefined) {
                localVarQueryParameter['requestStatus'] = requestStatus;
            }

            if (subscriptionExpiry !== undefined) {
                localVarQueryParameter['subscriptionExpiry'] = subscriptionExpiry;
            }

            if (subscriptionType !== undefined) {
                localVarQueryParameter['subscriptionType'] = subscriptionType;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get subscription information
         * @param {number} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionData: async (subscriptionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionId' is not null or undefined
            if (subscriptionId === null || subscriptionId === undefined) {
                throw new RequiredError('subscriptionId','Required parameter subscriptionId was null or undefined when calling getSubscriptionData.');
            }
            const localVarPath = `/api/admin/subscriptions/{subscriptionId}/info`
                .replace(`{${"subscriptionId"}}`, encodeURIComponent(String(subscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User
         * @param {number} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionOwner: async (subscriptionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionId' is not null or undefined
            if (subscriptionId === null || subscriptionId === undefined) {
                throw new RequiredError('subscriptionId','Required parameter subscriptionId was null or undefined when calling getSubscriptionOwner.');
            }
            const localVarPath = `/api/admin/subscriptions/{subscriptionId}/user`
                .replace(`{${"subscriptionId"}}`, encodeURIComponent(String(subscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a boolean based on whether the provided subscription has any pending license requests
         * @param {number} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasOpenRequests: async (subscriptionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionId' is not null or undefined
            if (subscriptionId === null || subscriptionId === undefined) {
                throw new RequiredError('subscriptionId','Required parameter subscriptionId was null or undefined when calling hasOpenRequests.');
            }
            const localVarPath = `/api/admin/subscriptions/{subscriptionId}/requests/open`
                .replace(`{${"subscriptionId"}}`, encodeURIComponent(String(subscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates container for specified subscription
         * @param {UpdateContainerDto} body 
         * @param {number} subscriptionId 
         * @param {number} containerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContainer: async (body: UpdateContainerDto, subscriptionId: number, containerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateContainer.');
            }
            // verify required parameter 'subscriptionId' is not null or undefined
            if (subscriptionId === null || subscriptionId === undefined) {
                throw new RequiredError('subscriptionId','Required parameter subscriptionId was null or undefined when calling updateContainer.');
            }
            // verify required parameter 'containerId' is not null or undefined
            if (containerId === null || containerId === undefined) {
                throw new RequiredError('containerId','Required parameter containerId was null or undefined when calling updateContainer.');
            }
            const localVarPath = `/api/admin/subscriptions/{subscriptionId}/containers/{containerId}`
                .replace(`{${"subscriptionId"}}`, encodeURIComponent(String(subscriptionId)))
                .replace(`{${"containerId"}}`, encodeURIComponent(String(containerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Disables the specified license
         * @param {number} licenseId 
         * @param {boolean} enable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEnableLicense: async (licenseId: number, enable: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'licenseId' is not null or undefined
            if (licenseId === null || licenseId === undefined) {
                throw new RequiredError('licenseId','Required parameter licenseId was null or undefined when calling updateEnableLicense.');
            }
            // verify required parameter 'enable' is not null or undefined
            if (enable === null || enable === undefined) {
                throw new RequiredError('enable','Required parameter enable was null or undefined when calling updateEnableLicense.');
            }
            const localVarPath = `/api/admin/subscriptions/{subscriptionId}/licenses/{licenseId}/enable`
                .replace(`{${"licenseId"}}`, encodeURIComponent(String(licenseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (enable !== undefined) {
                localVarQueryParameter['enable'] = enable;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Subscription on the Manage Subscription tab
         * @param {EditSubscriptionDto} body 
         * @param {number} version 
         * @param {number} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscription: async (body: EditSubscriptionDto, version: number, subscriptionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateSubscription.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling updateSubscription.');
            }
            // verify required parameter 'subscriptionId' is not null or undefined
            if (subscriptionId === null || subscriptionId === undefined) {
                throw new RequiredError('subscriptionId','Required parameter subscriptionId was null or undefined when calling updateSubscription.');
            }
            const localVarPath = `/api/admin/subscriptions/{subscriptionId}`
                .replace(`{${"subscriptionId"}}`, encodeURIComponent(String(subscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the ticket identifier for licensing request
         * @param {number} subscriptionId 
         * @param {number} requestId 
         * @param {string} ticketIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTicketIdentifier: async (subscriptionId: number, requestId: number, ticketIdentifier: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionId' is not null or undefined
            if (subscriptionId === null || subscriptionId === undefined) {
                throw new RequiredError('subscriptionId','Required parameter subscriptionId was null or undefined when calling updateTicketIdentifier.');
            }
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling updateTicketIdentifier.');
            }
            // verify required parameter 'ticketIdentifier' is not null or undefined
            if (ticketIdentifier === null || ticketIdentifier === undefined) {
                throw new RequiredError('ticketIdentifier','Required parameter ticketIdentifier was null or undefined when calling updateTicketIdentifier.');
            }
            const localVarPath = `/api/admin/subscriptions/{subscriptionId}/requests/{requestId}/ticket`
                .replace(`{${"subscriptionId"}}`, encodeURIComponent(String(subscriptionId)))
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (ticketIdentifier !== undefined) {
                localVarQueryParameter['ticketIdentifier'] = ticketIdentifier;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Manual method for uploading the update file for a license request
         * @param {number} subscriptionId 
         * @param {number} requestId 
         * @param {string} fileType 
         * @param {Blob} [requestFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadRequestFileForm: async (subscriptionId: number, requestId: number, fileType: string, requestFile?: Blob, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionId' is not null or undefined
            if (subscriptionId === null || subscriptionId === undefined) {
                throw new RequiredError('subscriptionId','Required parameter subscriptionId was null or undefined when calling uploadRequestFileForm.');
            }
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling uploadRequestFileForm.');
            }
            // verify required parameter 'fileType' is not null or undefined
            if (fileType === null || fileType === undefined) {
                throw new RequiredError('fileType','Required parameter fileType was null or undefined when calling uploadRequestFileForm.');
            }
            const localVarPath = `/api/admin/subscriptions/{subscriptionId}/requests/{requestId}/{fileType}`
                .replace(`{${"subscriptionId"}}`, encodeURIComponent(String(subscriptionId)))
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)))
                .replace(`{${"fileType"}}`, encodeURIComponent(String(fileType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


            if (requestFile !== undefined) {
                const requestFileValue = typeof requestFile === 'object' && !(requestFile instanceof Blob) ? JSON.stringify(requestFile) : requestFile;
                localVarFormParams.append('requestFile', requestFileValue as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminSubscriptionsApi - functional programming interface
 * @export
 */
export const AdminSubscriptionsApiFp = function(axiosInstance: Promise<AxiosInstance>, configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Manually sets the status of a pending request to abandoned
         * @param {number} subscriptionId 
         * @param {number} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async abandonRequest(subscriptionId: number, requestId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await AdminSubscriptionsApiAxiosParamCreator(configuration).abandonRequest(subscriptionId, requestId, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Add Container to Subscription on Manage Subscription tab
         * @param {AddContainerDto} body 
         * @param {number} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addContainer(body: AddContainerDto, subscriptionId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> {
            const localVarAxiosArgs = await AdminSubscriptionsApiAxiosParamCreator(configuration).addContainer(body, subscriptionId, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Add Licenses on Licenses tab
         * @param {Array<number>} modulesIds 
         * @param {number} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLicenses(modulesIds: Array<number>, subscriptionId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> {
            const localVarAxiosArgs = await AdminSubscriptionsApiAxiosParamCreator(configuration).createLicenses(modulesIds, subscriptionId, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Add Subscription on the Manage Subscription tab
         * @param {AddSubscriptionDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSubscription(body: AddSubscriptionDto, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> {
            const localVarAxiosArgs = await AdminSubscriptionsApiAxiosParamCreator(configuration).createSubscription(body, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Delete Container on Manage Subscription tab
         * @param {number} containerId 
         * @param {number} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContainerFromSubscription(containerId: number, subscriptionId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await AdminSubscriptionsApiAxiosParamCreator(configuration).deleteContainerFromSubscription(containerId, subscriptionId, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Delete Subscription on Manage Subscription tab
         * @param {number} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSubscriptionById(subscriptionId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await AdminSubscriptionsApiAxiosParamCreator(configuration).deleteSubscriptionById(subscriptionId, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get the full Subscription by id
         * @param {number} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminSubscriptionById(subscriptionId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<AdminSubscriptionDto>> {
            const localVarAxiosArgs = await AdminSubscriptionsApiAxiosParamCreator(configuration).getAdminSubscriptionById(subscriptionId, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get licensing requests by subscription id for the admin view
         * @param {number} subscriptionId 
         * @param {number} page 
         * @param {number} size 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAdminLicensingRequest(subscriptionId: number, page: number, size: number, options?: AxiosRequestConfig): Promise<AxiosResponse<PageDtoAdminLicenseRequestDto>> {
            const localVarAxiosArgs = await AdminSubscriptionsApiAxiosParamCreator(configuration).getAllAdminLicensingRequest(subscriptionId, page, size, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get List of Containers with the filtering possibility by type
         * @param {number} subscriptionId 
         * @param {ContainerType} [containerType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllContainers(subscriptionId: number, containerType?: ContainerType, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<ContainerDto>>> {
            const localVarAxiosArgs = await AdminSubscriptionsApiAxiosParamCreator(configuration).getAllContainers(subscriptionId, containerType, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get a full list of Subscriptions with pagination, filtering by status, searched value and user access
         * @param {number} page 
         * @param {number} size 
         * @param {string} [searchedValue] 
         * @param {SubscriptionStatus} [entityStatus] 
         * @param {LicensingRequestStatus} [requestStatus] 
         * @param {SubscriptionExpiryRequestType} [subscriptionExpiry] 
         * @param {SubscriptionType} [subscriptionType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginatedAdminSubscriptions(page: number, size: number, searchedValue?: string, entityStatus?: SubscriptionStatus, requestStatus?: LicensingRequestStatus, subscriptionExpiry?: SubscriptionExpiryRequestType, subscriptionType?: SubscriptionType, options?: AxiosRequestConfig): Promise<AxiosResponse<PageDtoAdminSubscriptionDto>> {
            const localVarAxiosArgs = await AdminSubscriptionsApiAxiosParamCreator(configuration).getPaginatedAdminSubscriptions(page, size, searchedValue, entityStatus, requestStatus, subscriptionExpiry, subscriptionType, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get subscription information
         * @param {number} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriptionData(subscriptionId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> {
            const localVarAxiosArgs = await AdminSubscriptionsApiAxiosParamCreator(configuration).getSubscriptionData(subscriptionId, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary User
         * @param {number} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriptionOwner(subscriptionId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<UserDto>> {
            const localVarAxiosArgs = await AdminSubscriptionsApiAxiosParamCreator(configuration).getSubscriptionOwner(subscriptionId, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Returns a boolean based on whether the provided subscription has any pending license requests
         * @param {number} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hasOpenRequests(subscriptionId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<boolean>> {
            const localVarAxiosArgs = await AdminSubscriptionsApiAxiosParamCreator(configuration).hasOpenRequests(subscriptionId, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Updates container for specified subscription
         * @param {UpdateContainerDto} body 
         * @param {number} subscriptionId 
         * @param {number} containerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContainer(body: UpdateContainerDto, subscriptionId: number, containerId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await AdminSubscriptionsApiAxiosParamCreator(configuration).updateContainer(body, subscriptionId, containerId, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Disables the specified license
         * @param {number} licenseId 
         * @param {boolean} enable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEnableLicense(licenseId: number, enable: boolean, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await AdminSubscriptionsApiAxiosParamCreator(configuration).updateEnableLicense(licenseId, enable, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Update Subscription on the Manage Subscription tab
         * @param {EditSubscriptionDto} body 
         * @param {number} version 
         * @param {number} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSubscription(body: EditSubscriptionDto, version: number, subscriptionId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> {
            const localVarAxiosArgs = await AdminSubscriptionsApiAxiosParamCreator(configuration).updateSubscription(body, version, subscriptionId, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Update the ticket identifier for licensing request
         * @param {number} subscriptionId 
         * @param {number} requestId 
         * @param {string} ticketIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTicketIdentifier(subscriptionId: number, requestId: number, ticketIdentifier: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await AdminSubscriptionsApiAxiosParamCreator(configuration).updateTicketIdentifier(subscriptionId, requestId, ticketIdentifier, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Manual method for uploading the update file for a license request
         * @param {number} subscriptionId 
         * @param {number} requestId 
         * @param {string} fileType 
         * @param {Blob} [requestFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadRequestFileForm(subscriptionId: number, requestId: number, fileType: string, requestFile?: Blob, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await AdminSubscriptionsApiAxiosParamCreator(configuration).uploadRequestFileForm(subscriptionId, requestId, fileType, requestFile, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
    }
};

/**
 * AdminSubscriptionsApi - factory interface
 * @export
 */
export const AdminSubscriptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Manually sets the status of a pending request to abandoned
         * @param {number} subscriptionId 
         * @param {number} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async abandonRequest(subscriptionId: number, requestId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AdminSubscriptionsApiFp(configuration).abandonRequest(subscriptionId, requestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add Container to Subscription on Manage Subscription tab
         * @param {AddContainerDto} body 
         * @param {number} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addContainer(body: AddContainerDto, subscriptionId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> {
            return AdminSubscriptionsApiFp(configuration).addContainer(body, subscriptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add Licenses on Licenses tab
         * @param {Array<number>} modulesIds 
         * @param {number} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLicenses(modulesIds: Array<number>, subscriptionId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> {
            return AdminSubscriptionsApiFp(configuration).createLicenses(modulesIds, subscriptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add Subscription on the Manage Subscription tab
         * @param {AddSubscriptionDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSubscription(body: AddSubscriptionDto, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> {
            return AdminSubscriptionsApiFp(configuration).createSubscription(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Container on Manage Subscription tab
         * @param {number} containerId 
         * @param {number} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContainerFromSubscription(containerId: number, subscriptionId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AdminSubscriptionsApiFp(configuration).deleteContainerFromSubscription(containerId, subscriptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Subscription on Manage Subscription tab
         * @param {number} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSubscriptionById(subscriptionId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AdminSubscriptionsApiFp(configuration).deleteSubscriptionById(subscriptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the full Subscription by id
         * @param {number} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminSubscriptionById(subscriptionId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<AdminSubscriptionDto>> {
            return AdminSubscriptionsApiFp(configuration).getAdminSubscriptionById(subscriptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get licensing requests by subscription id for the admin view
         * @param {number} subscriptionId 
         * @param {number} page 
         * @param {number} size 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAdminLicensingRequest(subscriptionId: number, page: number, size: number, options?: AxiosRequestConfig): Promise<AxiosResponse<PageDtoAdminLicenseRequestDto>> {
            return AdminSubscriptionsApiFp(configuration).getAllAdminLicensingRequest(subscriptionId, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get List of Containers with the filtering possibility by type
         * @param {number} subscriptionId 
         * @param {ContainerType} [containerType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllContainers(subscriptionId: number, containerType?: ContainerType, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<ContainerDto>>> {
            return AdminSubscriptionsApiFp(configuration).getAllContainers(subscriptionId, containerType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a full list of Subscriptions with pagination, filtering by status, searched value and user access
         * @param {number} page 
         * @param {number} size 
         * @param {string} [searchedValue] 
         * @param {SubscriptionStatus} [entityStatus] 
         * @param {LicensingRequestStatus} [requestStatus] 
         * @param {SubscriptionExpiryRequestType} [subscriptionExpiry] 
         * @param {SubscriptionType} [subscriptionType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginatedAdminSubscriptions(page: number, size: number, searchedValue?: string, entityStatus?: SubscriptionStatus, requestStatus?: LicensingRequestStatus, subscriptionExpiry?: SubscriptionExpiryRequestType, subscriptionType?: SubscriptionType, options?: AxiosRequestConfig): Promise<AxiosResponse<PageDtoAdminSubscriptionDto>> {
            return AdminSubscriptionsApiFp(configuration).getPaginatedAdminSubscriptions(page, size, searchedValue, entityStatus, requestStatus, subscriptionExpiry, subscriptionType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get subscription information
         * @param {number} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriptionData(subscriptionId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> {
            return AdminSubscriptionsApiFp(configuration).getSubscriptionData(subscriptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User
         * @param {number} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriptionOwner(subscriptionId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<UserDto>> {
            return AdminSubscriptionsApiFp(configuration).getSubscriptionOwner(subscriptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a boolean based on whether the provided subscription has any pending license requests
         * @param {number} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hasOpenRequests(subscriptionId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<boolean>> {
            return AdminSubscriptionsApiFp(configuration).hasOpenRequests(subscriptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates container for specified subscription
         * @param {UpdateContainerDto} body 
         * @param {number} subscriptionId 
         * @param {number} containerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContainer(body: UpdateContainerDto, subscriptionId: number, containerId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AdminSubscriptionsApiFp(configuration).updateContainer(body, subscriptionId, containerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Disables the specified license
         * @param {number} licenseId 
         * @param {boolean} enable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEnableLicense(licenseId: number, enable: boolean, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AdminSubscriptionsApiFp(configuration).updateEnableLicense(licenseId, enable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Subscription on the Manage Subscription tab
         * @param {EditSubscriptionDto} body 
         * @param {number} version 
         * @param {number} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSubscription(body: EditSubscriptionDto, version: number, subscriptionId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> {
            return AdminSubscriptionsApiFp(configuration).updateSubscription(body, version, subscriptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the ticket identifier for licensing request
         * @param {number} subscriptionId 
         * @param {number} requestId 
         * @param {string} ticketIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTicketIdentifier(subscriptionId: number, requestId: number, ticketIdentifier: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AdminSubscriptionsApiFp(configuration).updateTicketIdentifier(subscriptionId, requestId, ticketIdentifier, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Manual method for uploading the update file for a license request
         * @param {number} subscriptionId 
         * @param {number} requestId 
         * @param {string} fileType 
         * @param {Blob} [requestFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadRequestFileForm(subscriptionId: number, requestId: number, fileType: string, requestFile?: Blob, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AdminSubscriptionsApiFp(configuration).uploadRequestFileForm(subscriptionId, requestId, fileType, requestFile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminSubscriptionsApi - object-oriented interface
 * @export
 * @class AdminSubscriptionsApi
 * @extends {BaseAPI}
 */
export class AdminSubscriptionsApi extends BaseAPI {
    /**
     * 
     * @summary Manually sets the status of a pending request to abandoned
     * @param {number} subscriptionId 
     * @param {number} requestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSubscriptionsApi
     */
    public async abandonRequest(subscriptionId: number, requestId: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AdminSubscriptionsApiFp(this.configuration).abandonRequest(subscriptionId, requestId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Add Container to Subscription on Manage Subscription tab
     * @param {AddContainerDto} body 
     * @param {number} subscriptionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSubscriptionsApi
     */
    public async addContainer(body: AddContainerDto, subscriptionId: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<string>> {
        return AdminSubscriptionsApiFp(this.configuration).addContainer(body, subscriptionId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Add Licenses on Licenses tab
     * @param {Array<number>} modulesIds 
     * @param {number} subscriptionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSubscriptionsApi
     */
    public async createLicenses(modulesIds: Array<number>, subscriptionId: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<string>> {
        return AdminSubscriptionsApiFp(this.configuration).createLicenses(modulesIds, subscriptionId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Add Subscription on the Manage Subscription tab
     * @param {AddSubscriptionDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSubscriptionsApi
     */
    public async createSubscription(body: AddSubscriptionDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<string>> {
        return AdminSubscriptionsApiFp(this.configuration).createSubscription(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete Container on Manage Subscription tab
     * @param {number} containerId 
     * @param {number} subscriptionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSubscriptionsApi
     */
    public async deleteContainerFromSubscription(containerId: number, subscriptionId: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AdminSubscriptionsApiFp(this.configuration).deleteContainerFromSubscription(containerId, subscriptionId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete Subscription on Manage Subscription tab
     * @param {number} subscriptionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSubscriptionsApi
     */
    public async deleteSubscriptionById(subscriptionId: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AdminSubscriptionsApiFp(this.configuration).deleteSubscriptionById(subscriptionId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get the full Subscription by id
     * @param {number} subscriptionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSubscriptionsApi
     */
    public async getAdminSubscriptionById(subscriptionId: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<AdminSubscriptionDto>> {
        return AdminSubscriptionsApiFp(this.configuration).getAdminSubscriptionById(subscriptionId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get licensing requests by subscription id for the admin view
     * @param {number} subscriptionId 
     * @param {number} page 
     * @param {number} size 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSubscriptionsApi
     */
    public async getAllAdminLicensingRequest(subscriptionId: number, page: number, size: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<PageDtoAdminLicenseRequestDto>> {
        return AdminSubscriptionsApiFp(this.configuration).getAllAdminLicensingRequest(subscriptionId, page, size, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get List of Containers with the filtering possibility by type
     * @param {number} subscriptionId 
     * @param {ContainerType} [containerType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSubscriptionsApi
     */
    public async getAllContainers(subscriptionId: number, containerType?: ContainerType, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<ContainerDto>>> {
        return AdminSubscriptionsApiFp(this.configuration).getAllContainers(subscriptionId, containerType, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get a full list of Subscriptions with pagination, filtering by status, searched value and user access
     * @param {number} page 
     * @param {number} size 
     * @param {string} [searchedValue] 
     * @param {SubscriptionStatus} [entityStatus] 
     * @param {LicensingRequestStatus} [requestStatus] 
     * @param {SubscriptionExpiryRequestType} [subscriptionExpiry] 
     * @param {SubscriptionType} [subscriptionType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSubscriptionsApi
     */
    public async getPaginatedAdminSubscriptions(page: number, size: number, searchedValue?: string, entityStatus?: SubscriptionStatus, requestStatus?: LicensingRequestStatus, subscriptionExpiry?: SubscriptionExpiryRequestType, subscriptionType?: SubscriptionType, options?: AxiosRequestConfig) : Promise<AxiosResponse<PageDtoAdminSubscriptionDto>> {
        return AdminSubscriptionsApiFp(this.configuration).getPaginatedAdminSubscriptions(page, size, searchedValue, entityStatus, requestStatus, subscriptionExpiry, subscriptionType, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get subscription information
     * @param {number} subscriptionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSubscriptionsApi
     */
    public async getSubscriptionData(subscriptionId: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<string>> {
        return AdminSubscriptionsApiFp(this.configuration).getSubscriptionData(subscriptionId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary User
     * @param {number} subscriptionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSubscriptionsApi
     */
    public async getSubscriptionOwner(subscriptionId: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<UserDto>> {
        return AdminSubscriptionsApiFp(this.configuration).getSubscriptionOwner(subscriptionId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Returns a boolean based on whether the provided subscription has any pending license requests
     * @param {number} subscriptionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSubscriptionsApi
     */
    public async hasOpenRequests(subscriptionId: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<boolean>> {
        return AdminSubscriptionsApiFp(this.configuration).hasOpenRequests(subscriptionId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Updates container for specified subscription
     * @param {UpdateContainerDto} body 
     * @param {number} subscriptionId 
     * @param {number} containerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSubscriptionsApi
     */
    public async updateContainer(body: UpdateContainerDto, subscriptionId: number, containerId: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AdminSubscriptionsApiFp(this.configuration).updateContainer(body, subscriptionId, containerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Disables the specified license
     * @param {number} licenseId 
     * @param {boolean} enable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSubscriptionsApi
     */
    public async updateEnableLicense(licenseId: number, enable: boolean, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AdminSubscriptionsApiFp(this.configuration).updateEnableLicense(licenseId, enable, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update Subscription on the Manage Subscription tab
     * @param {EditSubscriptionDto} body 
     * @param {number} version 
     * @param {number} subscriptionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSubscriptionsApi
     */
    public async updateSubscription(body: EditSubscriptionDto, version: number, subscriptionId: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<string>> {
        return AdminSubscriptionsApiFp(this.configuration).updateSubscription(body, version, subscriptionId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update the ticket identifier for licensing request
     * @param {number} subscriptionId 
     * @param {number} requestId 
     * @param {string} ticketIdentifier 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSubscriptionsApi
     */
    public async updateTicketIdentifier(subscriptionId: number, requestId: number, ticketIdentifier: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AdminSubscriptionsApiFp(this.configuration).updateTicketIdentifier(subscriptionId, requestId, ticketIdentifier, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Manual method for uploading the update file for a license request
     * @param {number} subscriptionId 
     * @param {number} requestId 
     * @param {string} fileType 
     * @param {Blob} [requestFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSubscriptionsApi
     */
    public async uploadRequestFileForm(subscriptionId: number, requestId: number, fileType: string, requestFile?: Blob, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AdminSubscriptionsApiFp(this.configuration).uploadRequestFileForm(subscriptionId, requestId, fileType, requestFile, options).then((request) => request(this.axios, this.basePath));
    }
}
