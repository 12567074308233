import { useEffect } from 'react';
import { checkUpdate, installUpdate } from '@tauri-apps/api/updater';
import { App, Divider, Modal } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { isDesktop } from 'desktop';
import { errorNotification, infoNotification } from 'utils/Notifications';
import { McLogoIcon } from 'assets/icons/svg';
import { McButton } from 'components/mc';
import McSpinner from 'components/MotionCloudSpinner';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { setUpdaterOpen, updaterState } from 'store/slices/updater';
import { relaunch } from '@tauri-apps/api/process';
import { useLocalSettings } from 'settings';
import style from 'assets/styles/updater.module.scss';

const DesktopUpdateManager = () => {
	const { notification } = App.useApp();
	const dispatch = useAppDispatch();
	const { open: isDialogOpen } = useAppSelector(updaterState);
	const [desktopUpdateDismiss, setDesktopUpdateDismiss] = useLocalSettings(
		'desktopUpdateDismiss'
	);

	const { data: update, error: checkUpdateError } = useQuery({
		queryKey: ['desktop', 'update'],
		queryFn: () => checkUpdate().then((res) => res),
		enabled: isDesktop,
		initialData: undefined,
	});

	const { mutate: installUpdateHandler, isPending: isInstallingUpdate } =
		useMutation({
			mutationFn: () => installUpdate().then(relaunch),
			onMutate: () => setDesktopUpdateDismiss(false),
			onSuccess: () => {
				notification.success(
					infoNotification('Successfully downloaded the update!')
				);
			},
			onError: () =>
				notification.error(
					errorNotification('Encountered an error while installing the update!')
				),
		});

	useEffect(() => {
		if (!checkUpdateError) return;
		console.log('No desktop updates found!');
	}, [checkUpdateError]);

	useEffect(() => {
		if (!!update && !update.shouldUpdate) {
			dispatch(setUpdaterOpen(false));
		}
	}, [dispatch, update]);

	const isVisible = update?.shouldUpdate && isDialogOpen;

	return (
		<Modal
			centered
			maskClosable={false}
			open={isVisible}
			footer={null}
			closable={false}
			style={{ zIndex: '100000000' }}
			title={
				<div className={style.title}>
					<McLogoIcon />
					<h2>New Update!</h2>
				</div>
			}
		>
			<Divider />
			{isInstallingUpdate ? (
				<div className={style.spinner}>
					<McSpinner />
					<p>Installing update...</p>
				</div>
			) : (
				<>
					<p>
						Version {update?.manifest?.version} of the Motion Cloud desktop
						application is now available to download!
					</p>
					<div className={style.buttons}>
						{!desktopUpdateDismiss && (
							<McButton
								onClick={() => {
									if (!desktopUpdateDismiss) {
										dispatch(setUpdaterOpen(false));
										setDesktopUpdateDismiss(true);
									}
								}}
							>
								Later
							</McButton>
						)}
						<McButton
							primary
							onClick={() => {
								installUpdateHandler();
							}}
						>
							Update Now
						</McButton>
					</div>
				</>
			)}
		</Modal>
	);
};

export default DesktopUpdateManager;
