import { FC, useEffect, useState, useRef } from 'react';
import { useAppDispatch } from 'hooks/hooks';
import { useNavigate } from 'react-router-dom';
import { DownloadIcon } from 'assets/icons/svg';
import {
	getOneTemplate,
	resetState,
	getTemplateFileName,
} from 'store/slices/oneTemplate';
import style from 'assets/styles/templateCard.module.scss';
import { getDownloadTemplateUrl, getPublicImageURL } from 'services/ApiService';
import { McButton } from 'components/mc';
import { AppIcon } from 'assets/icons/apps';
import { ipc, isDesktop, useDesktopContext } from 'desktop';
import appReleasesService from 'services/AppReleasesService';
import applicationsService from 'services/ApplicationsService';
import { App } from 'antd';
import {
	fileDownloadedNotification,
	mcErrorNotification,
} from 'utils/Notifications';
import defaultImage from 'assets/images/top.jpg';
import adminTemplatesService from 'services/admin/AdminTemplatesService';

interface CardProps {
	template: Template;
}

export const TemplateCard: FC<CardProps> = ({ template }) => {
	let navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [templateFileUrl, setTemplateFileUrl] = useState<string | undefined>(
		undefined
	);
	const downloadRef = useRef<HTMLAnchorElement>(null);
	const [applicationIcon, setApplicationIcon] = useState<string>();
	const { reloadUserTemplates } = useDesktopContext();
	const { notification } = App.useApp();

	const handleClickToOpenTemplate = (id: number) => {
		dispatch(resetState());
		dispatch(getOneTemplate(id));
		navigate('/templates/' + id);
	};

	useEffect(() => {
		if (templateFileUrl) {
			downloadRef?.current?.click();
			setTemplateFileUrl(undefined);
		}
	}, [templateFileUrl]);

	const downloadTemplateHandler = () => {
		if (template.id) {
			dispatch(getTemplateFileName(template.id))
				.unwrap()
				.then((data) => {
					setTemplateFileUrl(getDownloadTemplateUrl(template.id));
					notification.success(fileDownloadedNotification());
				})
				.catch((err) => {
					notification.error(
						mcErrorNotification('Error', undefined, 'download', 'template')
					);
				});
		}
	};

	const downloadFromDesktop = async () => {
		if (isDesktop && template.id) {
			const promise = adminTemplatesService.getTemplatesFile(template.id, {
				responseType: 'arraybuffer',
			});

			await ipc.downloadFileToDesktop(
				promise,
				'Template',
				['tet'],
				'template.tet'
			);
			reloadUserTemplates();
		}
	};

	// Fetch application icon
	useEffect(() => {
		if (template.applicationReleaseId)
			appReleasesService
				.getApplicationReleaseById(template.applicationReleaseId)
				.then((res) =>
					applicationsService.getApplication(res.data.applicationId!)
				)
				.then((res) => setApplicationIcon(res.data.iconImagePath ?? undefined))
				.catch((err) => {
					console.error(
						'Failed to fetch corresponding application icon for the provided template!'
					);
					setApplicationIcon(undefined);
				});
	}, [template]);

	return (
		<div className={style.container}>
			<div
				className={style.imgContainer}
				onClick={() => {
					handleClickToOpenTemplate(template.id);
				}}
			>
				<img
					alt={template.title || 'Template thumbnail'}
					src={
						template.thumbnailImagePath
							? getPublicImageURL(template.thumbnailImagePath)
							: defaultImage
					}
					onError={(e) => {
						e.currentTarget.src = defaultImage;
					}}
				/>
			</div>
			<div className={style.descriptionContainer}>
				<div className={style.iconContainer}>
					{applicationIcon ? (
						<img
							alt=""
							src={getPublicImageURL(applicationIcon)}
							width={24}
							height={24}
						/>
					) : (
						<AppIcon app="" size={24} />
					)}
					<h4
						className={style.title}
						onClick={() => {
							handleClickToOpenTemplate(template.id);
						}}
					>
						{template.title}
					</h4>
				</div>
				<p className={style.description}>{template.description}</p>
			</div>
			<div className={style.btnContainer}>
				<McButton
					onClick={() => {
						handleClickToOpenTemplate(template.id);
					}}
				>
					More info
				</McButton>
				{isDesktop ? (
					<div onClick={downloadFromDesktop}>
						<DownloadIcon />
					</div>
				) : (
					<>
						<div onClick={downloadTemplateHandler}>
							<DownloadIcon />
						</div>
						<a
							style={{ display: 'none' }}
							download
							href={templateFileUrl}
							ref={downloadRef}
						>
							Download
						</a>
					</>
				)}
			</div>
		</div>
	);
};
