/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Motion Cloud
 * API for Motion Cloud web application
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ContainerType } from '../model';
import { ErrorDTO } from '../model';
import { PageDtoContainerDto } from '../model';
/**
 * AdminContainersApi - axios parameter creator
 * @export
 */
export const AdminContainersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a page of containers
         * @param {number} page 
         * @param {number} size 
         * @param {string} [searchedValue] 
         * @param {ContainerType} [containerType] 
         * @param {boolean} [activeLicensesContainer] 
         * @param {number} [activatedByUserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedContainers: async (page: number, size: number, searchedValue?: string, containerType?: ContainerType, activeLicensesContainer?: boolean, activatedByUserId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            if (page === null || page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling getPaginatedContainers.');
            }
            // verify required parameter 'size' is not null or undefined
            if (size === null || size === undefined) {
                throw new RequiredError('size','Required parameter size was null or undefined when calling getPaginatedContainers.');
            }
            const localVarPath = `/api/admin/containers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (searchedValue !== undefined) {
                localVarQueryParameter['searchedValue'] = searchedValue;
            }

            if (containerType !== undefined) {
                localVarQueryParameter['containerType'] = containerType;
            }

            if (activeLicensesContainer !== undefined) {
                localVarQueryParameter['activeLicensesContainer'] = activeLicensesContainer;
            }

            if (activatedByUserId !== undefined) {
                localVarQueryParameter['activatedByUserId'] = activatedByUserId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminContainersApi - functional programming interface
 * @export
 */
export const AdminContainersApiFp = function(axiosInstance: Promise<AxiosInstance>, configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a page of containers
         * @param {number} page 
         * @param {number} size 
         * @param {string} [searchedValue] 
         * @param {ContainerType} [containerType] 
         * @param {boolean} [activeLicensesContainer] 
         * @param {number} [activatedByUserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginatedContainers(page: number, size: number, searchedValue?: string, containerType?: ContainerType, activeLicensesContainer?: boolean, activatedByUserId?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<PageDtoContainerDto>> {
            const localVarAxiosArgs = await AdminContainersApiAxiosParamCreator(configuration).getPaginatedContainers(page, size, searchedValue, containerType, activeLicensesContainer, activatedByUserId, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
    }
};

/**
 * AdminContainersApi - factory interface
 * @export
 */
export const AdminContainersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get a page of containers
         * @param {number} page 
         * @param {number} size 
         * @param {string} [searchedValue] 
         * @param {ContainerType} [containerType] 
         * @param {boolean} [activeLicensesContainer] 
         * @param {number} [activatedByUserId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginatedContainers(page: number, size: number, searchedValue?: string, containerType?: ContainerType, activeLicensesContainer?: boolean, activatedByUserId?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<PageDtoContainerDto>> {
            return AdminContainersApiFp(configuration).getPaginatedContainers(page, size, searchedValue, containerType, activeLicensesContainer, activatedByUserId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminContainersApi - object-oriented interface
 * @export
 * @class AdminContainersApi
 * @extends {BaseAPI}
 */
export class AdminContainersApi extends BaseAPI {
    /**
     * 
     * @summary Get a page of containers
     * @param {number} page 
     * @param {number} size 
     * @param {string} [searchedValue] 
     * @param {ContainerType} [containerType] 
     * @param {boolean} [activeLicensesContainer] 
     * @param {number} [activatedByUserId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminContainersApi
     */
    public async getPaginatedContainers(page: number, size: number, searchedValue?: string, containerType?: ContainerType, activeLicensesContainer?: boolean, activatedByUserId?: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<PageDtoContainerDto>> {
        return AdminContainersApiFp(this.configuration).getPaginatedContainers(page, size, searchedValue, containerType, activeLicensesContainer, activatedByUserId, options).then((request) => request(this.axios, this.basePath));
    }
}
