import React from 'react';
import termsStyle from 'assets/styles/terms.module.scss';
import { Modal } from 'antd';
import { McLogoIcon } from 'assets/icons/svg';
import cookieStyle from 'assets/styles/cookies.module.scss';
import { cookie_consent_name } from './CookiesConsent';

interface TermsProps {
	isOpen: boolean;
	onClose: () => void;
}

type CookieType = 'ESSENTIAL' | 'ANALYTICS';

interface Cookie {
	name: string;
	reason: string | undefined;
	type: CookieType;
	expiration: string;
}

const cookies: Cookie[] = [
	{
		name: 'mc_cookie_consent',
		reason: 'Used to track the consent status of other non-required cookies.',
		type: 'ESSENTIAL',
		expiration: '150 days',
	},
	{
		name: 'ARRAffinity',
		reason:
			'Helps route user requests to the same server, maintaining session state between requests.',
		type: 'ESSENTIAL',
		expiration: 'Session',
	},
	{
		name: 'ARRAffinitySameSite',
		reason:
			'Helps to manage security by ensuring that the session affinity cookie is only sent in contexts that are safe. (SameSite)',
		type: 'ESSENTIAL',
		expiration: 'Session',
	},
	{
		name: '_legacy_auth0.<NUMBER>',
		reason: 'Used for auth0 authentication purposes.',
		type: 'ESSENTIAL',
		expiration: '24 hours',
	},
	{
		name: 'auth0.<NUMBER>',
		reason: 'Used for auth0 authentication purposes.',
		type: 'ESSENTIAL',
		expiration: '24 hours',
	},
	{
		name: '_ga',
		reason: 'Used for Google Analytics to distinguish users.',
		type: 'ANALYTICS',
		expiration: '2 years',
	},
	{
		name: '_ga_NP9HZ5YSNP',
		reason: 'Used for Google Analytics to persist session state.',
		type: 'ANALYTICS',
		expiration: '2 years',
	},
	{
		name: '_gid',
		reason:
			'Used for Google Analytics to store and update a unique value for each page visited. ',
		type: 'ANALYTICS',
		expiration: '24 hours',
	},
	{
		name: '_gat',
		reason:
			'Used for Google Analytics to to throttle the request rate - limiting the collection of data on high traffic sites. ',
		type: 'ANALYTICS',
		expiration: '1 minute',
	},
];

const CookiePolicy: React.FC<TermsProps> = ({ isOpen, onClose }) => {
	return (
		<Modal
			title={
				<div className={termsStyle.titleHeader}>
					<McLogoIcon />
					<h2>Cookie Policy</h2>
				</div>
			}
			open={isOpen}
			onCancel={onClose}
			footer={null}
			centered
			width={800}
			className={termsStyle.modal}
		>
			<div className={termsStyle.scrollableContent}>
				<div className={termsStyle.section}>
					<p style={{ fontWeight: '400', fontSize: '13px' }}>
						<i>Last Updated: 2024-12-03</i>
					</p>
				</div>
				<div className={termsStyle.section}>
					<p>
						<strong>How We Use Cookies</strong>
					</p>
					<p>
						We use cookies for a variety of reasons detailed below.
						Unfortunately in most cases there are no industry standard options
						for disabling cookies without completely disabling the functionality
						and features they add to this site. It is recommended that you leave
						on all cookies if you are not sure whether you need them or not in
						case they are used to provide a service that you use.
					</p>
				</div>
				<div className={termsStyle.section}>
					<p>
						<strong>Disabling Cookies</strong>
					</p>
					<p>
						You can prevent the setting of cookies by adjusting the settings on
						your browser (see your browser Help for how to do this). Be aware
						that disabling cookies will affect the functionality of this and
						many other websites that you visit. Disabling cookies will usually
						result in also disabling certain functionality and features of this
						site. Therefore, it is recommended that you do not disable cookies.
						If you want to remove a prior consent for the use of all coookies,
						simply remove the cookie named <i>{cookie_consent_name}</i> and
						refresh the page.
					</p>
				</div>
				<div className={termsStyle.section}>
					<p>
						<strong>The Cookies We Set </strong>
					</p>
					<p>
						If you create an account with us, then we will use cookies for the
						management of the signup process and general administration. These
						cookies will usually be deleted when you log out; however, in some
						cases they may remain afterwards to remember your site preferences
						when logged out.
					</p>
				</div>
				<div className={termsStyle.section}>
					<p>
						<strong>Authentication related cookies</strong>
					</p>
					<p>
						We use cookies when you are logged in so that we can remember that
						you are logged in. This prevents you from having to log in every
						single time you visit a new page. These cookies are typically
						removed or cleared when you log out to ensure that you can only
						access restricted features and areas when logged in.
					</p>
				</div>
				<div className={termsStyle.section}>
					<p>
						<strong>Forms related cookies </strong>
					</p>
					<p>
						When you submit data to us through a form such as those found on
						contact pages or comment forms, cookies may be set to remember your
						user details for future correspondence.
					</p>
				</div>
				<div className={termsStyle.section}>
					<p>
						<strong>Essential cookies</strong>
					</p>
					<p>
						Essential cookies and scripts are essential for our website to
						function. They allow visitors to move around our website and use its
						basic features, such as accessing secure areas of the website,
						opening navigation, and displaying content.
					</p>
				</div>
				<div className={termsStyle.section}>
					<p>
						<strong>Analytics</strong>
					</p>
					<p>
						Analytics allow us to count visits and traffic sources to the
						website, so that we can measure and improve the performance of our
						site. Analytics let us know which pages are the most and least
						popular and see how visitors move around the site. All of the
						information collected from analytics cookies is aggregated so it is
						anonymous.
					</p>
				</div>
				<div className={termsStyle.section}>
					<p>
						<strong>
							Cookies we may use on the Tema Platform (Motion Cloud) domain
						</strong>
					</p>
					<p>
						The following are common cookies we might use during your visit to
						our domain. Any potential cookie not listed should be considered
						ESSENTIAL. All cookies from auth0 domains are considered ESSENTIAL
						by default. If you have any question or doubt regarding a certain
						cookie, please contact{' '}
						<i>
							<a href="mailto:support@imagesystems.se">
								support@imagesystems.se
							</a>
						</i>{' '}
						for more information.
					</p>
					<table
						className={cookieStyle.policyTable}
						style={{ marginTop: '1rem' }}
					>
						<thead>
							<tr>
								<th>
									<b>Cookie name</b>
								</th>
								<th>
									<b>Type</b>
								</th>
								<th>
									<b>Expiration</b>
								</th>
								<th>
									<b>Reason</b>
								</th>
							</tr>
						</thead>
						<tbody>
							{cookies.map((cookie) => {
								return (
									<tr key={cookie.name}>
										<td>{cookie.name}</td>
										<td>{cookie.type}</td>
										<td>{cookie.expiration}</td>
										<td>{cookie.reason}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
		</Modal>
	);
};

export default CookiePolicy;
