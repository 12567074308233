/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Motion Cloud
 * API for Motion Cloud web application
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ErrorDTO } from '../model';
import { FeatureFlagDto } from '../model';
import { PageDtoFeatureFlagDto } from '../model';
import { UpdateFeatureFlagDto } from '../model';
/**
 * AdminFeatureFlagsApi - axios parameter creator
 * @export
 */
export const AdminFeatureFlagsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a new feature flag
         * @param {UpdateFeatureFlagDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFeatureFlag: async (body: UpdateFeatureFlagDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addFeatureFlag.');
            }
            const localVarPath = `/api/admin/feature-flags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a feature flag by its id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeatureFlagById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteFeatureFlagById.');
            }
            const localVarPath = `/api/admin/feature-flags/id/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a feature flag by its key
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeatureFlagByKey: async (key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling deleteFeatureFlagByKey.');
            }
            const localVarPath = `/api/admin/feature-flags/key/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a feature flag by its id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureFlagById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getFeatureFlagById.');
            }
            const localVarPath = `/api/admin/feature-flags/id/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a feature flag by its key
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureFlagByKey: async (key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling getFeatureFlagByKey.');
            }
            const localVarPath = `/api/admin/feature-flags/key/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get paginated feature flags
         * @param {number} page 
         * @param {number} size 
         * @param {string} [searchedValue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedFeatureFlags: async (page: number, size: number, searchedValue?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            if (page === null || page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling getPaginatedFeatureFlags.');
            }
            // verify required parameter 'size' is not null or undefined
            if (size === null || size === undefined) {
                throw new RequiredError('size','Required parameter size was null or undefined when calling getPaginatedFeatureFlags.');
            }
            const localVarPath = `/api/admin/feature-flags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (searchedValue !== undefined) {
                localVarQueryParameter['searchedValue'] = searchedValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Toggle the feature flag
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleFeatureFlag: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling toggleFeatureFlag.');
            }
            const localVarPath = `/api/admin/feature-flags/id/{id}/toggle`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing feature flag
         * @param {UpdateFeatureFlagDto} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeatureFlag: async (body: UpdateFeatureFlagDto, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateFeatureFlag.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateFeatureFlag.');
            }
            const localVarPath = `/api/admin/feature-flags/id/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminFeatureFlagsApi - functional programming interface
 * @export
 */
export const AdminFeatureFlagsApiFp = function(axiosInstance: Promise<AxiosInstance>, configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a new feature flag
         * @param {UpdateFeatureFlagDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFeatureFlag(body: UpdateFeatureFlagDto, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await AdminFeatureFlagsApiAxiosParamCreator(configuration).addFeatureFlag(body, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Delete a feature flag by its id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFeatureFlagById(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await AdminFeatureFlagsApiAxiosParamCreator(configuration).deleteFeatureFlagById(id, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Delete a feature flag by its key
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFeatureFlagByKey(key: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await AdminFeatureFlagsApiAxiosParamCreator(configuration).deleteFeatureFlagByKey(key, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get a feature flag by its id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureFlagById(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<FeatureFlagDto>> {
            const localVarAxiosArgs = await AdminFeatureFlagsApiAxiosParamCreator(configuration).getFeatureFlagById(id, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get a feature flag by its key
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureFlagByKey(key: string, options?: AxiosRequestConfig): Promise<AxiosResponse<FeatureFlagDto>> {
            const localVarAxiosArgs = await AdminFeatureFlagsApiAxiosParamCreator(configuration).getFeatureFlagByKey(key, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get paginated feature flags
         * @param {number} page 
         * @param {number} size 
         * @param {string} [searchedValue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginatedFeatureFlags(page: number, size: number, searchedValue?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<PageDtoFeatureFlagDto>> {
            const localVarAxiosArgs = await AdminFeatureFlagsApiAxiosParamCreator(configuration).getPaginatedFeatureFlags(page, size, searchedValue, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Toggle the feature flag
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async toggleFeatureFlag(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await AdminFeatureFlagsApiAxiosParamCreator(configuration).toggleFeatureFlag(id, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Update an existing feature flag
         * @param {UpdateFeatureFlagDto} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFeatureFlag(body: UpdateFeatureFlagDto, id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await AdminFeatureFlagsApiAxiosParamCreator(configuration).updateFeatureFlag(body, id, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
    }
};

/**
 * AdminFeatureFlagsApi - factory interface
 * @export
 */
export const AdminFeatureFlagsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Add a new feature flag
         * @param {UpdateFeatureFlagDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFeatureFlag(body: UpdateFeatureFlagDto, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AdminFeatureFlagsApiFp(configuration).addFeatureFlag(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a feature flag by its id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFeatureFlagById(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AdminFeatureFlagsApiFp(configuration).deleteFeatureFlagById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a feature flag by its key
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFeatureFlagByKey(key: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AdminFeatureFlagsApiFp(configuration).deleteFeatureFlagByKey(key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a feature flag by its id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureFlagById(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<FeatureFlagDto>> {
            return AdminFeatureFlagsApiFp(configuration).getFeatureFlagById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a feature flag by its key
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureFlagByKey(key: string, options?: AxiosRequestConfig): Promise<AxiosResponse<FeatureFlagDto>> {
            return AdminFeatureFlagsApiFp(configuration).getFeatureFlagByKey(key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get paginated feature flags
         * @param {number} page 
         * @param {number} size 
         * @param {string} [searchedValue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginatedFeatureFlags(page: number, size: number, searchedValue?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<PageDtoFeatureFlagDto>> {
            return AdminFeatureFlagsApiFp(configuration).getPaginatedFeatureFlags(page, size, searchedValue, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Toggle the feature flag
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async toggleFeatureFlag(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AdminFeatureFlagsApiFp(configuration).toggleFeatureFlag(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing feature flag
         * @param {UpdateFeatureFlagDto} body 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFeatureFlag(body: UpdateFeatureFlagDto, id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AdminFeatureFlagsApiFp(configuration).updateFeatureFlag(body, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminFeatureFlagsApi - object-oriented interface
 * @export
 * @class AdminFeatureFlagsApi
 * @extends {BaseAPI}
 */
export class AdminFeatureFlagsApi extends BaseAPI {
    /**
     * 
     * @summary Add a new feature flag
     * @param {UpdateFeatureFlagDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFeatureFlagsApi
     */
    public async addFeatureFlag(body: UpdateFeatureFlagDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AdminFeatureFlagsApiFp(this.configuration).addFeatureFlag(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete a feature flag by its id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFeatureFlagsApi
     */
    public async deleteFeatureFlagById(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AdminFeatureFlagsApiFp(this.configuration).deleteFeatureFlagById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete a feature flag by its key
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFeatureFlagsApi
     */
    public async deleteFeatureFlagByKey(key: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AdminFeatureFlagsApiFp(this.configuration).deleteFeatureFlagByKey(key, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get a feature flag by its id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFeatureFlagsApi
     */
    public async getFeatureFlagById(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<FeatureFlagDto>> {
        return AdminFeatureFlagsApiFp(this.configuration).getFeatureFlagById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get a feature flag by its key
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFeatureFlagsApi
     */
    public async getFeatureFlagByKey(key: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<FeatureFlagDto>> {
        return AdminFeatureFlagsApiFp(this.configuration).getFeatureFlagByKey(key, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get paginated feature flags
     * @param {number} page 
     * @param {number} size 
     * @param {string} [searchedValue] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFeatureFlagsApi
     */
    public async getPaginatedFeatureFlags(page: number, size: number, searchedValue?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<PageDtoFeatureFlagDto>> {
        return AdminFeatureFlagsApiFp(this.configuration).getPaginatedFeatureFlags(page, size, searchedValue, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Toggle the feature flag
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFeatureFlagsApi
     */
    public async toggleFeatureFlag(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AdminFeatureFlagsApiFp(this.configuration).toggleFeatureFlag(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update an existing feature flag
     * @param {UpdateFeatureFlagDto} body 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFeatureFlagsApi
     */
    public async updateFeatureFlag(body: UpdateFeatureFlagDto, id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AdminFeatureFlagsApiFp(this.configuration).updateFeatureFlag(body, id, options).then((request) => request(this.axios, this.basePath));
    }
}
