import { Modal } from 'antd';
import style from 'assets/styles/terms.module.scss';
import { McLogoIcon } from 'assets/icons/svg';

interface TermsProps {
	isOpen: boolean;
	onClose: () => void;
}

export const TermsOfServiceModal: React.FC<TermsProps> = ({
	isOpen,
	onClose,
}) => (
	<Modal
		title={
			<div className={style.titleHeader}>
				<McLogoIcon />
				<h2>Terms of Service - Motion Cloud user account</h2>
			</div>
		}
		open={isOpen}
		onCancel={onClose}
		footer={null}
		centered
		width={800}
		className={style.modal}
	>
		<div className={style.scrollableContent}>
			<div className={style.section}>
				<p style={{ fontWeight: '400', fontSize: '13px' }}>
					<i>Last Updated: 2024-10-23</i>
				</p>
				<p>
					By creating a user account at temaplatform.com, you agree to the
					following terms and conditions ("Terms"). Please read them carefully.
				</p>
			</div>
			<div className={style.section}>
				<p>
					<strong>1. Acceptance of Terms</strong>
				</p>
				<p>
					By accessing or using the services provided by <i>Image Systems</i>{' '}
					("Service"), you agree to comply with and be bound by these Terms of
					Service. If you do not agree to these Terms, you may not use the
					Service.
				</p>
			</div>
			<div className={style.section}>
				<p>
					<strong>2. Eligibility</strong>
				</p>
				<p>
					To create a user account, you must be at least 18 years old. By
					registering, you represent and warrant that you meet this eligibility
					requirement.
				</p>
			</div>
			<div className={style.section}>
				<p>
					<strong>3. Account Registration</strong>
				</p>
				<ul>
					<li>
						You must provide accurate, complete, and up-to-date information
						during the registration process.
					</li>
					<li>
						You are responsible for maintaining the confidentiality of your
						account login credentials.
					</li>
					<li>
						You agree to notify <i>Image Systems</i> immediately of any
						unauthorized use of your account or any other breach of security.
					</li>
				</ul>
			</div>
			<div className={style.section}>
				<p>
					<strong>4. User Responsibilities</strong>
				</p>

				<p>By using the Service, you agree not to:</p>
				<ul>
					<li>Violate any applicable laws or regulations.</li>
					<li>
						Post or share any harmful, threatening, abusive, defamatory,
						obscene, or otherwise objectionable content.
					</li>
					<li>Engage in fraudulent or deceptive practices.</li>
					<li>
						Access or attempt to access the Service through automated or
						non-human means (e.g., bots, scripts).
					</li>
					<li>
						Disrupt or interfere with the integrity or performance of the
						Service.
					</li>
				</ul>
			</div>
			<div className={style.section}>
				<p>
					<strong>5. Termination of Account</strong>
				</p>

				<p>
					<i>Image Systems</i> reserves the right to suspend or terminate your
					account at any time, without notice, if:
				</p>
				<ul>
					<li>You violate any of the provisions of these Terms.</li>
					<li>
						You engage in behavior deemed harmful to other users or the
						integrity of the Service.
					</li>
				</ul>
			</div>
			<div className={style.section}>
				<p>
					<strong>6. Privacy</strong>
				</p>
				<p>
					Our [Privacy Policy] outlines how we collect, use, and protect your
					personal data. By using the Service, you agree to the collection and
					use of information as outlined in the Privacy Policy.
				</p>
			</div>
			<div className={style.section}>
				<p>
					<strong>7. Intellectual Property</strong>
				</p>
				<p>
					All content, features, and functionality (including but not limited to
					text, images, software, and logos) are the exclusive property of Image
					Systems or its licensors. You may not reproduce, modify, or distribute
					any part of the Service without prior written consent.
				</p>
			</div>
			<div className={style.section}>
				<p>
					<strong>8. Modification of Terms</strong>
				</p>
				<p>
					<i>Image Systems</i> reserves the right to modify these Terms at any
					time. Any changes will be effective immediately upon posting on the
					Service. Your continued use of the Service following any changes
					constitutes your acceptance of the revised Terms.
				</p>
			</div>
			<div className={style.section}>
				<p>
					<strong>9. Disclaimer of Warranties</strong>
				</p>
				<p>
					The Service is provided "as is" and "as available" without any
					warranties of any kind, either express or implied.{' '}
					<i>Image Systems</i> does not guarantee that the Service will be
					uninterrupted, secure, or error-free.
				</p>
			</div>
			<div className={style.section}>
				<p>
					<strong>10. Limitation of Liability</strong>
				</p>
				<p>
					To the fullest extent permitted by law, <i>Image Systems</i> shall not
					be liable for any indirect, incidental, special, or consequential
					damages arising from your use of the Service.
				</p>
			</div>
			<div className={style.section}>
				<p>
					<strong>11. Governing Law</strong>
				</p>
				<p>
					These Terms are governed by and construed in accordance with the laws
					of Sweden. Any disputes arising out of or related to these Terms shall
					be resolved exclusively in the courts of Sweden.
				</p>
			</div>
			<div className={style.section}>
				<p>
					<strong>12. Contact Information</strong>
				</p>
				<p>
					For questions or concerns regarding these Terms, please contact us at
					info@imagesystems.se.
				</p>
			</div>
		</div>
	</Modal>
);

export const PrivacyModal: React.FC<TermsProps> = ({ isOpen, onClose }) => (
	<Modal
		title={
			<div className={style.titleHeader}>
				<McLogoIcon />
				<h2>Privacy Policy</h2>
			</div>
		}
		open={isOpen}
		onCancel={onClose}
		footer={null}
		centered
		width={800}
		className={style.modal}
	>
		<div className={style.scrollableContent}>
			<div className={style.section}>
				<p>
					We are committed to protecting your personal data in compliance with
					the General Data Protection Regulation (GDPR). This policy outlines
					how we collect, use, and safeguard your personal information.
				</p>
			</div>
			<div className={style.section}>
				<b>• Data Collection:</b>
				<p className={style.paragraphBullet}>
					We collect personal data only when necessary, such as your name, email
					address, or other relevant details.
				</p>
			</div>
			<div className={style.section}>
				<b>• Data Usage:</b>
				<p className={style.paragraphBullet}>
					Your data is used solely for providing our services, improving your
					experience, and fulfilling legal obligations.
				</p>
			</div>
			<div className={style.section}>
				<b>• Data Protection:</b>
				<p className={style.paragraphBullet}>
					We implement appropriate security measures to prevent unauthorized
					access, alteration, or deletion of your data.
				</p>
			</div>
			<div className={style.section}>
				<b>• Your Rights:</b>
				<p className={style.paragraphBullet}>
					You have the right to access, correct, or request deletion of your
					personal data. You can also withdraw consent for data processing at
					any time.
				</p>
			</div>
			<div className={style.section}>
				<b>• Contact:</b>
				<p className={style.paragraphBullet}>
					For any inquiries regarding your data or this policy, please contact
					us at info@imagesystems.se.
				</p>
			</div>
		</div>
	</Modal>
);
