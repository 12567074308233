import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { ErrorDTO, UserDto } from 'api';
import { usersService } from 'services/UsersService';
import { authService } from 'services/AuthService';

import { RootState } from 'store';
import { createAppAsyncThunk, setLoadingState } from 'utils/rtk';
import { getErrorMessageAnd, rejectionError } from 'utils/errors';
import adminUsersService from 'services/admin/AdminUsersService';

export interface UserState {
	user: UserDto;
	loading: boolean;
	error: ErrorDTO | null;
}

const initialState: UserState = {
	user: {} as UserDto,
	loading: true,
	error: null,
};

export const deleteUser = createAppAsyncThunk(
	'oneUser/deleteUser',
	(email: string, { rejectWithValue }) =>
		adminUsersService
			.deleteUserByEmail(email)
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const deleteUserAvatar = createAppAsyncThunk(
	'oneUser/deleteUserAvatar',
	(email: string, { rejectWithValue }) =>
		usersService
			.deleteUsersProfilePicture(email)
			.catch(getErrorMessageAnd(rejectWithValue))
);

type CreateAccountParams = Parameters<
	typeof adminUsersService.createAccountForm
>;
export const createUser = createAppAsyncThunk(
	'oneUser/createUser',
	(params: CreateAccountParams, { rejectWithValue }) =>
		adminUsersService
			.createAccountForm(...params)
			.catch(getErrorMessageAnd(rejectWithValue))
);

type SignupParams = Parameters<typeof authService.signUp>;
export const signupUser = createAppAsyncThunk(
	'oneUser/signupUser',
	(params: SignupParams, { rejectWithValue }) =>
		authService.signUp(...params).catch(getErrorMessageAnd(rejectWithValue))
);

type UpdateUserParams = Parameters<typeof adminUsersService.updateUserForm>;
export const updateUser = createAppAsyncThunk(
	'oneUser/updateUser',
	(params: UpdateUserParams, { rejectWithValue }) =>
		adminUsersService
			.updateUserForm(...params)
			.catch(getErrorMessageAnd(rejectWithValue))
);

const oneUserSlice = createSlice({
	name: 'oneUser',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(createUser.pending, setLoadingState)
			.addCase(createUser.fulfilled, (state) => {
				state.loading = false;
				state.error = null;
			})

			.addCase(signupUser.pending, setLoadingState)
			.addCase(signupUser.fulfilled, (state) => {
				state.loading = false;
				state.error = null;
			})

			.addCase(updateUser.pending, setLoadingState)
			.addCase(updateUser.fulfilled, (state) => {
				state.loading = false;
				state.error = null;
			})

			.addCase(deleteUser.pending, setLoadingState)
			.addCase(deleteUser.fulfilled, (state) => {
				state.loading = false;
				state.user = {} as UserDto;
				state.error = null;
			})

			.addCase(deleteUserAvatar.pending, setLoadingState)
			.addCase(deleteUserAvatar.fulfilled, (state) => {
				state.loading = false;
				state.error = null;
			})

			.addMatcher(
				isAnyOf(
					createUser.rejected,
					signupUser.rejected,
					updateUser.rejected,
					deleteUser.rejected,
					deleteUserAvatar.rejected
				),
				(state, action) => {
					state.error = action.payload ?? rejectionError;
					state.loading = false;
				}
			);
	},
});

export const oneUserState = (state: RootState) => state.oneUser;

export default oneUserSlice.reducer;
