import { App, Spin } from 'antd';
import style from 'assets/styles/supportPage.module.scss';
import { SupportItemDto, TagDto } from 'api';
import { DownloadIcon } from 'assets/icons/svg';
import { FC, useMemo } from 'react';
import { supportService, blobSupportService } from 'services/SupportService';
import { Cond } from 'utils/Cond';
import { McMarkdown } from 'components/mc';
import { getPublicImageURL } from 'services/ApiService';
import { useMutation, useQuery } from '@tanstack/react-query';
import { mcErrorNotification } from 'utils/Notifications';

interface Props {
	supportItem: SupportItemDto;
	allTags: TagDto[];
}

export const SupportPanelContent: FC<Props> = ({
	supportItem,
	allTags,
}: Props) => {
	const { tagIds, answer } = supportItem;
	const { notification } = App.useApp();

	const { data: supportAttachmentFileName } = useQuery({
		queryKey: ['support', supportItem.id, 'file', 'name'],
		queryFn: () =>
			supportService
				.getSupportItemFileName(supportItem.id)
				.then((res) => res.data),
		enabled: !!supportItem.downloadLink,
	});

	const { mutateAsync: downloadSupportItem, isPending: loading } = useMutation({
		mutationFn: ({
			supportItemId,
			fileName,
		}: {
			supportItemId: number;
			fileName: string;
		}) =>
			blobSupportService
				.getSupportItemFile(supportItemId)
				.then((res) => res.data),
		onSuccess: (dataFile, { fileName }) => {
			let data = new Blob([dataFile]);
			let requestURL = window.URL.createObjectURL(data);
			const tempLink = document.createElement('a');
			tempLink.href = requestURL;
			tempLink.setAttribute('download', fileName);
			tempLink.click();
		},

		onError: (err) => {
			notification.error(
				mcErrorNotification('Error', err, 'download', 'support item')
			);
		},
	});

	const supportItemTags = useMemo(
		() => allTags.filter((tag) => tagIds.includes(tag.id)),
		[allTags, tagIds]
	);

	return (
		<Spin spinning={!supportItem}>
			<div style={{ marginTop: '1.5rem' }}>
				<McMarkdown content={answer} />
			</div>
			<Cond if={supportItem?.fullSizeImagePath}>
				<img
					className={style.supportItemImg}
					src={getPublicImageURL(supportItem?.fullSizeImagePath!)}
					alt=""
				/>
			</Cond>

			<div
				className={style.supportItemTagsRow}
				style={{ marginTop: tagIds && tagIds.length > 0 ? '1rem' : '0' }}
			>
				<div className={style.supportItemTags}>
					{supportItemTags.map((tag) => (
						<span className={style.supportItemTag}>{tag.name}</span>
					))}
				</div>
				<Cond if={supportItem?.downloadLink}>
					<Spin size="small" spinning={loading}>
						<div
							style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
						>
							<span>Download Attachment</span>
							<div
								style={{ cursor: 'pointer' }}
								onClick={async (e) => {
									e.preventDefault();
									e.stopPropagation();
									if (!!supportAttachmentFileName) {
										await downloadSupportItem({
											supportItemId: supportItem.id,
											fileName: supportAttachmentFileName,
										});
									}
								}}
							>
								<DownloadIcon />
							</div>
						</div>
					</Spin>
				</Cond>
			</div>
		</Spin>
	);
};
