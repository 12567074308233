import { FC, useEffect } from 'react';
import {
	Route,
	Routes,
	Navigate,
	useNavigate,
	useLocation,
} from 'react-router-dom';

import { AppLayout } from 'modules/Layout';
import { readPermissions } from 'utils/permissions';

import {
	Login,
	HomePage,
	LicensingPage,
	StorePage,
	NewsPage,
	SupportPage,
	TemplatesPage,
	ManageEngines,
	ManageAppReleases,
	ManageUsers,
	ManageNews,
	ManageTemplates,
	ManageLicenses,
	ManageSupport,
	ManageStatistics,
	ManageModules,
	ManageApplications,
	OneNews,
	OneApp,
	OneEngine,
	OneTemplate,
	NotFound,
	ManageOrganizations,
} from './pages';

import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import {
	authState,
	guestMode,
	loginUsingToken,
	logout,
	updateOnlineStatus,
	fetchAndSetAdminPermissions,
} from 'store/slices/auth';
import { ProtectedRoute } from 'Router/ProtectedRoute';
import { AuthRoute } from 'Router/AuthRoute';
import { Preloader } from 'modules/Preloader';
import { OfflineModal } from 'modules/OfflineModal';
import { LocalContextProvider, isDesktop } from 'desktop';
import { useLocalSettings } from 'settings';
import { ConfigProvider } from 'antd';
import { Cond } from 'utils/Cond';
import { Titlebar } from 'desktop/components';
import { useLightMode } from 'hooks/useLightMode';
import { cirrusTheme, nimbusTheme } from 'themes';
import { ThemePage } from 'themes/ThemePage';
import { ComponentsPage } from 'components/mc/ComponentsPage';
import { Signup } from 'pages/Signup';
import { useAuth0 } from '@auth0/auth0-react';
import { apiConfig } from 'services/ApiService';
import { App as AntdApp } from 'antd';
import { NotificationConfig } from 'antd/es/notification/interface';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ManageFeatureFlags from 'pages/ManageFeatureFlags';
import NonAuthLayout from 'modules/NonAuthLayout';
import ManageContainers from 'pages/ManageContainers';
import CookiesConsent from 'components/CookiesConsent';
import DesktopUpdateManager from 'modules/DesktopUpdateManager';

const createAuthParams = (scopes: string[] | undefined) => {
	// Scoped access tokens does not work correctly, so they are disabled for now.
	// FIXME: Re-enable this to limit the scope for access tokens
	return {};

	// if (!Array.isArray(scopes)) return {};

	// Creating a new access token for the necessary scope does not work correctly on non-https, so it's disabled in development
	// if (process.env.NODE_ENV !== 'production') return {};

	// return {
	// 	scope: scopes.join(' '),
	// };
};

const nonAuthPaths = [
	'/forgot-password',
	'/reset-password',
	'/set-password',
	'/signup',
	'/login',
];

const queryClient = new QueryClient();
export const ISMA_FIRM: number = 6000157;

export const App: FC = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { loading: apiLoading, isOnline, isGuest } = useAppSelector(authState);
	const dispatch = useAppDispatch();
	const {
		isAuthenticated: hasAPIAuth,
		isLoading: authLoading,
		getAccessTokenSilently,
		getIdTokenClaims,
	} = useAuth0();

	const loading = apiLoading || authLoading;

	useEffect(() => {
		// Remove window decorations if they have been added by the authentication redirect
		if (isDesktop) {
			import('@tauri-apps/api/window').then(({ appWindow }) => {
				appWindow.setDecorations(false);
			});
		}
	}, []);

	useEffect(() => {
		const handleStatusChange = () =>
			dispatch(updateOnlineStatus(navigator.onLine));

		window.addEventListener('online', handleStatusChange);
		window.addEventListener('offline', handleStatusChange);

		return () => {
			window.removeEventListener('online', handleStatusChange);
			window.removeEventListener('offline', handleStatusChange);
		};
	}, [dispatch]);

	const [authIsGuest] = useLocalSettings('isGuest');
	const [lightMode] = useLightMode();
	const { permissions } = useAppSelector(authState);
	const showOffline = location.pathname !== '/login' && !isGuest && !isOnline;

	useEffect(() => {
		if (lightMode) {
			document.body.classList.add('lightMode');
		} else {
			document.body.classList.remove('lightMode');
		}
	}, [lightMode]);

	// Try to restore authentication and redirect to login if it's not possible to do so
	useEffect(() => {
		if (authLoading || isGuest || !isOnline || hasAPIAuth || isGuest) return;
		if (nonAuthPaths.includes(location.pathname)) return;

		getAccessTokenSilently().catch((e: Error) => {
			if (!('error' in e && e.error === 'missing_refresh_token')) {
				// Missing refresh token is expected when the user has not previously logged in
				console.error('[AUTH CHECK] Silent token error: %o', e);
			}
			navigate('/login', { replace: true });
		});
	}, [
		hasAPIAuth,
		authLoading,
		authIsGuest,
		getAccessTokenSilently,
		navigate,
		location.pathname,
		isOnline,
		isGuest,
		dispatch,
	]);

	// Update the store according to the guest/auth0 states
	useEffect(() => {
		if (authLoading) return;

		if (authIsGuest) {
			dispatch(guestMode());
		} else if (hasAPIAuth) {
			getIdTokenClaims().then(loginUsingToken).then(dispatch);
		} else {
			dispatch(logout({ isGuest: false }));
		}
	}, [
		hasAPIAuth,
		dispatch,
		getIdTokenClaims,
		isGuest,
		authLoading,
		authIsGuest,
	]);

	useEffect(() => {
		apiConfig.accessToken = async (_, scopes) => {
			const token = await getAccessTokenSilently({
				authorizationParams: createAuthParams(scopes),
			});
			return token;
		};
	}, [getAccessTokenSilently]);

	useEffect(() => {
		if (hasAPIAuth && !authLoading) {
			const checkAdminStatus = async () => {
				const token = await getAccessTokenSilently();

				if (token) {
					dispatch(fetchAndSetAdminPermissions(token));
				}
			};

			checkAdminStatus();
		}
	}, [hasAPIAuth, authLoading, dispatch, getAccessTokenSilently]);

	const notificationConfig: NotificationConfig = isDesktop
		? {
				stack: { threshold: 2 },
				maxCount: 3,
				placement: 'bottomRight', // bottomRight placement does not cover up as much on the desktop app
				duration: 5,
		  }
		: {
				stack: { threshold: 3 },
				maxCount: 5,
				placement: 'topRight',
				duration: 5,
		  };

	const routePermissions = {
		admin: {
			permissions: [readPermissions.news],
			element: <Navigate to="/admin/news" />,
		},
		'admin/users/:email?': {
			permissions: [readPermissions.users],
			element: <ManageUsers />,
		},
		'admin/organizations/:id?': {
			permissions: [readPermissions.organizations],
			element: <ManageOrganizations />,
		},
		'admin/engines': {
			permissions: [readPermissions.engines],
			element: <ManageEngines />,
		},
		'admin/releases': {
			permissions: [readPermissions.releases],
			element: <ManageAppReleases />,
		},
		'admin/news': {
			permissions: [readPermissions.news],
			element: <ManageNews />,
		},
		'admin/templates': {
			permissions: [readPermissions.templates],
			element: <ManageTemplates />,
		},
		'admin/licenses/:id?': {
			permissions: [readPermissions.licenses],
			element: <ManageLicenses />,
		},
		'admin/containers': {
			permissions: [readPermissions.licenses],
			element: <ManageContainers />,
		},
		'admin/support': {
			permissions: [readPermissions.support],
			element: <ManageSupport />,
		},
		'admin/modules': {
			permissions: [readPermissions.modules],
			element: <ManageModules />,
		},
		'admin/applications': {
			permissions: [readPermissions.applications],
			element: <ManageApplications />,
		},
		'admin/statistics': {
			permissions: [readPermissions.statistics],
			element: <ManageStatistics />,
		},
		'admin/feature-flags': {
			permissions: [readPermissions.featureflags],
			element: <ManageFeatureFlags />,
		},
	};

	const hasPermissions = (
		userPermissions: string[],
		requiredPermissions: string[]
	): boolean =>
		requiredPermissions.some((permission) =>
			userPermissions.includes(permission)
		);

	return (
		<QueryClientProvider client={queryClient}>
			<ConfigProvider theme={lightMode ? cirrusTheme : nimbusTheme}>
				<LocalContextProvider>
					<AntdApp notification={notificationConfig}>
						<Cond if={isDesktop}>
							<Titlebar />
						</Cond>
						{!isDesktop && <CookiesConsent />}
						{isDesktop && <DesktopUpdateManager />}
						<OfflineModal show={showOffline} />
						<Routes>
							<Route
								path="login"
								element={
									<AuthRoute
										children={
											<NonAuthLayout>
												<Login />
											</NonAuthLayout>
										}
									/>
								}
							/>
							<Route
								path="signup"
								element={
									<AuthRoute
										children={
											<NonAuthLayout>
												<Signup />
											</NonAuthLayout>
										}
									/>
								}
							/>
							<Route
								path="/"
								element={<ProtectedRoute children={<AppLayout />} />}
							>
								<Route index element={<HomePage />} />
								{isGuest ? undefined : (
									<>
										<Route path="news/:id" element={<OneNews />} />
										<Route path="apps/:appId/:engineId?" element={<OneApp />} />
										<Route path="engines/:id" element={<OneEngine />} />
										<Route path="store" element={<StorePage />} />
										<Route path="news" element={<NewsPage />} />
										<Route path="store/apps/:id" element={<OneApp />} />
										<Route path="store/news/:id" element={<OneNews />} />
									</>
								)}
								<Route path="templates" element={<TemplatesPage />} />
								<Route path="templates/:id" element={<OneTemplate />} />
								<Route path="licensing/:id?" element={<LicensingPage />} />
								<Route path="support" element={<Navigate to="/support/qa" />} />
								<Route
									path="support/:category/:id?"
									element={<SupportPage />}
								/>
								{Object.entries(routePermissions).map(
									([path, { permissions: requiredPermissions, element }]) =>
										hasPermissions(permissions, requiredPermissions) ? (
											<Route key={path} path={path} element={element} />
										) : null
								)}
								{loading && <Route path="*" element={<Preloader />} />}
							</Route>
							<Route path="theme" element={<ThemePage />} />
							<Route path="components" element={<ComponentsPage />} />
							<Route path="*" element={<NotFound />} />
						</Routes>
					</AntdApp>
				</LocalContextProvider>
			</ConfigProvider>
			<ReactQueryDevtools buttonPosition="bottom-left" />
		</QueryClientProvider>
	);
};

export default App;
