import { InputField } from 'components/fields';
import { CancelButton } from 'components/buttons';
import style from 'assets/styles/editAddElementForm.module.scss';
import style2 from 'assets/styles/manageElements.module.scss';
import { Dispatch, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { GENERIC_ERROR, getRequestError } from 'utils/errors';
import { yupResolver } from '@hookform/resolvers/yup';
import { editOrganizationSchema } from 'validations/FormValidation';
import { AddOrganizationDto, ErrorDTO, OrganizationDto, UserDto } from 'api';
import { IconRemoveFromTrash, IconTrash, LinkIcon } from 'assets/icons/svg';
import { App, Col, Row, Spin } from 'antd';
import SelectAddUser from 'components/selects/SelectAddUser';
import { webCrmOrganizationUrl } from 'utils/urls';
import SelectWebCrmOrganization from 'components/selects/SelectWebCrmOrganization';
import adminOrganizationService from 'services/admin/AdminOrganizationService';
import {
	errorModal,
	errorNotification,
	saveSuccessNotification,
} from 'utils/Notifications';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAppSelector } from 'hooks/hooks';
import { authState } from 'store/slices/auth';
import { hasWritePermission } from 'utils/permissions';
import { McButton } from 'components/mc';

interface Props {
	organization: OrganizationDto | 'new';
	setSelected: Dispatch<OrganizationDto | undefined>;
	onHide?: () => void;
}

interface FormValues {
	name: string;
	email: string;
}

type UserItemVariation = 'ADD' | 'REMOVE' | 'ORDINARY';

const EditOrganizationForm = ({ organization, setSelected, onHide }: Props) => {
	const isNew = organization === 'new';

	const [usersToAdd, setUsersToAdd] = useState<UserDto[]>([]);
	const [usersToRemove, setUsersToRemove] = useState<UserDto[]>([]);
	const { modal, notification } = App.useApp();
	const queryClient = useQueryClient();

	const [webCrmId, setWebCrmId] = useState<number | undefined>(
		!isNew ? organization.webCrmId : undefined
	);

	const {
		formState: { errors },
		handleSubmit,
		control,
		setValue,
	} = useForm<FormValues>({
		mode: 'onBlur',
		resolver: yupResolver(editOrganizationSchema),
	});

	const { permissions } = useAppSelector(authState);

	const canEdit = hasWritePermission(permissions, 'organizations');

	useEffect(() => {
		const opts = {
			shouldValidate: false,
			shouldDirty: false,
		};

		if (!isNew) {
			setValue('name', organization.name ?? '', opts);
			setValue('email', organization.email ?? '', opts);
		}
	}, [organization, setValue, isNew]);

	const editOrganization: SubmitHandler<FormValues> = (data) => {
		if (isNew) return;

		const organizationDto: OrganizationDto = {
			id: organization.id,
			name: data.name.trim(),
			email: data.email?.trim(),
			webCrmId: webCrmId,
		};

		// If nothing has changed in the form then hide and exit
		if (
			organization.name === organizationDto.name &&
			organization.email === organizationDto.email &&
			organization.webCrmId === organizationDto.webCrmId &&
			usersToAdd.length === 0 &&
			usersToRemove.length === 0
		) {
			setSelected(undefined);
			if (onHide) onHide();
			return;
		}
		const unableToEditUsers: UserDto[] = [];

		adminOrganizationService
			.updateOrganization(organizationDto)
			.then(() =>
				Promise.allSettled(
					usersToAdd
						.map((user) =>
							adminOrganizationService
								.addUserToOrganization(organization.id!, user.id!)
								.catch(() => unableToEditUsers.push(user))
						)
						.concat(
							usersToRemove.map((user) =>
								adminOrganizationService
									.removeUserFromOrganization(organization.id!, user.id!)
									.catch(() => unableToEditUsers.push(user))
							)
						)
				)
			)
			.then(() => {
				if (unableToEditUsers.length > 0) {
					modal.warning(
						errorModal(
							'Unable to edit users:\n' +
								unableToEditUsers.map(
									(user) => ' - ' + user.firstName + ' ' + user.lastName + '\n'
								) +
								'\nThis might indicate that they already have been added or removed by someone else!\nTry refreshing the page!'
						)
					);
				} else {
					if (onHide) onHide();
					notification.success(saveSuccessNotification(organizationDto.name));
					setSelected(undefined);
				}
				queryClient.invalidateQueries({
					queryKey: ['users'],
				});

				queryClient.invalidateQueries({
					queryKey: ['organizations'],
				});
			})
			.catch((err: unknown) => {
				const errorDto: ErrorDTO = getRequestError(err);
				notification.error(errorNotification(decodeError(errorDto)));
			})
			.finally(() => {
				// cleanup
				setUsersToAdd([]);
				setUsersToRemove([]);
			});
	};

	const addOrganization: SubmitHandler<FormValues> = (data) => {
		const addOrganizationDto: AddOrganizationDto = {
			name: data.name.trim(),
			email: data.email?.trim(),
			webCrmId: webCrmId,
		};

		const unableToAddUsers: UserDto[] = [];

		adminOrganizationService
			.addOrganization(addOrganizationDto)
			.then((res) =>
				Promise.allSettled(
					usersToAdd.map((user) =>
						adminOrganizationService
							.addUserToOrganization(res.data.id!, user.id!)
							.catch((err) => unableToAddUsers.push(user))
					)
				)
			)
			.then(() => {
				unableToAddUsers.length > 0
					? modal.warning(
							errorModal(
								'Unable to add users:\n' +
									unableToAddUsers.map(
										(user) =>
											' - ' + user.firstName + ' ' + user.lastName + '\n'
									)
							)
					  )
					: notification.success(
							saveSuccessNotification(addOrganizationDto.name)
					  );

				queryClient.invalidateQueries({
					queryKey: ['users'],
				});

				queryClient.invalidateQueries({
					queryKey: ['organizations'],
				});
				if (onHide) onHide();
			})
			.catch((err: unknown) => {
				const errorDto: ErrorDTO = getRequestError(err);
				notification.error(errorNotification(decodeError(errorDto)));
			})
			.finally(() => {
				// cleanup
				setUsersToAdd([]);
				setUsersToRemove([]);
			});
	};

	// More specialized error
	const decodeError = (err: ErrorDTO) =>
		err.code === 'ENTITY_UNIQUE_CONFLICT' && err.target === 'webCrmId'
			? 'The specified webCRM organization is already in use!'
			: err.code === 'ENTITY_UNIQUE_CONFLICT' && err.target === 'name'
			? 'The specified organization name is already in use!'
			: GENERIC_ERROR;

	// Fetch all users for the current organization
	const {
		data: usersInOrganization,
		isLoading: userLoading,
		error: getUsersError,
	} = useQuery({
		queryKey: ['organizations', (organization as OrganizationDto).id, 'users'],
		queryFn: () =>
			adminOrganizationService
				.getUsersInOrganization(0, 1000, (organization as OrganizationDto).id)
				.then((res) => res.data.content),
		initialData: [],
		enabled: !isNew,
	});

	useEffect(() => {
		if (!getUsersError) return;
		notification.error(
			errorNotification(
				'There was an error while fetching the user list for the organization!'
			)
		);
	}, [getUsersError, notification]);

	const handleRemoveUserFromSelected = (id: number | undefined) => {
		if (!id) return;
		setUsersToAdd((selectedUsers) =>
			selectedUsers.filter((selectedUser) => selectedUser.id !== id)
		);
	};

	const handleRemoveUserFromRemovalList = (id: number | undefined) => {
		if (!id) return;
		setUsersToRemove((usersForRemoval) =>
			usersForRemoval.filter((userForRemoval) => userForRemoval.id !== id)
		);
	};

	const handleRemoveExistingUser = useCallback(
		(id: number | undefined) => {
			if (!id) return;
			const user = usersInOrganization.find((userInOrg) => userInOrg.id === id);
			setUsersToRemove((usersToBeRemoved) => {
				// Check if user already is scheduled for removal
				if (!user || !!usersToBeRemoved.find((user) => user.id === id)) {
					return usersToBeRemoved;
				}

				return [...usersToBeRemoved, user];
			});
		},
		[usersInOrganization]
	);

	const displayedUserList: (UserDto & { variation: UserItemVariation })[] =
		useMemo(
			() => [
				...usersToAdd.map((user) => {
					return { ...user, variation: 'ADD' } as UserDto & {
						variation: UserItemVariation;
					};
				}),
				...usersInOrganization.map((user) => {
					const shouldRemove = !!usersToRemove.find(
						(toRemove) => toRemove.id === user.id
					);

					return {
						...user,
						variation: shouldRemove ? 'REMOVE' : 'ORDINARY',
						status: user.status,
					} as UserDto & {
						variation: UserItemVariation;
					};
				}),
			],
			[usersInOrganization, usersToAdd, usersToRemove]
		);

	const userItem = useCallback(
		(user: UserDto & { variation: UserItemVariation }) => (
			<Row className={style.userDisplayItem} key={user.id}>
				<Col span={10} style={{ display: 'flex', alignItems: 'center' }}>
					{user.variation !== 'ORDINARY' && (
						<div
							className={style2.statusCircle}
							style={{
								backgroundColor:
									user.variation === 'ADD'
										? 'var(--add-green)'
										: 'var(--add-red)',
								marginRight: '1rem',
							}}
						/>
					)}
					<div>
						{user.firstName} {user.lastName}
					</div>
				</Col>
				<Col span={7}>{user.email}</Col>
				<Col span={7} style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<div
						onClick={() =>
							user.variation === 'ADD'
								? handleRemoveUserFromSelected(user.id)
								: user.variation === 'REMOVE'
								? handleRemoveUserFromRemovalList(user.id)
								: handleRemoveExistingUser(user.id)
						}
						style={{ cursor: 'pointer' }}
					>
						{user.variation === 'REMOVE' ? (
							<IconRemoveFromTrash />
						) : (
							<IconTrash />
						)}
					</div>
				</Col>
			</Row>
		),
		[handleRemoveExistingUser]
	);

	return (
		<form
			className={isNew ? style.addFormWrapper : style.editFormWrapper}
			onSubmit={handleSubmit(isNew ? addOrganization : editOrganization)}
		>
			<div className={style.editForm}>
				<div className={style.column}>
					<Controller
						name="name"
						control={control}
						render={({ field }) => (
							<InputField
								placeholder={'Your Organization Name'}
								{...field}
								label={'Organization Name'}
								error={!!errors.name}
								errorMessage={errors.name?.message}
							/>
						)}
					/>
				</div>
				<div className={style.column}>
					<Controller
						name="email"
						control={control}
						render={({ field }) => (
							<InputField
								placeholder={'youremail@example.com'}
								{...field}
								label={'Contact Email'}
								error={!!errors.email}
								errorMessage={errors.email?.message}
							/>
						)}
					/>
				</div>
			</div>
			<div
				style={{
					display: 'flex',
					marginBottom: '1.5rem',
					alignItems: 'center',
				}}
			>
				<SelectWebCrmOrganization
					selectedId={webCrmId}
					setSelectedId={setWebCrmId}
				/>
				{webCrmId ? (
					<a
						href={webCrmId ? webCrmOrganizationUrl(webCrmId) : ''}
						className={style.linkIcon}
						style={{ marginLeft: '1rem' }}
						target="_blank"
						rel="noreferrer"
					>
						<LinkIcon />
					</a>
				) : (
					<div
						className={style.disabledLinkIcon}
						style={{
							marginTop: '1.5rem',
							marginLeft: '1rem',
						}}
					>
						<LinkIcon />
					</div>
				)}
			</div>
			{/*FIXME: start using gap instead on these instead of margin!*/}
			<div style={{ marginBottom: '1.5rem' }}>
				<SelectAddUser
					tabIndex={40}
					usersToAdd={usersToAdd}
					setUsersToAdd={setUsersToAdd}
					existingUsersInOrganization={usersInOrganization}
				/>
			</div>
			{userLoading ? (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						marginTop: '2rem',
					}}
				>
					<Spin spinning={userLoading} size="large"></Spin>
				</div>
			) : displayedUserList.length === 0 ? (
				<div style={{ textAlign: 'center', color: 'var(--text-normal)' }}>
					No users found
				</div>
			) : (
				<div className={style.userDisplayBox}>
					{displayedUserList.map((user) => userItem(user))}
				</div>
			)}
			<div className={style.buttonsWrapper} style={{ marginTop: '2rem' }}>
				<CancelButton
					tabIndex={60}
					onClickCancel={() => {
						setSelected(undefined);
						if (onHide) onHide();
					}}
				/>
				<McButton primary type="submit" disabled={!canEdit}>
					Save
				</McButton>
			</div>
		</form>
	);
};

export default EditOrganizationForm;
