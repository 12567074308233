import React, { useState, Dispatch, SetStateAction, useEffect } from 'react';
import style from 'assets/styles/selectEngine.module.scss';
import { IconTickDown } from 'assets/icons/svg';
import useOutsideClick from 'hooks/useOutsideClick';
import { useQuery } from '@tanstack/react-query';
import adminTemplatesService from 'services/admin/AdminTemplatesService';
import { App } from 'antd';
import { mcErrorNotification } from 'utils/Notifications';

interface FilterProps {
	label: string;
	setSelEngineID: Dispatch<SetStateAction<number | undefined>>;
}

export const SelectEngineForTemplates: React.FC<FilterProps> = ({
	label,
	setSelEngineID,
}) => {
	const [showDropMenu, setShowDropMenu] = useState(false);
	const [selected, setSelected] = useState(0);
	const ref = useOutsideClick(() => {
		setShowDropMenu(false);
	});
	const { notification } = App.useApp();

	const { data: publishedEnginesForTemplates, error: getEnginesError } =
		useQuery({
			queryKey: ['templates', 'engines'], // TODO: maybe this key should change
			queryFn: () =>
				adminTemplatesService
					.getAllPublishedEnginesForTemplate()
					.then((res) => res.data),
			initialData: [],
		});

	useEffect(() => {
		if (!getEnginesError) return;
		notification.error(
			mcErrorNotification(
				'Error',
				getEnginesError,
				'fetch',
				'engines for template'
			)
		);
	}, [getEnginesError, notification]);

	const values = [
		{
			id: undefined,
			name: 'Show all',
		},
		...publishedEnginesForTemplates,
	];

	const [value, setValue] = useState(values[0].name);

	return (
		<div className={style.wrapper} ref={ref}>
			<div
				className={style.filterField}
				onClick={(e) => {
					e.preventDefault();
					setShowDropMenu((prev) => !prev);
				}}
			>
				<span style={{ color: 'var(--text-normal)' }}>{label}</span>
				<div style={{ color: 'var(--text-primary)' }}>{value}</div>
				{showDropMenu ? (
					<div style={{ transform: 'rotate(180deg)' }}>
						<IconTickDown />
					</div>
				) : (
					<div>
						<IconTickDown />
					</div>
				)}
			</div>
			{showDropMenu && (
				<div className={style.filterFieldDropMenu}>
					{values.map((val, idx) => {
						return (
							<div
								className={
									selected === idx ? style.selected : style.filterFieldDropItem
								}
								key={idx}
								onClick={() => {
									setValue(val.name);
									setShowDropMenu(false);
									setSelected(idx);
									setSelEngineID(val.id);
								}}
							>
								{val.name}
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};
