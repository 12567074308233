import 'assets/styles/layout.scss';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { CustomFooter } from './CustomFooter';
import { CustomHeader } from './CustomHeader';
import { Layout } from 'antd';
import { Sidebar } from './Sidebar';
import { isDesktop } from 'desktop';
import { McIconButton } from 'components/mc';
import { IconClose } from 'assets/icons/svg';
import { downloadDesktopAppUrl } from 'services/ApiService';
import { useSessionSettings } from 'hooks/useLocalSettings';

import LifTemplateModal from './LifTemplateModal';
import SseHandler from './SseHandler';

const { Content } = Layout;

export const AppLayout: FC = () => {
	return (
		<>
			<SseHandler />
			<LifTemplateModal />
			<Layout className="layout-wrapper">
				<div
					className="header-wrapper"
					style={{
						zIndex: 1,
					}}
				>
					<CustomHeader />
				</div>
				<div className="content-wrapper">
					<TopBanner />
					<Layout className="main-wrapper">
						<Sidebar />
						<Content className="layout-mainContent">
							<Outlet />
						</Content>
					</Layout>
				</div>
				{!isDesktop && ( // show footer only on web
					<div className="footer-wrapper">
						<CustomFooter />
					</div>
				)}
			</Layout>
		</>
	);
};

const TopBanner: FC<{}> = () => {
	const [downloadDismissed, setDownloadDismissed] = useSessionSettings(
		'dismissDesktopDownload'
	);

	if (isDesktop || downloadDismissed) {
		return <></>;
	}

	return (
		<div className="top-banner">
			<div className="banner-content">
				<h4>
					To manage your installations of TEMA products, please download and
					install
					<a
						style={{ color: 'var(--text-primary)' }}
						download
						href={downloadDesktopAppUrl}
					>
						Motion Cloud Desktop
					</a>
				</h4>
				<McIconButton
					icon={<IconClose />}
					onClick={() => setDownloadDismissed(true)}
				/>
			</div>
		</div>
	);
};
