/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Motion Cloud
 * API for Motion Cloud web application
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ErrorDTO } from '../model';
import { FullDataLicenseDto } from '../model';
import { LicenseRequestDto } from '../model';
import { LicenseRequestProgressDto } from '../model';
import { PageDtoLicensingRequestListDto } from '../model';
import { PageDtoSubscriptionDto } from '../model';
import { SubscriptionDto } from '../model';
/**
 * SubscriptionsApi - axios parameter creator
 * @export
 */
export const SubscriptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary activates licenses with the License Central API
         * @param {number} subscriptionId 
         * @param {LicenseRequestDto} [licenseRequestDto] 
         * @param {Blob} [requestFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateLicensesForm: async (subscriptionId: number, licenseRequestDto?: LicenseRequestDto, requestFile?: Blob, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionId' is not null or undefined
            if (subscriptionId === null || subscriptionId === undefined) {
                throw new RequiredError('subscriptionId','Required parameter subscriptionId was null or undefined when calling activateLicensesForm.');
            }
            const localVarPath = `/api/subscriptions/{subscriptionId}/requests`
                .replace(`{${"subscriptionId"}}`, encodeURIComponent(String(subscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


            if (licenseRequestDto !== undefined) {
                const licenseRequestDtoValue = typeof licenseRequestDto === 'object' && !(licenseRequestDto instanceof Blob) ? JSON.stringify(licenseRequestDto) : licenseRequestDto;
                localVarFormParams.append('licenseRequestDto', licenseRequestDtoValue as any);
            }

            if (requestFile !== undefined) {
                const requestFileValue = typeof requestFile === 'object' && !(requestFile instanceof Blob) ? JSON.stringify(requestFile) : requestFile;
                localVarFormParams.append('requestFile', requestFileValue as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Completes a license request by uploading a receipt file
         * @param {number} subscriptionId 
         * @param {number} requestId 
         * @param {Blob} [requestFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeRequestForm: async (subscriptionId: number, requestId: number, requestFile?: Blob, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionId' is not null or undefined
            if (subscriptionId === null || subscriptionId === undefined) {
                throw new RequiredError('subscriptionId','Required parameter subscriptionId was null or undefined when calling completeRequestForm.');
            }
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling completeRequestForm.');
            }
            const localVarPath = `/api/subscriptions/{subscriptionId}/requests/{requestId}`
                .replace(`{${"subscriptionId"}}`, encodeURIComponent(String(subscriptionId)))
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


            if (requestFile !== undefined) {
                const requestFileValue = typeof requestFile === 'object' && !(requestFile instanceof Blob) ? JSON.stringify(requestFile) : requestFile;
                localVarFormParams.append('requestFile', requestFileValue as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download a specific license request file
         * @param {number} subscriptionId 
         * @param {number} requestId 
         * @param {string} fileType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadRequestFile: async (subscriptionId: number, requestId: number, fileType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionId' is not null or undefined
            if (subscriptionId === null || subscriptionId === undefined) {
                throw new RequiredError('subscriptionId','Required parameter subscriptionId was null or undefined when calling downloadRequestFile.');
            }
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling downloadRequestFile.');
            }
            // verify required parameter 'fileType' is not null or undefined
            if (fileType === null || fileType === undefined) {
                throw new RequiredError('fileType','Required parameter fileType was null or undefined when calling downloadRequestFile.');
            }
            const localVarPath = `/api/subscriptions/{subscriptionId}/requests/{requestId}/{fileType}`
                .replace(`{${"subscriptionId"}}`, encodeURIComponent(String(subscriptionId)))
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)))
                .replace(`{${"fileType"}}`, encodeURIComponent(String(fileType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all licenses by subscription id
         * @param {number} subscriptionId 
         * @param {boolean} [includeDisabledLicenses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLicenses: async (subscriptionId: number, includeDisabledLicenses?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionId' is not null or undefined
            if (subscriptionId === null || subscriptionId === undefined) {
                throw new RequiredError('subscriptionId','Required parameter subscriptionId was null or undefined when calling getAllLicenses.');
            }
            const localVarPath = `/api/subscriptions/{subscriptionId}/licenses`
                .replace(`{${"subscriptionId"}}`, encodeURIComponent(String(subscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (includeDisabledLicenses !== undefined) {
                localVarQueryParameter['includeDisabledLicenses'] = includeDisabledLicenses;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all licensing requests by subscription id
         * @param {number} subscriptionId 
         * @param {number} page 
         * @param {number} size 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLicensingRequests: async (subscriptionId: number, page: number, size: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionId' is not null or undefined
            if (subscriptionId === null || subscriptionId === undefined) {
                throw new RequiredError('subscriptionId','Required parameter subscriptionId was null or undefined when calling getAllLicensingRequests.');
            }
            // verify required parameter 'page' is not null or undefined
            if (page === null || page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling getAllLicensingRequests.');
            }
            // verify required parameter 'size' is not null or undefined
            if (size === null || size === undefined) {
                throw new RequiredError('size','Required parameter size was null or undefined when calling getAllLicensingRequests.');
            }
            const localVarPath = `/api/subscriptions/{subscriptionId}/licensingRequests`
                .replace(`{${"subscriptionId"}}`, encodeURIComponent(String(subscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of Subscriptions with pagination, searched value and user access
         * @param {number} page 
         * @param {number} size 
         * @param {string} [searchedValue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginatedSubscriptions: async (page: number, size: number, searchedValue?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            if (page === null || page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling getPaginatedSubscriptions.');
            }
            // verify required parameter 'size' is not null or undefined
            if (size === null || size === undefined) {
                throw new RequiredError('size','Required parameter size was null or undefined when calling getPaginatedSubscriptions.');
            }
            const localVarPath = `/api/subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (searchedValue !== undefined) {
                localVarQueryParameter['searchedValue'] = searchedValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Subscription by id
         * @param {number} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionById: async (subscriptionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionId' is not null or undefined
            if (subscriptionId === null || subscriptionId === undefined) {
                throw new RequiredError('subscriptionId','Required parameter subscriptionId was null or undefined when calling getSubscriptionById.');
            }
            const localVarPath = `/api/subscriptions/{subscriptionId}`
                .replace(`{${"subscriptionId"}}`, encodeURIComponent(String(subscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionsApi - functional programming interface
 * @export
 */
export const SubscriptionsApiFp = function(axiosInstance: Promise<AxiosInstance>, configuration?: Configuration) {
    return {
        /**
         * 
         * @summary activates licenses with the License Central API
         * @param {number} subscriptionId 
         * @param {LicenseRequestDto} [licenseRequestDto] 
         * @param {Blob} [requestFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateLicensesForm(subscriptionId: number, licenseRequestDto?: LicenseRequestDto, requestFile?: Blob, options?: AxiosRequestConfig): Promise<AxiosResponse<LicenseRequestProgressDto>> {
            const localVarAxiosArgs = await SubscriptionsApiAxiosParamCreator(configuration).activateLicensesForm(subscriptionId, licenseRequestDto, requestFile, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Completes a license request by uploading a receipt file
         * @param {number} subscriptionId 
         * @param {number} requestId 
         * @param {Blob} [requestFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeRequestForm(subscriptionId: number, requestId: number, requestFile?: Blob, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await SubscriptionsApiAxiosParamCreator(configuration).completeRequestForm(subscriptionId, requestId, requestFile, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Download a specific license request file
         * @param {number} subscriptionId 
         * @param {number} requestId 
         * @param {string} fileType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadRequestFile(subscriptionId: number, requestId: number, fileType: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Blob>> {
            const localVarAxiosArgs = await SubscriptionsApiAxiosParamCreator(configuration).downloadRequestFile(subscriptionId, requestId, fileType, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get all licenses by subscription id
         * @param {number} subscriptionId 
         * @param {boolean} [includeDisabledLicenses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllLicenses(subscriptionId: number, includeDisabledLicenses?: boolean, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<FullDataLicenseDto>>> {
            const localVarAxiosArgs = await SubscriptionsApiAxiosParamCreator(configuration).getAllLicenses(subscriptionId, includeDisabledLicenses, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get all licensing requests by subscription id
         * @param {number} subscriptionId 
         * @param {number} page 
         * @param {number} size 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllLicensingRequests(subscriptionId: number, page: number, size: number, options?: AxiosRequestConfig): Promise<AxiosResponse<PageDtoLicensingRequestListDto>> {
            const localVarAxiosArgs = await SubscriptionsApiAxiosParamCreator(configuration).getAllLicensingRequests(subscriptionId, page, size, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get list of Subscriptions with pagination, searched value and user access
         * @param {number} page 
         * @param {number} size 
         * @param {string} [searchedValue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginatedSubscriptions(page: number, size: number, searchedValue?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<PageDtoSubscriptionDto>> {
            const localVarAxiosArgs = await SubscriptionsApiAxiosParamCreator(configuration).getPaginatedSubscriptions(page, size, searchedValue, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get Subscription by id
         * @param {number} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriptionById(subscriptionId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<SubscriptionDto>> {
            const localVarAxiosArgs = await SubscriptionsApiAxiosParamCreator(configuration).getSubscriptionById(subscriptionId, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
    }
};

/**
 * SubscriptionsApi - factory interface
 * @export
 */
export const SubscriptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary activates licenses with the License Central API
         * @param {number} subscriptionId 
         * @param {LicenseRequestDto} [licenseRequestDto] 
         * @param {Blob} [requestFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateLicensesForm(subscriptionId: number, licenseRequestDto?: LicenseRequestDto, requestFile?: Blob, options?: AxiosRequestConfig): Promise<AxiosResponse<LicenseRequestProgressDto>> {
            return SubscriptionsApiFp(configuration).activateLicensesForm(subscriptionId, licenseRequestDto, requestFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Completes a license request by uploading a receipt file
         * @param {number} subscriptionId 
         * @param {number} requestId 
         * @param {Blob} [requestFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeRequestForm(subscriptionId: number, requestId: number, requestFile?: Blob, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return SubscriptionsApiFp(configuration).completeRequestForm(subscriptionId, requestId, requestFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download a specific license request file
         * @param {number} subscriptionId 
         * @param {number} requestId 
         * @param {string} fileType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadRequestFile(subscriptionId: number, requestId: number, fileType: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Blob>> {
            return SubscriptionsApiFp(configuration).downloadRequestFile(subscriptionId, requestId, fileType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all licenses by subscription id
         * @param {number} subscriptionId 
         * @param {boolean} [includeDisabledLicenses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllLicenses(subscriptionId: number, includeDisabledLicenses?: boolean, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<FullDataLicenseDto>>> {
            return SubscriptionsApiFp(configuration).getAllLicenses(subscriptionId, includeDisabledLicenses, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all licensing requests by subscription id
         * @param {number} subscriptionId 
         * @param {number} page 
         * @param {number} size 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllLicensingRequests(subscriptionId: number, page: number, size: number, options?: AxiosRequestConfig): Promise<AxiosResponse<PageDtoLicensingRequestListDto>> {
            return SubscriptionsApiFp(configuration).getAllLicensingRequests(subscriptionId, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of Subscriptions with pagination, searched value and user access
         * @param {number} page 
         * @param {number} size 
         * @param {string} [searchedValue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginatedSubscriptions(page: number, size: number, searchedValue?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<PageDtoSubscriptionDto>> {
            return SubscriptionsApiFp(configuration).getPaginatedSubscriptions(page, size, searchedValue, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Subscription by id
         * @param {number} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriptionById(subscriptionId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<SubscriptionDto>> {
            return SubscriptionsApiFp(configuration).getSubscriptionById(subscriptionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubscriptionsApi - object-oriented interface
 * @export
 * @class SubscriptionsApi
 * @extends {BaseAPI}
 */
export class SubscriptionsApi extends BaseAPI {
    /**
     * 
     * @summary activates licenses with the License Central API
     * @param {number} subscriptionId 
     * @param {LicenseRequestDto} [licenseRequestDto] 
     * @param {Blob} [requestFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public async activateLicensesForm(subscriptionId: number, licenseRequestDto?: LicenseRequestDto, requestFile?: Blob, options?: AxiosRequestConfig) : Promise<AxiosResponse<LicenseRequestProgressDto>> {
        return SubscriptionsApiFp(this.configuration).activateLicensesForm(subscriptionId, licenseRequestDto, requestFile, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Completes a license request by uploading a receipt file
     * @param {number} subscriptionId 
     * @param {number} requestId 
     * @param {Blob} [requestFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public async completeRequestForm(subscriptionId: number, requestId: number, requestFile?: Blob, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return SubscriptionsApiFp(this.configuration).completeRequestForm(subscriptionId, requestId, requestFile, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Download a specific license request file
     * @param {number} subscriptionId 
     * @param {number} requestId 
     * @param {string} fileType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public async downloadRequestFile(subscriptionId: number, requestId: number, fileType: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Blob>> {
        return SubscriptionsApiFp(this.configuration).downloadRequestFile(subscriptionId, requestId, fileType, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get all licenses by subscription id
     * @param {number} subscriptionId 
     * @param {boolean} [includeDisabledLicenses] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public async getAllLicenses(subscriptionId: number, includeDisabledLicenses?: boolean, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<FullDataLicenseDto>>> {
        return SubscriptionsApiFp(this.configuration).getAllLicenses(subscriptionId, includeDisabledLicenses, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get all licensing requests by subscription id
     * @param {number} subscriptionId 
     * @param {number} page 
     * @param {number} size 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public async getAllLicensingRequests(subscriptionId: number, page: number, size: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<PageDtoLicensingRequestListDto>> {
        return SubscriptionsApiFp(this.configuration).getAllLicensingRequests(subscriptionId, page, size, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get list of Subscriptions with pagination, searched value and user access
     * @param {number} page 
     * @param {number} size 
     * @param {string} [searchedValue] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public async getPaginatedSubscriptions(page: number, size: number, searchedValue?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<PageDtoSubscriptionDto>> {
        return SubscriptionsApiFp(this.configuration).getPaginatedSubscriptions(page, size, searchedValue, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get Subscription by id
     * @param {number} subscriptionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public async getSubscriptionById(subscriptionId: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<SubscriptionDto>> {
        return SubscriptionsApiFp(this.configuration).getSubscriptionById(subscriptionId, options).then((request) => request(this.axios, this.basePath));
    }
}
