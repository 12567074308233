/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Motion Cloud
 * API for Motion Cloud web application
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ApplicationReleaseDto } from '../model';
import { EngineDto } from '../model';
import { ErrorDTO } from '../model';
/**
 * ApplicationReleasesApi - axios parameter creator
 * @export
 */
export const ApplicationReleasesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get application release by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationReleaseById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getApplicationReleaseById.');
            }
            const localVarPath = `/api/applications/releases/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get application releases for the store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationReleasesForStore: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/applications/releases/store`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get application releases to try based on what licenses the user already owns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationReleasesToTry: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/applications/releases/to-try`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get latest published engine for application release
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestEngineForApplicationRelease: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getLatestEngineForApplicationRelease.');
            }
            const localVarPath = `/api/applications/releases/{id}/engine`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationReleasesApi - functional programming interface
 * @export
 */
export const ApplicationReleasesApiFp = function(axiosInstance: Promise<AxiosInstance>, configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get application release by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationReleaseById(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<ApplicationReleaseDto>> {
            const localVarAxiosArgs = await ApplicationReleasesApiAxiosParamCreator(configuration).getApplicationReleaseById(id, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get application releases for the store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationReleasesForStore(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<ApplicationReleaseDto>>> {
            const localVarAxiosArgs = await ApplicationReleasesApiAxiosParamCreator(configuration).getApplicationReleasesForStore(options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get application releases to try based on what licenses the user already owns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationReleasesToTry(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<ApplicationReleaseDto>>> {
            const localVarAxiosArgs = await ApplicationReleasesApiAxiosParamCreator(configuration).getApplicationReleasesToTry(options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get latest published engine for application release
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestEngineForApplicationRelease(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<EngineDto>> {
            const localVarAxiosArgs = await ApplicationReleasesApiAxiosParamCreator(configuration).getLatestEngineForApplicationRelease(id, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
    }
};

/**
 * ApplicationReleasesApi - factory interface
 * @export
 */
export const ApplicationReleasesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get application release by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationReleaseById(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<ApplicationReleaseDto>> {
            return ApplicationReleasesApiFp(configuration).getApplicationReleaseById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get application releases for the store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationReleasesForStore(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<ApplicationReleaseDto>>> {
            return ApplicationReleasesApiFp(configuration).getApplicationReleasesForStore(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get application releases to try based on what licenses the user already owns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationReleasesToTry(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<ApplicationReleaseDto>>> {
            return ApplicationReleasesApiFp(configuration).getApplicationReleasesToTry(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get latest published engine for application release
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestEngineForApplicationRelease(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<EngineDto>> {
            return ApplicationReleasesApiFp(configuration).getLatestEngineForApplicationRelease(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationReleasesApi - object-oriented interface
 * @export
 * @class ApplicationReleasesApi
 * @extends {BaseAPI}
 */
export class ApplicationReleasesApi extends BaseAPI {
    /**
     * 
     * @summary Get application release by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationReleasesApi
     */
    public async getApplicationReleaseById(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<ApplicationReleaseDto>> {
        return ApplicationReleasesApiFp(this.configuration).getApplicationReleaseById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get application releases for the store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationReleasesApi
     */
    public async getApplicationReleasesForStore(options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<ApplicationReleaseDto>>> {
        return ApplicationReleasesApiFp(this.configuration).getApplicationReleasesForStore(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get application releases to try based on what licenses the user already owns
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationReleasesApi
     */
    public async getApplicationReleasesToTry(options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<ApplicationReleaseDto>>> {
        return ApplicationReleasesApiFp(this.configuration).getApplicationReleasesToTry(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get latest published engine for application release
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationReleasesApi
     */
    public async getLatestEngineForApplicationRelease(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<EngineDto>> {
        return ApplicationReleasesApiFp(this.configuration).getLatestEngineForApplicationRelease(id, options).then((request) => request(this.axios, this.basePath));
    }
}
