/* tslint:disable */
/* eslint-disable */
/**
 * Motion Cloud
 * API for Motion Cloud web application
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @enum {string}
 */
export const SupportItemTypeVals = {
    QA: 'QA',
    MANUAL: 'MANUAL',
    TUTORIAL: 'TUTORIAL',
    ADMIN: 'ADMIN'
} as const;

export type SupportItemType = typeof SupportItemTypeVals[keyof typeof SupportItemTypeVals];

