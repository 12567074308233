import React, { useEffect, useMemo } from 'react';
import style from 'assets/styles/selectField.module.scss';
import { SelectField } from './SelectField';
import { getPublicImageURL } from 'services/ApiService';
import { TemaGenericAppIcon } from 'assets/icons/apps';
import { useQuery } from '@tanstack/react-query';
import applicationsService from 'services/ApplicationsService';
import { App } from 'antd';
import { mcErrorNotification } from 'utils/Notifications';

type Props = React.ComponentPropsWithoutRef<typeof SelectField<number>>;

export const SelectApplication: React.FC<Props> = (props) => {
	const { notification } = App.useApp();

	const {
		data: applications,
		isLoading: appsLoading,
		error: getApplicationsError,
	} = useQuery({
		queryKey: ['applications'],
		queryFn: () =>
			applicationsService
				.getApplicationsList(0, 50, undefined)
				.then((res) => res.data.content),
		initialData: [],
	});

	useEffect(() => {
		if (!getApplicationsError) return;
		notification.error(
			mcErrorNotification(
				'Error',
				getApplicationsError,
				'fetch',
				'applications'
			)
		);
	}, [getApplicationsError, notification]);

	const options = useMemo(
		() =>
			(applications ?? []).map((app) => ({
				value: app.id,
				label: (
					<div className={style.dropdownItem} key={app.id}>
						<div className={style.labelIconName}>
							{!app.iconImagePath ? (
								<TemaGenericAppIcon size={32} />
							) : (
								<img
									alt=""
									src={getPublicImageURL(app.iconImagePath)}
									width={32}
									height={32}
								/>
							)}

							<div>{app.name}</div>
						</div>
					</div>
				),
			})),
		[applications]
	);

	return (
		<SelectField
			{...props}
			placeholder={props.placeholder ?? 'Select an application'}
			options={options}
			loading={appsLoading}
			error={props.error}
		/>
	);
};
