import React, { useCallback, useEffect, useState } from 'react';
import CookieConsent from 'react-cookie-consent';
import style from 'assets/styles/cookies.module.scss';
import { McLogoIcon } from 'assets/icons/svg';
import ReactGA from 'react-ga4';
import Cookies from 'js-cookie';
import { isDesktop } from 'desktop';
import CookiePolicy from './CookiePolicy';

export const cookie_consent_name = 'mc_cookie_consent';

const CookiesConsent = () => {
	const [analyticsInitialized, setAnalyticsInitialized] = useState(false);
	const [policyOpen, setPolicyOpen] = useState<boolean>(false);

	const initializeAnalytics = useCallback(() => {
		if (analyticsInitialized) return;

		// Initialize Google Analytics
		ReactGA.initialize('G-NP9HZ5YSNP');

		// Optionally send an initial pageview
		ReactGA.send({ hitType: 'pageview', page: window.location.pathname });

		setAnalyticsInitialized(true); // Prevent re-initialization
	}, [analyticsInitialized]);

	useEffect(() => {
		// Check if the user has already accepted cookies (stored as a cookie)
		const consentGiven = Cookies.get(cookie_consent_name) === 'true';

		if (consentGiven || isDesktop) {
			initializeAnalytics();
		}
	}, [initializeAnalytics]);

	useEffect(() => {
		const interval = setInterval(() => {
			const consentGiven = Cookies.get(cookie_consent_name) === 'true';
			if (!consentGiven && !isDesktop) {
				ReactGA.reset();
				deleteGoogleAnalyticsCookies();
				setAnalyticsInitialized(false);
			}
		}, 60000);

		return () => clearInterval(interval);
	}, [analyticsInitialized]);

	const deleteGoogleAnalyticsCookies = () => {
		try {
			const allCookies = document.cookie.split('; ');
			allCookies.forEach((cookie) => {
				const cookieName = cookie.split('=')[0];
				if (
					cookieName.startsWith('_ga') ||
					cookieName.startsWith('_gid') ||
					cookieName.startsWith('_gat')
				) {
					// Delete cookie by setting it to expired
					document.cookie = `${cookieName}=; Max-Age=-99999999; path=/; SameSite=None; Secure`;
				}
			});
		} catch (e: any) {
			console.error('Failed to remove analytics cookies');
		}
	};

	return !isDesktop ? (
		<>
			<CookiePolicy
				isOpen={policyOpen}
				onClose={() => {
					setPolicyOpen(false);
				}}
			/>
			<CookieConsent
				location="bottom"
				buttonText="Accept All"
				declineButtonText="Only Essential"
				cookieName={cookie_consent_name}
				enableDeclineButton
				containerClasses={style.container}
				expires={150}
				overlay
				flipButtons
				buttonClasses={`${style.button} ${style.acceptButton}`}
				declineButtonClasses={`${style.button} ${style.declineButton}`}
				contentClasses={style.content}
				onAccept={() => {
					initializeAnalytics();
				}}
				onDecline={() => {
					deleteGoogleAnalyticsCookies();
				}}
			>
				<div className={style.contentTitle}>
					<McLogoIcon />
					<h2>This website uses cookies to enhance the user experience.</h2>
				</div>
				<p className={style.contentParagraph}>
					To provide you with the best experience on our website, we use
					cookies. Cookies help us analyze website traffic and improve our
					services. By continuing to use the site, you agree to our use of
					cookies (
					<i
						style={{
							display: 'inline',
							color: 'var(--primary)',
							cursor: 'help',
						}}
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							setPolicyOpen(true);
						}}
					>
						Cookie Policy
					</i>
					).
				</p>
			</CookieConsent>
		</>
	) : (
		<></>
	);
};

export default CookiesConsent;
