import { useState, FC, useEffect, useMemo } from 'react';
import { RecentFileCard, TemplateCard } from 'components/cards';
import { FilterField } from 'components/fields';
import { SelectEngineForTemplates } from 'components/selects';
import { App, Spin } from 'antd';
import { AlertCircle } from 'assets/icons/svg';
import style from 'assets/styles/templatePage.module.scss';
import { SessionFile, isDesktop, useDesktopContext } from 'desktop';
import { Section } from 'components/Section';
import { AppIcon } from 'assets/icons/apps';
import { timeAgo } from 'utils';
import { McButton } from 'components/mc';
import { TemplateDto } from 'api';
import { useInfiniteQuery } from '@tanstack/react-query';
import { mcErrorNotification } from 'utils/Notifications';
import { useAppSelector } from 'hooks/hooks';
import { authState } from 'store/slices/auth';
import adminTemplatesService from 'services/admin/AdminTemplatesService';

type SortOrder = 'BY_ALPHABET_ASC' | 'BY_ALPHABET_DESC';

export const TemplatesPage: FC = () => {
	const [sortOrder, setSortOrder] = useState<SortOrder>('BY_ALPHABET_ASC');
	const [selEngineID, setSelEngineID] = useState<number>();
	const PAGE_SIZE = 12;
	const { notification } = App.useApp();
	const { userTemplates, recentTemplates } = useDesktopContext();
	const { isGuest } = useAppSelector(authState);

	const {
		data: templatePages,
		error: getTemplatesError,
		hasNextPage,
		isLoading,
		isFetching,
		fetchNextPage,
	} = useInfiniteQuery({
		queryKey: ['templates', 'infinite', sortOrder, selEngineID],
		queryFn: ({ pageParam: pageNr }) =>
			adminTemplatesService
				.getPublishedTemplatesList(pageNr, PAGE_SIZE, sortOrder, selEngineID)
				.then((res) => res.data),
		initialPageParam: 0,
		getNextPageParam: (lastPage) => {
			const nextPageNr = lastPage.pageNumber + 1;
			return nextPageNr < lastPage.allPages ? nextPageNr : undefined;
		},
		enabled: !isGuest,
	});

	const templates: TemplateDto[] = useMemo(
		() =>
			!!templatePages
				? templatePages.pages.reduce(
						(acc, page) => [...acc, ...page.content],
						[] as TemplateDto[]
				  )
				: [],
		[templatePages]
	);

	const allTemplatesCount = !!templatePages
		? templatePages.pages[0].allElements
		: 0;

	useEffect(() => {
		if (!getTemplatesError) return;
		notification.warning(
			mcErrorNotification('Warning', getTemplatesError, 'fetch', 'templates')
		);
	}, [getTemplatesError, notification]);

	return (
		<div className={style.wrapper}>
			<h1 className={style.title}>Explore Templates</h1>
			<div className={style.templatesSection}>
				{isDesktop && (
					<>
						<Section title="Recently used" row style={{ marginTop: '0' }}>
							{!recentTemplates.length ? (
								<div className={style.noElementsInfo}>
									<AlertCircle />
									There are currently no recently saved Templates to show here.
									Start using Templates and the recently used ones will show
									here
								</div>
							) : (
								<div className={style.allTemplatesContainer}>
									{recentTemplates &&
										recentTemplates.map((item: SessionFile, idx: number) => (
											<div className={style.cardWrapper} key={idx}>
												<RecentFileCard
													selectedEngine={undefined} // TODO: fix
													recentFile={{
														image: <AppIcon size={24} app={item.app_id} />,
														name: `${item.name}.${item.extension}`,
														timeStamp: !!item.last_modified
															? timeAgo(item.last_modified)
															: '',
														fileSource: 'TEMPLATE',
													}}
												/>
											</div>
										))}
								</div>
							)}
						</Section>
						<Section title="User Templates" row style={{ marginTop: '0' }}>
							{!userTemplates.length ? (
								<div className={style.noElementsInfo}>
									<AlertCircle />
									No user templates created yet
								</div>
							) : (
								<div className={style.allTemplatesContainer}>
									{userTemplates &&
										userTemplates.map((item: SessionFile, idx: number) => (
											<div className={style.cardWrapper} key={idx}>
												<RecentFileCard
													selectedEngine={undefined} // TODO: fix
													recentFile={{
														image: <AppIcon size={24} app={item.app_id} />,
														name: `${item.name}.${item.extension}`,
														timeStamp: !!item.last_modified
															? timeAgo(item.last_modified)
															: '',
														fileSource: 'TEMPLATE',
													}}
												/>
											</div>
										))}
								</div>
							)}
						</Section>
					</>
				)}
				<div className={style.titleRow}>
					<h3 className={style.subTitle}>Factory Templates</h3>
					<div>
						<SelectEngineForTemplates
							label={'Engine: '}
							setSelEngineID={setSelEngineID}
						/>
					</div>
					<div>
						<FilterField
							label={'Sort: '}
							selValue={sortOrder}
							font={'1rem'}
							labels={['Ascending', 'Descending']}
							values={['BY_ALPHABET_ASC', 'BY_ALPHABET_DESC']}
							setSelectedField={setSortOrder}
						/>
					</div>
				</div>
				<div>
					<Spin spinning={isLoading} size="large">
						{!isLoading &&
							!isFetching &&
							allTemplatesCount === 0 &&
							selEngineID !== -1 && (
								<>
									<div className={style.noElementsInfo}>
										There are no Factory Templates to show here
										<AlertCircle />
									</div>
								</>
							)}
						{allTemplatesCount > 0 && (
							<div className={style.allTemplatesContainer}>
								{templates.map((template: TemplateDto) => (
									<div className={style.cardWrapper} key={template.id}>
										<TemplateCard template={template} />
									</div>
								))}
							</div>
						)}
					</Spin>
					{hasNextPage && (
						<div
							className={style.paginationContainer}
							onClick={() => fetchNextPage()}
						>
							<McButton disabled={isLoading || isFetching}>View more</McButton>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
