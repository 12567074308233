import { createSlice, createAction, isAnyOf } from '@reduxjs/toolkit';
import { ErrorDTO, NewsDto } from 'api';
import { newsService } from 'services/NewsService';
import adminNewsService from 'services/admin/AdminNewsService';

import { RootState } from 'store';
import { getErrorMessageAnd, rejectionError } from 'utils/errors';
import { createAppAsyncThunk, setLoadingState } from 'utils/rtk';

export interface NewsState {
	news: NewsDto & { id: number };
	loading: boolean;
	error: ErrorDTO | null;
}

const initialState: NewsState = {
	news: {} as NewsDto & { id: number },
	loading: true,
	error: null,
};

export const deleteNews = createAppAsyncThunk(
	'oneNews/deleteNews',
	({ id }: { id: number }, { rejectWithValue }) =>
		adminNewsService
			.deleteNewsItemById(id)
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const deleteNewsPicture = createAppAsyncThunk(
	'oneNews/deleteNewsPicture',
	({ id }: { id: number }, { rejectWithValue }) =>
		adminNewsService
			.deleteNewsItemPicture(id)
			.catch(getErrorMessageAnd(rejectWithValue))
);

type CreateNewsParams = Parameters<typeof adminNewsService.addNewItemForm>;
export const addNews = createAppAsyncThunk(
	'oneNews/addNews',
	(params: CreateNewsParams, { rejectWithValue }) =>
		adminNewsService
			.addNewItemForm(...params)
			.catch(getErrorMessageAnd(rejectWithValue))
);

type UpdateNewsParams = Parameters<typeof adminNewsService.updateNewsItemForm>;

export const updateNews = createAppAsyncThunk(
	'oneNews/updateNews',
	([id, dto, imageFull, imageSmall]: UpdateNewsParams, { rejectWithValue }) =>
		adminNewsService
			.updateNewsItemForm(id, dto, imageFull, imageSmall)
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const getOneNews = createAppAsyncThunk(
	'oneNews/getOneNews',
	(id: number, { rejectWithValue }) =>
		newsService.getNewsItemById(id).catch(getErrorMessageAnd(rejectWithValue))
);

export const resetState = createAction('RESET');

const oneNewsSlice = createSlice({
	name: 'oneNews',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(addNews.pending, setLoadingState)
			.addCase(addNews.fulfilled, (state) => {
				state.loading = false;
				state.error = null;
			})

			.addCase(updateNews.pending, setLoadingState)
			.addCase(updateNews.fulfilled, (state) => {
				state.loading = false;
				state.error = null;
			})

			.addCase(deleteNews.pending, setLoadingState)
			.addCase(deleteNews.fulfilled, (state) => {
				state.loading = false;
				state.news = {} as NewsDto & { id: number };
				state.error = null;
			})

			.addCase(deleteNewsPicture.pending, setLoadingState)
			.addCase(deleteNewsPicture.fulfilled, (state) => {
				state.loading = false;
				state.error = null;
			})

			.addCase(getOneNews.pending, setLoadingState)
			.addCase(getOneNews.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.news = { ...payload.data, id: payload.data.id ?? -1 };
			})

			.addCase(resetState, () => {
				return initialState;
			})

			.addMatcher(
				isAnyOf(
					addNews.rejected,
					updateNews.rejected,
					deleteNews.rejected,
					deleteNewsPicture.rejected,
					getOneNews.rejected
				),
				(state, action) => {
					state.error = action.payload ?? rejectionError;
					state.loading = false;
				}
			);
	},
});

export const oneNewsState = (state: RootState) => state.oneNews;

export default oneNewsSlice.reducer;
