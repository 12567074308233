import { useEffect, useState, useRef, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AlertCircle, ArrowLeftIcon } from 'assets/icons/svg';
import { useAppDispatch } from 'hooks/hooks';
import { NotFoundElement } from 'components/NotFoundElement';
import { getTemplateFileName } from 'store/slices/oneTemplate';
import { MotionButton } from 'components/MotionButton';
import { getDownloadTemplateUrl, getPublicImageURL } from 'services/ApiService';
import style from 'assets/styles/oneElementPage.module.scss';
import { App, Spin } from 'antd';
import {
	fileDownloadedNotification,
	mcErrorNotification,
} from 'utils/Notifications';
import applicationsService from 'services/ApplicationsService';
import { useQuery } from '@tanstack/react-query';
import appReleasesService from 'services/AppReleasesService';
import defaultImage from 'assets/images/top.jpg';
import adminTemplatesService from 'services/admin/AdminTemplatesService';

export const OneTemplate = () => {
	const dispatch = useAppDispatch();
	let navigate = useNavigate();
	const { id: idString } = useParams();
	const id = Number(idString);
	const [templateFileUrl, setTemplateFileUrl] = useState<string | undefined>(
		undefined
	);
	const downloadRef = useRef<HTMLAnchorElement>(null);
	const { notification } = App.useApp();

	const {
		data: template,
		isLoading: isLoadingTemplate,
		error: getTemplateError,
	} = useQuery({
		queryKey: ['templates', id],
		queryFn: () =>
			adminTemplatesService.getTemplatesItemById(id).then((res) => res.data),
		enabled: id !== undefined,
		initialData: undefined,
	});

	const {
		data: applicationRelease,
		isLoading: isLoadingApplicationRelease,
		error: getApplicationReleaseError,
	} = useQuery({
		queryKey: ['applications', 'releases', template?.applicationReleaseId],
		queryFn: () =>
			appReleasesService
				.getApplicationReleaseById(template!.applicationReleaseId!)
				.then((res) => res.data),
		initialData: undefined,
		enabled:
			!!template &&
			template.applicationReleaseId !== undefined &&
			template.applicationReleaseId !== null,
	});

	const {
		data: applications,
		isLoading: isLoadingApplications,
		error: getApplicationsError,
	} = useQuery({
		queryKey: ['applications'],
		queryFn: () =>
			applicationsService
				.getApplicationsList(0, 50)
				.then((res) => res.data.content),
		initialData: [],
	});

	useEffect(() => {
		if (!getApplicationsError) return;
		notification.warning(
			mcErrorNotification(
				'Warning',
				getApplicationsError,
				'fetch',
				'applications'
			)
		);
	}, [getApplicationsError, notification]);

	useEffect(() => {
		if (!getApplicationReleaseError) return;
		notification.warning(
			mcErrorNotification(
				'Warning',
				getApplicationReleaseError,
				'fetch',
				'application release'
			)
		);
	}, [getApplicationReleaseError, notification]);

	const handleBackBtn = () => {
		navigate(-1);
	};

	useEffect(() => {
		if (templateFileUrl) {
			downloadRef?.current?.click();
			setTemplateFileUrl(undefined);
		}
	}, [templateFileUrl]);

	const downloadTemplateHandler = () => {
		if (!!template && template.id) {
			dispatch(getTemplateFileName(id))
				.unwrap()
				.then(() => {
					const url = getDownloadTemplateUrl(id);
					setTemplateFileUrl(url);
					notification.success(fileDownloadedNotification());
				})
				.catch((err) => {
					notification.error(
						mcErrorNotification('Error', undefined, 'download', 'template')
					);
				});
		}
	};

	const selectedApplication = useMemo(
		() =>
			applications.find(
				(application) => application.id === applicationRelease?.applicationId
			),
		[applications, applicationRelease]
	);

	const isLoading =
		isLoadingApplicationRelease || isLoadingApplications || isLoadingTemplate;

	return getTemplateError ? (
		<NotFoundElement element={'template'} />
	) : (
		<Spin spinning={isLoading}>
			{!!template && (
				<div className={style.container}>
					<>
						<div className={style.header}>
							<h1 className={style.templateTitle}>{template.title}</h1>
							<div className={style.btnContainer}>
								<div className={style.version}>
									<div
										style={{
											transform: 'scale(0.85)',
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<AlertCircle />
									</div>
									{selectedApplication?.name ?? ''} -{' '}
									{applicationRelease?.version}
								</div>
								<MotionButton
									type="primary"
									shape="round"
									size="large"
									onClick={downloadTemplateHandler}
								>
									Download
								</MotionButton>
								<a
									style={{ display: 'none' }}
									download
									href={templateFileUrl}
									ref={downloadRef}
								>
									Download
								</a>
							</div>
						</div>
						<div className={style.imgContainer}>
							<img
								alt={template.title || 'Template thumbnail'}
								src={
									template.fullSizeImagePath
										? getPublicImageURL(template.fullSizeImagePath)
										: defaultImage
								}
								onError={(e) => {
									e.currentTarget.src = defaultImage;
								}}
							/>
						</div>
						<div className={style.descriptionContainer}>
							<h2 className={style.descriptionTitle}>Description</h2>
							<h4 className={style.description}>{template.description}</h4>
						</div>
						<h4 className={style.backButton} onClick={handleBackBtn}>
							<ArrowLeftIcon />
							<span>Back</span>
						</h4>
					</>
				</div>
			)}
		</Spin>
	);
};
