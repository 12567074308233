import React, { Dispatch, SetStateAction, useContext } from 'react';
import { useState } from 'react';
import { PropsWithChildren } from 'react';

type SetSettingDispatch = <K extends keyof SettingsState>(
	key: K,
	value: SetStateAction<SettingsState[K]>
) => void;

export const SettingsContext = React.createContext<
	[SettingsState, SetSettingDispatch]
>([{} as any, () => {}]);
export const useSettingsContext = () => useContext(SettingsContext);

type SettingsNamespace = 'auth' | 'pref' | 'home' | 'desktop';

interface SettingConfig<T> {
	default: T;
	storage: Storage;
	namespace: SettingsNamespace;
}
const Settings = {
	isGuest: {
		default: false as boolean,
		storage: localStorage,
		namespace: 'auth',
	},
	accessToken: {
		default: null as string | null,
		storage: localStorage,
		namespace: 'auth',
	},
	refreshToken: {
		default: null as string | null,
		storage: localStorage,
		namespace: 'auth',
	},
	apiSession: {
		default: null as string | null,
		storage: localStorage,
		namespace: 'auth',
	},
	lightMode: {
		default: null as boolean | null,
		storage: localStorage,
		namespace: 'pref',
	},
	dismissDesktopDownload: {
		default: false as boolean,
		storage: sessionStorage,
		namespace: 'home',
	},
	dismissIntroductionTour: {
		default: false as boolean,
		storage: localStorage,
		namespace: 'home',
	},
	redirectAfterLogin: {
		default: null as string | null,
		storage: sessionStorage,
		namespace: 'auth',
	},
	engineSelected: {
		default: null as string | null,
		storage: localStorage,
		namespace: 'home',
	},
	desktopUpdateDismiss: {
		default: false as boolean,
		storage: localStorage,
		namespace: 'desktop',
	},
} satisfies Record<string, SettingConfig<string | boolean | number | null>>;

type SettingsConfig = typeof Settings;
export type SettingKey = keyof SettingsConfig;
export type SettingType<K extends keyof SettingsConfig> =
	SettingsConfig[K]['default'];

type SettingsState = { [P in SettingKey]: SettingsConfig[P]['default'] };

const storageKey = <K extends SettingKey>(key: K) =>
	`${Settings[key].namespace}:${key}`;

const readSetting = <K extends keyof SettingsConfig>(
	key: K
): SettingType<K> => {
	const setting = Settings[key];
	try {
		const item = setting.storage.getItem(storageKey(key));
		return item && item !== 'undefined' ? JSON.parse(item) : setting.default;
	} catch (error) {
		console.warn(
			`[Settings] Failed to parse JSON value for key %o: ${error}`,
			key
		);
		return setting.default;
	}
};

export const SettingsProvider: React.FC<PropsWithChildren<{}>> = ({
	children,
}) => {
	const [settingsState, setSettingsState] = useState(() => {
		const state = {} as SettingsState;
		for (const key of Object.keys(Settings) as SettingKey[]) {
			(state[key] as any) = readSetting(key);
		}
		return state;
	});

	const setSetting: SetSettingDispatch = (key, valueOrFunc) => {
		try {
			// Handle function to make us compatible with useState
			const value =
				valueOrFunc instanceof Function
					? valueOrFunc(settingsState[key])
					: valueOrFunc;
			Settings[key].storage.setItem(storageKey(key), JSON.stringify(value));
			console.log('SetValue %o to %o', key, value);
			setSettingsState((s) => ({ ...s, [key]: value }));
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<SettingsContext.Provider value={[settingsState, setSetting]}>
			{children}
		</SettingsContext.Provider>
	);
};

export const useLocalSettings = <K extends SettingKey>(key: K) => {
	const [settings, setSetting] = useSettingsContext();
	return [
		settings[key],
		(value: SettingsState[K]) => setSetting(key, value),
	] as [SettingsState[K], Dispatch<SettingsState[K]>];
};

export const getLocalSetting = <K extends SettingKey>(key: K): SettingType<K> =>
	readSetting(key);

export const setLocalSetting = <K extends SettingKey>(
	key: K,
	value: SettingType<K>
) => {
	const storeValue = JSON.stringify(value);
	if (storeValue !== window.localStorage.getItem(key)) {
		console.debug('[Settings] Value for %o changed to: %o', key, value);
		Settings[key].storage.setItem(storageKey(key), storeValue);
	}
};
