/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Motion Cloud
 * API for Motion Cloud web application
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { AddTemplateItemDto } from '../model';
import { ApplicationReleaseDto } from '../model';
import { EntityStatus } from '../model';
import { ErrorDTO } from '../model';
import { PageDtoTemplateDto } from '../model';
import { PublishedEngineForTemplatesDto } from '../model';
import { TemplateDto } from '../model';
/**
 * AdminTemplatesApi - axios parameter creator
 * @export
 */
export const AdminTemplatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add template item
         * @param {AddTemplateItemDto} [templateItemDto] 
         * @param {Blob} [imageFullSize] 
         * @param {Blob} [imageSmallSize] 
         * @param {Blob} [templatesFile] 
         * @param {boolean} [templatesFilePresent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTemplateItemForm: async (templateItemDto?: AddTemplateItemDto, imageFullSize?: Blob, imageSmallSize?: Blob, templatesFile?: Blob, templatesFilePresent?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


            if (templateItemDto !== undefined) {
                const templateItemDtoValue = typeof templateItemDto === 'object' && !(templateItemDto instanceof Blob) ? JSON.stringify(templateItemDto) : templateItemDto;
                localVarFormParams.append('templateItemDto', templateItemDtoValue as any);
            }

            if (imageFullSize !== undefined) {
                const imageFullSizeValue = typeof imageFullSize === 'object' && !(imageFullSize instanceof Blob) ? JSON.stringify(imageFullSize) : imageFullSize;
                localVarFormParams.append('imageFullSize', imageFullSizeValue as any);
            }

            if (imageSmallSize !== undefined) {
                const imageSmallSizeValue = typeof imageSmallSize === 'object' && !(imageSmallSize instanceof Blob) ? JSON.stringify(imageSmallSize) : imageSmallSize;
                localVarFormParams.append('imageSmallSize', imageSmallSizeValue as any);
            }

            if (templatesFile !== undefined) {
                const templatesFileValue = typeof templatesFile === 'object' && !(templatesFile instanceof Blob) ? JSON.stringify(templatesFile) : templatesFile;
                localVarFormParams.append('templatesFile', templatesFileValue as any);
            }

            if (templatesFilePresent !== undefined) {
                const templatesFilePresentValue = typeof templatesFilePresent === 'object' && !(templatesFilePresent instanceof Blob) ? JSON.stringify(templatesFilePresent) : templatesFilePresent;
                localVarFormParams.append('templatesFilePresent', templatesFilePresentValue as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete template item by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemplateItemById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteTemplateItemById.');
            }
            const localVarPath = `/api/admin/templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete templates file by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemplatesFile: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteTemplatesFile.');
            }
            const localVarPath = `/api/admin/templates/{id}/file`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete templates avatar picture by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemplatesItemBasicPicture: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteTemplatesItemBasicPicture.');
            }
            const localVarPath = `/api/admin/templates/{id}/picture`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of applications for template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllApplicationsForTemplate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/templates/applications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get published engines for concrete template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPublishedEnginesForTemplate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/templates/published/engines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get published templates list
         * @param {number} page 
         * @param {number} size 
         * @param {string} [sortOrder] 
         * @param {number} [enginesId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublishedTemplatesList: async (page: number, size: number, sortOrder?: string, enginesId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            if (page === null || page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling getPublishedTemplatesList.');
            }
            // verify required parameter 'size' is not null or undefined
            if (size === null || size === undefined) {
                throw new RequiredError('size','Required parameter size was null or undefined when calling getPublishedTemplatesList.');
            }
            const localVarPath = `/api/admin/templates/published`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (enginesId !== undefined) {
                localVarQueryParameter['enginesId'] = enginesId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get templates file name by id
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateFileName: async (templateId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            if (templateId === null || templateId === undefined) {
                throw new RequiredError('templateId','Required parameter templateId was null or undefined when calling getTemplateFileName.');
            }
            const localVarPath = `/api/admin/templates/{templateId}/file/name`
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download templates file by id
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplatesFile: async (templateId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            if (templateId === null || templateId === undefined) {
                throw new RequiredError('templateId','Required parameter templateId was null or undefined when calling getTemplatesFile.');
            }
            const localVarPath = `/api/admin/templates/{templateId}/file`
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Template item by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplatesItemById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getTemplatesItemById.');
            }
            const localVarPath = `/api/admin/templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get templates list with pagination and filtering by status and key value
         * @param {number} page 
         * @param {number} size 
         * @param {string} [searchedValue] 
         * @param {EntityStatus} [entityStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplatesList: async (page: number, size: number, searchedValue?: string, entityStatus?: EntityStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            if (page === null || page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling getTemplatesList.');
            }
            // verify required parameter 'size' is not null or undefined
            if (size === null || size === undefined) {
                throw new RequiredError('size','Required parameter size was null or undefined when calling getTemplatesList.');
            }
            const localVarPath = `/api/admin/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (searchedValue !== undefined) {
                localVarQueryParameter['searchedValue'] = searchedValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (entityStatus !== undefined) {
                localVarQueryParameter['entityStatus'] = entityStatus;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update template item by id
         * @param {number} id 
         * @param {TemplateDto} [dto] 
         * @param {Blob} [imageFullSize] 
         * @param {Blob} [imageSmallSize] 
         * @param {Blob} [templatesFile] 
         * @param {boolean} [templatesFilePresent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTemplateItemForm: async (id: number, dto?: TemplateDto, imageFullSize?: Blob, imageSmallSize?: Blob, templatesFile?: Blob, templatesFilePresent?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateTemplateItemForm.');
            }
            const localVarPath = `/api/admin/templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


            if (dto !== undefined) {
                const dtoValue = typeof dto === 'object' && !(dto instanceof Blob) ? JSON.stringify(dto) : dto;
                localVarFormParams.append('dto', dtoValue as any);
            }

            if (imageFullSize !== undefined) {
                const imageFullSizeValue = typeof imageFullSize === 'object' && !(imageFullSize instanceof Blob) ? JSON.stringify(imageFullSize) : imageFullSize;
                localVarFormParams.append('imageFullSize', imageFullSizeValue as any);
            }

            if (imageSmallSize !== undefined) {
                const imageSmallSizeValue = typeof imageSmallSize === 'object' && !(imageSmallSize instanceof Blob) ? JSON.stringify(imageSmallSize) : imageSmallSize;
                localVarFormParams.append('imageSmallSize', imageSmallSizeValue as any);
            }

            if (templatesFile !== undefined) {
                const templatesFileValue = typeof templatesFile === 'object' && !(templatesFile instanceof Blob) ? JSON.stringify(templatesFile) : templatesFile;
                localVarFormParams.append('templatesFile', templatesFileValue as any);
            }

            if (templatesFilePresent !== undefined) {
                const templatesFilePresentValue = typeof templatesFilePresent === 'object' && !(templatesFilePresent instanceof Blob) ? JSON.stringify(templatesFilePresent) : templatesFilePresent;
                localVarFormParams.append('templatesFilePresent', templatesFilePresentValue as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload templates file by id
         * @param {number} templatesId 
         * @param {Blob} [templatesFile] 
         * @param {boolean} [templatePresent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadTemplateForm: async (templatesId: number, templatesFile?: Blob, templatePresent?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templatesId' is not null or undefined
            if (templatesId === null || templatesId === undefined) {
                throw new RequiredError('templatesId','Required parameter templatesId was null or undefined when calling uploadTemplateForm.');
            }
            const localVarPath = `/api/admin/templates/{templatesId}/file`
                .replace(`{${"templatesId"}}`, encodeURIComponent(String(templatesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


            if (templatesFile !== undefined) {
                const templatesFileValue = typeof templatesFile === 'object' && !(templatesFile instanceof Blob) ? JSON.stringify(templatesFile) : templatesFile;
                localVarFormParams.append('templatesFile', templatesFileValue as any);
            }

            if (templatePresent !== undefined) {
                const templatePresentValue = typeof templatePresent === 'object' && !(templatePresent instanceof Blob) ? JSON.stringify(templatePresent) : templatePresent;
                localVarFormParams.append('templatePresent', templatePresentValue as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminTemplatesApi - functional programming interface
 * @export
 */
export const AdminTemplatesApiFp = function(axiosInstance: Promise<AxiosInstance>, configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add template item
         * @param {AddTemplateItemDto} [templateItemDto] 
         * @param {Blob} [imageFullSize] 
         * @param {Blob} [imageSmallSize] 
         * @param {Blob} [templatesFile] 
         * @param {boolean} [templatesFilePresent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTemplateItemForm(templateItemDto?: AddTemplateItemDto, imageFullSize?: Blob, imageSmallSize?: Blob, templatesFile?: Blob, templatesFilePresent?: boolean, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await AdminTemplatesApiAxiosParamCreator(configuration).addTemplateItemForm(templateItemDto, imageFullSize, imageSmallSize, templatesFile, templatesFilePresent, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Delete template item by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTemplateItemById(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await AdminTemplatesApiAxiosParamCreator(configuration).deleteTemplateItemById(id, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Delete templates file by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTemplatesFile(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await AdminTemplatesApiAxiosParamCreator(configuration).deleteTemplatesFile(id, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Delete templates avatar picture by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTemplatesItemBasicPicture(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await AdminTemplatesApiAxiosParamCreator(configuration).deleteTemplatesItemBasicPicture(id, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get list of applications for template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllApplicationsForTemplate(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<ApplicationReleaseDto>>> {
            const localVarAxiosArgs = await AdminTemplatesApiAxiosParamCreator(configuration).getAllApplicationsForTemplate(options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get published engines for concrete template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPublishedEnginesForTemplate(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<PublishedEngineForTemplatesDto>>> {
            const localVarAxiosArgs = await AdminTemplatesApiAxiosParamCreator(configuration).getAllPublishedEnginesForTemplate(options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get published templates list
         * @param {number} page 
         * @param {number} size 
         * @param {string} [sortOrder] 
         * @param {number} [enginesId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublishedTemplatesList(page: number, size: number, sortOrder?: string, enginesId?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<PageDtoTemplateDto>> {
            const localVarAxiosArgs = await AdminTemplatesApiAxiosParamCreator(configuration).getPublishedTemplatesList(page, size, sortOrder, enginesId, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get templates file name by id
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemplateFileName(templateId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> {
            const localVarAxiosArgs = await AdminTemplatesApiAxiosParamCreator(configuration).getTemplateFileName(templateId, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Download templates file by id
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemplatesFile(templateId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<Blob>> {
            const localVarAxiosArgs = await AdminTemplatesApiAxiosParamCreator(configuration).getTemplatesFile(templateId, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get Template item by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemplatesItemById(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<TemplateDto>> {
            const localVarAxiosArgs = await AdminTemplatesApiAxiosParamCreator(configuration).getTemplatesItemById(id, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get templates list with pagination and filtering by status and key value
         * @param {number} page 
         * @param {number} size 
         * @param {string} [searchedValue] 
         * @param {EntityStatus} [entityStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemplatesList(page: number, size: number, searchedValue?: string, entityStatus?: EntityStatus, options?: AxiosRequestConfig): Promise<AxiosResponse<PageDtoTemplateDto>> {
            const localVarAxiosArgs = await AdminTemplatesApiAxiosParamCreator(configuration).getTemplatesList(page, size, searchedValue, entityStatus, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Update template item by id
         * @param {number} id 
         * @param {TemplateDto} [dto] 
         * @param {Blob} [imageFullSize] 
         * @param {Blob} [imageSmallSize] 
         * @param {Blob} [templatesFile] 
         * @param {boolean} [templatesFilePresent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTemplateItemForm(id: number, dto?: TemplateDto, imageFullSize?: Blob, imageSmallSize?: Blob, templatesFile?: Blob, templatesFilePresent?: boolean, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await AdminTemplatesApiAxiosParamCreator(configuration).updateTemplateItemForm(id, dto, imageFullSize, imageSmallSize, templatesFile, templatesFilePresent, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Upload templates file by id
         * @param {number} templatesId 
         * @param {Blob} [templatesFile] 
         * @param {boolean} [templatePresent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadTemplateForm(templatesId: number, templatesFile?: Blob, templatePresent?: boolean, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await AdminTemplatesApiAxiosParamCreator(configuration).uploadTemplateForm(templatesId, templatesFile, templatePresent, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
    }
};

/**
 * AdminTemplatesApi - factory interface
 * @export
 */
export const AdminTemplatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Add template item
         * @param {AddTemplateItemDto} [templateItemDto] 
         * @param {Blob} [imageFullSize] 
         * @param {Blob} [imageSmallSize] 
         * @param {Blob} [templatesFile] 
         * @param {boolean} [templatesFilePresent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTemplateItemForm(templateItemDto?: AddTemplateItemDto, imageFullSize?: Blob, imageSmallSize?: Blob, templatesFile?: Blob, templatesFilePresent?: boolean, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AdminTemplatesApiFp(configuration).addTemplateItemForm(templateItemDto, imageFullSize, imageSmallSize, templatesFile, templatesFilePresent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete template item by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTemplateItemById(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AdminTemplatesApiFp(configuration).deleteTemplateItemById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete templates file by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTemplatesFile(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AdminTemplatesApiFp(configuration).deleteTemplatesFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete templates avatar picture by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTemplatesItemBasicPicture(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AdminTemplatesApiFp(configuration).deleteTemplatesItemBasicPicture(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of applications for template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllApplicationsForTemplate(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<ApplicationReleaseDto>>> {
            return AdminTemplatesApiFp(configuration).getAllApplicationsForTemplate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get published engines for concrete template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPublishedEnginesForTemplate(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<PublishedEngineForTemplatesDto>>> {
            return AdminTemplatesApiFp(configuration).getAllPublishedEnginesForTemplate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get published templates list
         * @param {number} page 
         * @param {number} size 
         * @param {string} [sortOrder] 
         * @param {number} [enginesId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublishedTemplatesList(page: number, size: number, sortOrder?: string, enginesId?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<PageDtoTemplateDto>> {
            return AdminTemplatesApiFp(configuration).getPublishedTemplatesList(page, size, sortOrder, enginesId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get templates file name by id
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemplateFileName(templateId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> {
            return AdminTemplatesApiFp(configuration).getTemplateFileName(templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download templates file by id
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemplatesFile(templateId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<Blob>> {
            return AdminTemplatesApiFp(configuration).getTemplatesFile(templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Template item by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemplatesItemById(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<TemplateDto>> {
            return AdminTemplatesApiFp(configuration).getTemplatesItemById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get templates list with pagination and filtering by status and key value
         * @param {number} page 
         * @param {number} size 
         * @param {string} [searchedValue] 
         * @param {EntityStatus} [entityStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemplatesList(page: number, size: number, searchedValue?: string, entityStatus?: EntityStatus, options?: AxiosRequestConfig): Promise<AxiosResponse<PageDtoTemplateDto>> {
            return AdminTemplatesApiFp(configuration).getTemplatesList(page, size, searchedValue, entityStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update template item by id
         * @param {number} id 
         * @param {TemplateDto} [dto] 
         * @param {Blob} [imageFullSize] 
         * @param {Blob} [imageSmallSize] 
         * @param {Blob} [templatesFile] 
         * @param {boolean} [templatesFilePresent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTemplateItemForm(id: number, dto?: TemplateDto, imageFullSize?: Blob, imageSmallSize?: Blob, templatesFile?: Blob, templatesFilePresent?: boolean, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AdminTemplatesApiFp(configuration).updateTemplateItemForm(id, dto, imageFullSize, imageSmallSize, templatesFile, templatesFilePresent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload templates file by id
         * @param {number} templatesId 
         * @param {Blob} [templatesFile] 
         * @param {boolean} [templatePresent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadTemplateForm(templatesId: number, templatesFile?: Blob, templatePresent?: boolean, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AdminTemplatesApiFp(configuration).uploadTemplateForm(templatesId, templatesFile, templatePresent, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminTemplatesApi - object-oriented interface
 * @export
 * @class AdminTemplatesApi
 * @extends {BaseAPI}
 */
export class AdminTemplatesApi extends BaseAPI {
    /**
     * 
     * @summary Add template item
     * @param {AddTemplateItemDto} [templateItemDto] 
     * @param {Blob} [imageFullSize] 
     * @param {Blob} [imageSmallSize] 
     * @param {Blob} [templatesFile] 
     * @param {boolean} [templatesFilePresent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTemplatesApi
     */
    public async addTemplateItemForm(templateItemDto?: AddTemplateItemDto, imageFullSize?: Blob, imageSmallSize?: Blob, templatesFile?: Blob, templatesFilePresent?: boolean, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AdminTemplatesApiFp(this.configuration).addTemplateItemForm(templateItemDto, imageFullSize, imageSmallSize, templatesFile, templatesFilePresent, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete template item by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTemplatesApi
     */
    public async deleteTemplateItemById(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AdminTemplatesApiFp(this.configuration).deleteTemplateItemById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete templates file by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTemplatesApi
     */
    public async deleteTemplatesFile(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AdminTemplatesApiFp(this.configuration).deleteTemplatesFile(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete templates avatar picture by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTemplatesApi
     */
    public async deleteTemplatesItemBasicPicture(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AdminTemplatesApiFp(this.configuration).deleteTemplatesItemBasicPicture(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get list of applications for template
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTemplatesApi
     */
    public async getAllApplicationsForTemplate(options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<ApplicationReleaseDto>>> {
        return AdminTemplatesApiFp(this.configuration).getAllApplicationsForTemplate(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get published engines for concrete template
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTemplatesApi
     */
    public async getAllPublishedEnginesForTemplate(options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<PublishedEngineForTemplatesDto>>> {
        return AdminTemplatesApiFp(this.configuration).getAllPublishedEnginesForTemplate(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get published templates list
     * @param {number} page 
     * @param {number} size 
     * @param {string} [sortOrder] 
     * @param {number} [enginesId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTemplatesApi
     */
    public async getPublishedTemplatesList(page: number, size: number, sortOrder?: string, enginesId?: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<PageDtoTemplateDto>> {
        return AdminTemplatesApiFp(this.configuration).getPublishedTemplatesList(page, size, sortOrder, enginesId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get templates file name by id
     * @param {number} templateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTemplatesApi
     */
    public async getTemplateFileName(templateId: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<string>> {
        return AdminTemplatesApiFp(this.configuration).getTemplateFileName(templateId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Download templates file by id
     * @param {number} templateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTemplatesApi
     */
    public async getTemplatesFile(templateId: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<Blob>> {
        return AdminTemplatesApiFp(this.configuration).getTemplatesFile(templateId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get Template item by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTemplatesApi
     */
    public async getTemplatesItemById(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<TemplateDto>> {
        return AdminTemplatesApiFp(this.configuration).getTemplatesItemById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get templates list with pagination and filtering by status and key value
     * @param {number} page 
     * @param {number} size 
     * @param {string} [searchedValue] 
     * @param {EntityStatus} [entityStatus] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTemplatesApi
     */
    public async getTemplatesList(page: number, size: number, searchedValue?: string, entityStatus?: EntityStatus, options?: AxiosRequestConfig) : Promise<AxiosResponse<PageDtoTemplateDto>> {
        return AdminTemplatesApiFp(this.configuration).getTemplatesList(page, size, searchedValue, entityStatus, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update template item by id
     * @param {number} id 
     * @param {TemplateDto} [dto] 
     * @param {Blob} [imageFullSize] 
     * @param {Blob} [imageSmallSize] 
     * @param {Blob} [templatesFile] 
     * @param {boolean} [templatesFilePresent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTemplatesApi
     */
    public async updateTemplateItemForm(id: number, dto?: TemplateDto, imageFullSize?: Blob, imageSmallSize?: Blob, templatesFile?: Blob, templatesFilePresent?: boolean, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AdminTemplatesApiFp(this.configuration).updateTemplateItemForm(id, dto, imageFullSize, imageSmallSize, templatesFile, templatesFilePresent, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Upload templates file by id
     * @param {number} templatesId 
     * @param {Blob} [templatesFile] 
     * @param {boolean} [templatePresent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTemplatesApi
     */
    public async uploadTemplateForm(templatesId: number, templatesFile?: Blob, templatePresent?: boolean, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AdminTemplatesApiFp(this.configuration).uploadTemplateForm(templatesId, templatesFile, templatePresent, options).then((request) => request(this.axios, this.basePath));
    }
}
