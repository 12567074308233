import { FeatureFlagDto } from 'api';
import React, { Dispatch, MouseEventHandler } from 'react';
import style from 'assets/styles/manageElements.module.scss';
import { ItemContainer } from 'components/ItemContainer';
import EditFeatureFlagForm from './EditFeatureFlagForm';
import { McIconButton } from 'components/mc';
import { IconEdit, IconTrash } from 'assets/icons/svg';
import { App, Switch, Tooltip } from 'antd';
import {
	deleteItemModal,
	deleteSuccessNotification,
	mcErrorNotification,
} from 'utils/Notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import adminFeatureFlagService from 'services/admin/AdminFeatureFlagService';
import { useAppSelector } from 'hooks/hooks';
import { authState } from 'store/slices/auth';
import { hasWritePermission, hasDeletePermission } from 'utils/permissions';

interface Props {
	featureFlag: FeatureFlagDto;
	setSelectedToUpdate: Dispatch<number | null>;
	selectedToUpdate: number | null;
}

const ManageOneFeatureFlag = ({
	featureFlag,
	selectedToUpdate,
	setSelectedToUpdate,
}: Props) => {
	const { modal, notification } = App.useApp();
	const queryClient = useQueryClient();

	const { permissions } = useAppSelector(authState);

	const canEdit = hasWritePermission(permissions, 'featureflags');
	const canDelete = hasDeletePermission(permissions, 'featureflags');

	const styles =
		selectedToUpdate === featureFlag.id
			? {
					backgroundColor: 'var(--bg-active)',
					color: 'var(--button-text)',
					columnGap: '2rem',
			  }
			: {
					color: 'var(--text-normal)',
					columnGap: '2rem',
			  };

	const clickEditBtnHandler: MouseEventHandler = (e) => {
		e.preventDefault();

		if (selectedToUpdate === null) {
			setSelectedToUpdate(featureFlag.id);
		} else {
			if (selectedToUpdate === featureFlag.id) {
				setSelectedToUpdate(null);
			} else {
				setSelectedToUpdate(featureFlag.id);
			}
		}
	};

	const { mutate: deleteFeatureFlag } = useMutation({
		mutationFn: () =>
			adminFeatureFlagService
				.deleteFeatureFlagById(featureFlag.id)
				.then((res) => res.data),
		onSuccess: () => {
			notification.success(deleteSuccessNotification());
			queryClient.invalidateQueries({
				queryKey: ['feature-flags'],
			});
		},
		onError: (error: unknown) =>
			notification.error(
				mcErrorNotification('Error', error, 'delete', 'feature flag')
			),
	});

	const { mutate: toggleFeatureFlag, isPending: isPendingToggle } = useMutation(
		{
			mutationFn: (id: number) =>
				adminFeatureFlagService.toggleFeatureFlag(id).then((res) => res.data),
			onSuccess: () => {
				queryClient.invalidateQueries({
					queryKey: ['feature-flags'],
				});
			},
			onError: (error: unknown) =>
				notification.error(
					mcErrorNotification('Error', error, 'toggle', 'feature flag')
				),
		}
	);

	return (
		<div>
			<ItemContainer styles={styles}>
				<div className={style.textItem}>
					{
						<Switch
							disabled={isPendingToggle || !canEdit}
							loading={isPendingToggle}
							value={featureFlag.enabled}
							onChange={(_, event) => {
								event.preventDefault();
								toggleFeatureFlag(featureFlag.id);
							}}
							onClick={(_, event) => {
								event.preventDefault();
							}}
						/>
					}
				</div>
				<div className={style.statusItem}>
					<Tooltip title={!!featureFlag.enabled ? 'Enabled' : 'Disabled'}>
						<div
							className={style.statusCircle}
							style={{
								background: !!featureFlag.enabled
									? 'var(--add-green)'
									: 'var(--add-red)',
							}}
						/>
					</Tooltip>
					{featureFlag.key}
				</div>

				<div className={style.textItem} style={{ flex: '3' }}>
					{featureFlag.description}
				</div>
				<div className={style.buttonsItem} style={{ flexGrow: 0, gap: 0 }}>
					<McIconButton
						icon={<IconEdit />}
						disabled={!canEdit}
						onClick={clickEditBtnHandler}
					/>
					<McIconButton
						icon={<IconTrash />}
						disabled={!canDelete}
						onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
							e.preventDefault();
							modal.confirm(
								deleteItemModal(() => {
									deleteFeatureFlag();
								}, featureFlag.key)
							);
						}}
					/>
				</div>
			</ItemContainer>
			{selectedToUpdate === featureFlag.id && (
				<EditFeatureFlagForm
					featureFlag={featureFlag}
					hideForm={() => setSelectedToUpdate(null)}
				/>
			)}
		</div>
	);
};

export default ManageOneFeatureFlag;
